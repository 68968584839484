import { GeofireTools } from '../GeofireTools.js';
import { KatapultGeometry } from 'katapult-toolbox';
import { Path } from '../Path.js';
import { Round } from '../Round.js';
import { AddAttribute } from '../AddAttribute.js';
import { GoogleGeometry } from '../GoogleGeometry.js';
import { DataLayer } from './DataLayer.js';
import { GeneratePushID } from '../GeneratePushID.js';

export let Connections = {
  create: function (node1Key, node1Data, node2Key, node2Data, otherAttributes, firebase, options) {
    options = options || {};
    options.jobStyles = options.jobStyles || {};
    // Initial connection data
    let newConnectionKey = options.key || GeneratePushID();
    let newConnection = { node_id_1: node1Key, node_id_2: node2Key };
    if (options.breakpoints?.length > 0) {
      newConnection.breakpoints = options.breakpoints;
    }
    DataLayer._addTimeStamp(newConnection, 'connection', 'created', options.method, firebase);
    // Add button property if given
    if (options.button) newConnection.button = options.button;
    // Apply regular attributes
    this.setAttributes(newConnectionKey, newConnection, options.attributes, otherAttributes, {
      jobId: options.jobId,
      jobStyles: options.jobStyles
    });
    // Check if we should apply connection length attributes
    if (options.connection_length_attributes) {
      this.applyLengthAttributes(
        newConnectionKey,
        newConnection,
        node1Data,
        node2Data,
        options.connection_length_attributes,
        otherAttributes,
        options
      );
    }
    // Modify the update object if provided
    if (options.update) {
      let connPath = Path.join([options.jobId, 'connections', newConnectionKey], '/');
      options.update[connPath] = newConnection;
      let geoPath = options.geoPath || Path.join([options.jobId, 'geohash'], '/') + '/';
      // Set the geofire data for the node in the updates as well.
      GeofireTools.setGeohash('connections', newConnection, newConnectionKey, options.jobStyles, options.update, {
        nId1: node1Key,
        nId2: node2Key,
        location1: [node1Data.latitude, node1Data.longitude],
        location2: [node2Data.latitude, node2Data.longitude],
        geoPath,
        google: options.google
      });
    }

    return {
      key: newConnectionKey,
      data: newConnection
    };
  },
  insertNode: function (connKey, connData, node1Data, node2Data, newNodeKey, newNode, otherAttributes, firebase, options) {
    options = options || {};
    options.jobStyles = options.jobStyles || {};
    // Create the new connection
    let results = this.create(newNodeKey, newNode, connData.node_id_2, node2Data, otherAttributes, firebase, options);
    // Update the 2nd endpoint for the existing connection to the new
    // node (and skip updating section locations since we'll do that below)
    options.skipUpdatingSectionLocations = true;
    this.updateEndpoint(connKey, connData, 2, newNodeKey, newNode, connData.node_id_1, node1Data, options);
    // Adjust section positions
    for (let sectionKey in connData.sections) {
      let distanceToExisting = KatapultGeometry.CalcDistanceToLine(
        connData.sections[sectionKey].latitude,
        connData.sections[sectionKey].longitude,
        node1Data.latitude,
        node1Data.longitude,
        newNode.latitude,
        newNode.longitude
      );
      let distanceToNew = KatapultGeometry.CalcDistanceToLine(
        connData.sections[sectionKey].latitude,
        connData.sections[sectionKey].longitude,
        newNode.latitude,
        newNode.longitude,
        node2Data.latitude,
        node2Data.longitude
      );
      if (distanceToExisting < distanceToNew) {
        // Just adjust the endpoint of the connection and the section along with it
        DataLayer.Sections.setLocationOnConnection(
          sectionKey,
          connData.sections[sectionKey],
          connKey,
          connData,
          node1Data,
          newNode,
          options
        );
      } else {
        let sectionNewLoc = KatapultGeometry.SnapToLine(
          connData.sections[sectionKey].latitude,
          connData.sections[sectionKey].longitude,
          newNode.latitude,
          newNode.longitude,
          node2Data.latitude,
          node2Data.longitude
        );
        // Remove the section from the existing connection and set on the new one
        connData.sections[sectionKey].latitude = sectionNewLoc.lat;
        connData.sections[sectionKey].longitude = sectionNewLoc.long;
        // This should also automatically apply the section data to the update
        // because of the object reference for results.data
        Path.set(results.data, `sections.${sectionKey}`, connData.sections[sectionKey]);
        Path.delete(connData, `sections.${sectionKey}`);
        if (options.update) {
          let oldSectionPath = Path.join([options.jobId, 'connections', connKey, 'sections', sectionKey], '/');
          let geoPath = options.geoPath || Path.join([options.jobId, 'geohash'], '/') + '/';
          options.update[oldSectionPath] = null;
          options.update[`${geoPath}${connKey}:${sectionKey}`] = null;
          GeofireTools.setGeohash('sections', results.data.sections[sectionKey], results.key, options.jobStyles, options.update, {
            sectionId: sectionKey
          });
        }
      }
    }
    return results;
  },
  delete: function () {
    // todo
  },
  setAttributes: function (connKey, connData, attributes, otherAttributes, options) {
    DataLayer.setItemAttributes('connections', connKey, null, connData, attributes, otherAttributes, options);
  },
  _computeLength: function (endPoint1, endPoint2) {
    // Get the locations for the endpoints
    let endPoint1Location = new GoogleGeometry.maps.LatLng(endPoint1.latitude, endPoint1.longitude);
    let endPoint2Location = new GoogleGeometry.maps.LatLng(endPoint2.latitude, endPoint2.longitude);
    // Compute the length (todo: this should be adapted for polylines)
    return Round(GoogleGeometry.maps.geometry.spherical.computeDistanceBetween(endPoint1Location, endPoint2Location) / 0.3048, 1);
  },
  updateEndpoint: function (connKey, connData, endpointIndex, newNodeKey, newNodeData, otherNodeKey, otherNodeData, options) {
    options = options || {};
    let endpointKey = `node_id_${endpointIndex}`;
    let oldNodeKey = connData[endpointKey];
    connData[endpointKey] = newNodeKey;
    // Check if we should adjust the section positions
    if (!options.skipUpdatingSectionLocations) {
      let node1Data = newNodeKey == connData.node_id_1 ? newNodeData : otherNodeData;
      let node2Data = newNodeKey == connData.node_id_2 ? newNodeData : otherNodeData;
      for (let sectionKey in connData.sections) {
        DataLayer.Sections.setLocationOnConnection(
          sectionKey,
          connData.sections[sectionKey],
          connKey,
          connData,
          node1Data,
          node2Data,
          options
        );
      }
    }
    if (options.update) {
      let length = this._computeLength(newNodeData, otherNodeData);
      let connPath = Path.join([options.jobId, 'connections', connKey], '/');
      let geoPath = options.geoPath || Path.join([options.jobId, 'geohash'], '/') + '/';
      // Update the connection's endpoint key
      options.update[`${connPath}/${endpointKey}`] = newNodeKey;
      // Set the node connection in the geohash for the new node
      GeofireTools.updateNodeConnectionIndex(newNodeKey, connKey, endpointIndex, options.update, { geoPath });
      // Remove the connection id from the geohash for the old node
      if (oldNodeKey != null && oldNodeKey != newNodeKey)
        GeofireTools.updateNodeConnectionIndex(oldNodeKey, connKey, null, options.update, { geoPath });
      // Update the new length for the connection
      options.update[`${geoPath}${connKey}~1/d`] = length;
      options.update[`${geoPath}${connKey}~2/d`] = length;
      // Update the l2 position of the unchanged endpoint
      let otherEndpointIndex = endpointIndex == 1 ? 2 : 1;
      options.update[`${geoPath}${connKey}~${otherEndpointIndex}/l2`] = [newNodeData.latitude, newNodeData.longitude];
      // Update the position of the endpoint
      GeofireTools.updateLocation(
        `${geoPath}${connKey}~${endpointIndex}`,
        [newNodeData.latitude, newNodeData.longitude],
        10,
        options.update
      );
    }
  },
  applyLengthAttributes: function (connKey, connData, node1Data, node2Data, lengthAttributes, otherAttributes, options) {
    options = options || {};
    options.jobStyles = options.jobStyles || {};
    let length = this._computeLength(node1Data, node2Data);
    // Create an attribute object if needed
    if (!connData.attributes) connData.attributes = {};
    let attributesPath = Path.join([options.jobId, 'connections', connKey, 'attributes'], '/');
    // Loop through the connection length attributes
    for (let key in lengthAttributes) {
      let item = lengthAttributes[key];
      let matches = (item.min == null || item.min <= length) && (item.max == null || item.max >= length);
      for (let prop in item.attributes) {
        let attributeKey = 'map_added';
        let attributeValue = item.attributes[prop];
        if (matches) {
          // Add the attribute
          AddAttribute(prop, connData.attributes, otherAttributes, {
            attributeKey,
            attributeValue
          });
          // Add the data to the update if given
          if (options.update) options.update[`${attributesPath}/${prop}/${attributeKey}`] = item.attributes[prop];
        } else {
          delete connData.attributes[prop];
          if (options.update) options.update[`${attributesPath}/${prop}`] = null;
        }
      }
    }
    // todo check all calls to updateStyle once in beta
    GeofireTools.updateStyle('connections', connKey, connData, options.update || {}, options.jobStyles);
  }
};
