import '@polymer/polymer/polymer-legacy.js';
import '@polymer/iron-icon/iron-icon.js';
import '../style-modules/paper-dialog-style.js';
import '../katapult-elements/katapult-button.js';
import { Polymer } from '@polymer/polymer/lib/legacy/polymer-fn.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
Polymer({
  _template: html`
    <style include="paper-dialog-style">
      :host {
        background-color: white;
      }

      #container label {
        color: #737373;
        font-size: 12px;
      }

      #container .previewContainer {
        padding: 0px;
        cursor: pointer;
        border-bottom: 1px solid #737373;
      }

      #container .previewText {
        white-space: nowrap;
        width: calc(100% - 25px);
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 1px;
        font-size: 16px;
      }

      #container .previewContainer iron-icon {
        float: right;
        top: -24px;
      }

      #container div.item {
        color: #212121;
        padding: 12px;
        cursor: pointer;
      }

      #container div.selected {
        color: white;
        background-color: #007198;
      }

      #container iron-icon {
        color: #737373;
      }
    </style>

    <div id="container">
      <template is="dom-if" if="{{label}}">
        <label>{{label}}</label>
      </template>
      <div class="previewContainer" on-click="_openSelectDialog">
        <div class="previewText">{{_computeSelectedValuePreview(items.*, selectedKeys.*, displayedProperty)}}</div>
        <iron-icon icon="arrow-drop-down"></iron-icon>
      </div>

      <paper-dialog id="selectDialog">
        <template is="dom-if" if="{{multi}}">
          <h2>Select Options</h2>
        </template>
        <template is="dom-if" if="{{!multi}}">
          <h2>Select an Option</h2>
        </template>
        <paper-dialog-scrollable>
          <template is="dom-repeat" items="{{_computeItemsArray(items)}}" sort="{{_computeSortFunction(sortByKey)}}">
            <div class$="item {{_computeClass(item, selectedKeys.*)}}" on-click="_toggleSelected">
              {{_computeDisplayedValue(item.value, displayedProperty)}}
            </div>
          </template>
        </paper-dialog-scrollable>
        <div class="buttons">
          <katapult-button dialog-confirm>Done</katapult-button>
        </div>
      </paper-dialog>
    </div>
  `,

  is: 'select-drop-down',

  properties: {
    displayedProperty: {
      type: String,
      value: null
    },
    items: {
      type: Object
    },
    label: {
      type: String
    },
    multi: {
      type: Boolean,
      value: false
    },
    selectedKeys: {
      type: Array,
      value: function () {
        return [];
      },
      notify: true
    },
    sortByKey: {
      type: String,
      value: null
    }
  },

  _computeClass: function (item, selectedKeys) {
    return this.selectedKeys.indexOf(item.key) == -1 ? '' : 'selected';
  },

  _computeDisplayedValue: function (item, displayedProperty) {
    return item[displayedProperty] || '';
  },

  _computeItemsArray: function (items) {
    var newArray = [];
    // Check if the parameter is an Object
    if (typeof items === 'object') {
      // Add the items to newArray
      for (var key in items) {
        if (items.hasOwnProperty(key)) {
          newArray.push({
            key: key,
            value: items[key]
          });
        }
      }
    }
    return newArray;
  },

  _computeSelectedValuePreview: function () {
    // Return 'None Selected' is selectedKeys doesn't exist, or if there are no items selectedKeys
    if (!this.items || !this.selectedKeys || this.selectedKeys.length == 0) return 'None Selected';
    // Array to store the selected values' display value
    var values = [];
    // Add the display values for the selected items
    for (var i = 0; i < this.selectedKeys.length; i++) {
      values.push(this._computeDisplayedValue(this.items[this.selectedKeys[i]], this.displayedProperty));
    }
    // Join the array with commas in between items
    return values.join(', ');
  },

  _computeSortFunction: function (sortByKey) {
    if (!sortByKey) {
      return null;
    } else {
      return function (a, b) {
        if (a.value.hasOwnProperty(sortByKey) && b.value.hasOwnProperty(sortByKey)) {
          return a.value[sortByKey].localeCompare(b.value[sortByKey]);
        }
      };
    }
  },

  _openSelectDialog: function () {
    this.$.selectDialog.open();
  },

  _toggleSelected: function (e) {
    if (e && e.model && e.model.item && e.model.item.key && this.selectedKeys) {
      // Get the index of the key in the selectedKeys list
      var index = this.selectedKeys.indexOf(e.model.item.key);
      // Check if the key is in the list or not
      if (index != -1) {
        // Splice out key or reset array based on multiselect option
        if (this.multi) {
          this.splice('selectedKeys', index, 1);
        } else {
          this.set('selectedKeys', []);
        }
      } else {
        if (this.multi) {
          this.push('selectedKeys', e.model.item.key);
        } else {
          this.set('selectedKeys', [e.model.item.key]);
        }
      }
    }
  }
});
