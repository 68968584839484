import { KLogic } from './KLogic.js';
import { GeneratePushID } from '../modules/GeneratePushID';

class EmailTemplate {
  /**
   *
   * @param {Object} email  - {addresses:[], subject:'', body:'', template_items:{}}
   * @param {Object} data - $DATA for KLogic blocks
   * @returns Templatized email
   */
  static compute(email, data) {
    if (!email) return email;

    // Convert template items to an array.
    const templateItems = Object.values(email.template_items ?? {});

    //Remove the template items
    delete email.template_items;
    //Templatize the subject and body
    ['subject', 'body'].forEach((item) => {
      email[item] = email[item].replace(/\$\(([^)]+)\)/g, (key) =>
        KLogic.compute(templateItems.find((x) => x.name == key.slice(2, -1))?.value, data)
      );
    });

    // Update the addresses, to, and cc properties.
    email.addresses = this.getAddresses(email);
    const addresses = Object.values(email.addresses);
    ['to', 'cc'].forEach((type) => {
      email[type] = addresses
        .filter((x) => x.type == type)
        .map((x) => x.email)
        .join(', ');
    });

    // Return the email
    return email;
  }

  static getAddresses(email) {
    // Define an array for the addresses.
    const addressesArray = [];

    // Define a function for adding addresses to the list.
    const add = (address) => {
      // Trim the email address string.
      address.email = address.email?.trim?.();
      // Skip if the email address is empty.
      if (address.email == null || address.email == '') return;
      // Add the address object to the list if the email address is not already in the list.
      if (addressesArray.every((x) => x.email != address.email)) addressesArray.push(address);
    };

    // Add addresses from email.addresses object.
    for (const key in email?.addresses) {
      // Build address object.
      let address = email.addresses[key];
      // If the address is a string, convert it to an object.
      if (typeof address == 'string') address = { email: address, type: 'to' };
      // Set the key on the address object.
      address.$key = key;
      // Add the address object to the list.
      add(address);
    }

    // Add addresses from email.to and email.cc.
    ['to', 'cc'].forEach((type) => {
      // Split email string into individual email addresses.
      const emails = email[type]?.split(',') ?? [];
      emails.forEach((email) => {
        // Build an address object.
        const address = { email, type };
        // Add the address object to the list.
        add(address);
      });
    });

    // Convert the addresses array to an object.
    const addresses = {};

    addressesArray.forEach((address) => {
      const temp = { email: address.email, type: address.type };
      addresses[address.$key ?? GeneratePushID()] = temp;
    });

    // Return the addresses object.
    return addresses;
  }

  static addressesToString(email) {
    if (email.addresses) {
      email.to =
        Object.values(email.addresses)
          ?.filter((x) => typeof x == 'string' || x.type == 'to')
          .map((x) => x.email || x)
          .join(', ') || '';
      email.cc =
        Object.values(email.addresses)
          ?.filter((x) => x.type == 'cc')
          .map((x) => x.email || x)
          .join(', ') || '';
    }
    return email;
  }

  static addressesToObject(email) {
    if (email != null) {
      let to = {};
      email.to
        ?.split(',')
        .map((x) => x.trim())
        .filter((x) => x)
        .forEach((x) => (to[GeneratePushID()] = { email: x, type: 'to' }));
      email.cc
        ?.split(',')
        .map((x) => x.trim())
        .filter((x) => x)
        .forEach((x) => (to[GeneratePushID()] = { email: x, type: 'cc' }));
      email.addresses = to;
    }
    return email;
  }
}

export { EmailTemplate };
