import { PickAnAttribute } from './PickAnAttribute.js';
import { Round } from './Round.js';
import { GeofireTools } from './GeofireTools.js';
import { KatapultGeometry } from 'katapult-toolbox';
import { GoogleGeometry } from './GoogleGeometry.js';

export function UpdateNodeLocation(jobId, nodeId, node, latitude, longitude, nodeConnections, connectedNodes, update, options) {
  options = options || {};
  options.jobStyles = options.jobStyles || {};
  nodeConnections = nodeConnections || [];
  // Build a lookup for the node's endpoint number for each connection
  let nodeEndpointLookup = {};
  for (let i = 0; i < nodeConnections.length; i++) {
    nodeEndpointLookup[nodeConnections[i].connId] = nodeConnections[i].connData.node_id_1 == nodeId ? 1 : 2;
  }
  // Create a lat/long object for the moving node's existing lat/long
  let nodeExistingLatLng = new GoogleGeometry.maps.LatLng(node.latitude, node.longitude);
  node.latitude = latitude;
  node.longitude = longitude;
  update['nodes/' + nodeId + '/latitude'] = latitude;
  update['nodes/' + nodeId + '/longitude'] = longitude;
  if (options.recordNodeMoveAttribute) {
    update['/nodes/' + nodeId + '/attributes/' + options.recordNodeMoveAttribute + '/value'] = true;
    if (!node.attributes) node.attributes = {};
    if (!node.attributes[options.recordNodeMoveAttribute]) node.attributes[options.recordNodeMoveAttribute] = {};
    node.attributes[options.recordNodeMoveAttribute].value = true;
    GeofireTools.setGeohash('nodes', node, nodeId, options.jobStyles, update, { nodeConnections: nodeEndpointLookup });
  } else {
    GeofireTools.updateLocation('geohash/' + nodeId, [latitude, longitude], 10, update);
  }
  for (let i = 0; i < nodeConnections.length; i++) {
    let connId = nodeConnections[i].connId;
    var conn = nodeConnections[i].connData;
    var toNodeId = nodeConnections[i].toNodeId;
    if (conn != null) {
      // Get a reference to the other node on the connection
      let otherNode = connectedNodes[toNodeId];
      // Create a lat/long object for the other node's position
      let otherNodeLatLng = new GoogleGeometry.maps.LatLng(otherNode.latitude, otherNode.longitude);
      let otherNodeType = PickAnAttribute(otherNode.attributes, 'node_type');
      // Find any connections of type "down guy" or "pushbrace" because we
      // want to lock the angle and distance of other nodes on those connections
      let connectionType = PickAnAttribute(conn.attributes, 'connection_type');
      if (
        (connectionType == 'down guy' || connectionType == 'pushbrace') &&
        (otherNodeType == 'existing anchor' || otherNodeType == 'new anchor' || otherNodeType == 'pushbrace')
      ) {
        // Calculate the new position for the other node so that the
        // existing length and angle are maintained

        // Get the current distance and angle between the moving node and the attached node
        let existingDistance = GoogleGeometry.maps.geometry.spherical.computeDistanceBetween(otherNodeLatLng, nodeExistingLatLng);
        let existingAngle = GoogleGeometry.maps.geometry.spherical.computeHeading(nodeExistingLatLng, otherNodeLatLng);

        // Update the otherNodeLatLng to the position calculated by the new moved node's position and the static distance and angle
        otherNodeLatLng = GoogleGeometry.maps.geometry.spherical.computeOffset(
          new GoogleGeometry.maps.LatLng(latitude, longitude),
          existingDistance,
          existingAngle
        );

        // Update the geohash data for the new position of the other node
        update['/nodes/' + toNodeId + '/latitude'] = otherNodeLatLng.lat();
        update['/nodes/' + toNodeId + '/longitude'] = otherNodeLatLng.lng();
        GeofireTools.updateLocation('geohash/' + toNodeId, [otherNodeLatLng.lat(), otherNodeLatLng.lng()], 10, update);

        // Update the geohash connection data for the new position of the other node
        update['/geohash/' + connId + '~' + nodeEndpointLookup[connId] + '/l2'] = [otherNodeLatLng.lat(), otherNodeLatLng.lng()];
        GeofireTools.updateLocation(
          'geohash/' + connId + '~' + (nodeEndpointLookup[connId] == 1 ? 2 : 1),
          [otherNodeLatLng.lat(), otherNodeLatLng.lng()],
          10,
          update
        );
      }

      // Calculate the length of the connection after the move (should be the same if the distance and angle didn't change)
      var length = Round(
        GoogleGeometry.maps.geometry.spherical.computeDistanceBetween(
          new GoogleGeometry.maps.LatLng(latitude, longitude),
          otherNodeLatLng
        ) / 0.3048,
        1
      );
      if (options.connection_length_attributes) {
        for (let key in options.connection_length_attributes) {
          let item = options.connection_length_attributes[key];
          let matches = (item.min == null || item.min <= length) && (item.max == null || item.max >= length);
          for (let prop in item.attributes) {
            if (matches) conn.attributes[prop] = { map_added: item.attributes[prop] };
            else delete conn.attributes[prop];
            update['/connections/' + connId + '/attributes/' + prop] = matches ? { map_added: item.attributes[prop] } : null;
          }
          GeofireTools.updateStyle('connections', connId, conn, update, options.jobStyles);
        }
      }
      // Update the geohash connection data for the position of the moved node
      update['/geohash/' + connId + '~' + (nodeEndpointLookup[connId] == 1 ? 2 : 1) + '/l2'] = [latitude, longitude];
      update['/geohash/' + connId + '~1/d'] = length;
      update['/geohash/' + connId + '~2/d'] = length;
      // Run the geohash update
      GeofireTools.updateLocation('geohash/' + connId + '~' + nodeEndpointLookup[connId], [latitude, longitude], 10, update);

      for (var sectionId in conn.sections) {
        var n2 = connectedNodes[toNodeId];
        var pointOnLine = KatapultGeometry.SnapToLine(
          conn.sections[sectionId].latitude,
          conn.sections[sectionId].longitude,
          latitude,
          longitude,
          n2.latitude,
          n2.longitude
        );
        update['connections/' + connId + '/sections/' + sectionId + '/latitude'] = pointOnLine.lat;
        update['connections/' + connId + '/sections/' + sectionId + '/longitude'] = pointOnLine.long;
        GeofireTools.updateLocation('geohash/' + connId + ':' + sectionId, [pointOnLine.lat, pointOnLine.long], 10, update);
      }
    }
  }
}
