// *********************************************************************************************
// Calculate Bearings,inner and outer angles, Break angle, Pull angle, and Pull distance

import { CalcDistanceToLine } from 'katapult-toolbox/katapult-geometry';
import { Convert } from './Convert';

// *********************************************************************************************
/**
 * Calculate bearings, inner and outer angles, break angle, pull angle, and pull distance
 * @param {object} startNode - The first node in the midspan
 * @param {object} middleNode - The second node in the midspan
 * @param {object} endNode - The last node in the midspan
 * @returns {object} An object containing an inner, outer, and break angle, as well as the feet of pull and feet off pole
 */
export function LineAngleCalculations(startNode, middleNode, endNode) {
  let lineAngleObj = {};

  let bearingA = google.maps.geometry.spherical.computeHeading(middleNode, startNode);
  let bearingB = google.maps.geometry.spherical.computeHeading(middleNode, endNode);

  let innerAngle = 0;
  let outerAngle = 0;
  let breakAngle = 0;
  let angleDelta = Math.abs(bearingA + 180 - bearingB) % 360;

  if (angleDelta > 180) {
    breakAngle = Math.abs(angleDelta - 360);
  } else {
    breakAngle = angleDelta;
  }

  let bearingDelta = Math.abs(bearingA - bearingB);

  if (bearingDelta > 180) {
    outerAngle = bearingDelta;
    innerAngle = 360 - bearingDelta;
  } else {
    innerAngle = bearingDelta;
    outerAngle = 360 - bearingDelta;
  }

  // Feet of pull and feet off pole calculations
  let feetOfPull = 0;
  let feetOffPole = 0;

  let coord1 = google.maps.geometry.spherical.computeOffsetOrigin(middleNode, 100 * 0.3048, bearingA);
  let coord2 = google.maps.geometry.spherical.computeOffsetOrigin(middleNode, 100 * 0.3048, bearingB);

  feetOfPull = Number(
    Convert(CalcDistanceToLine(middleNode.lat, middleNode.lng, coord1.lat(), coord1.lng(), coord2.lat(), coord2.lng()), `m`, `foot`, 1)
  );
  feetOffPole = Number(
    Convert(CalcDistanceToLine(middleNode.lat, middleNode.lng, startNode.lat, startNode.lng, endNode.lat, endNode.lng), `m`, `foot`, 1)
  );

  lineAngleObj = {
    innerAngle: innerAngle.toFixed(),
    outerAngle: outerAngle.toFixed(),
    breakAngle: breakAngle.toFixed(),
    feetOfPull: feetOfPull.toFixed(1),
    feetOffPole: feetOffPole.toFixed(1)
  };

  return lineAngleObj;
}
