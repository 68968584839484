import { SquashNulls } from './SquashNulls.js';
import { PickAnAttribute } from './PickAnAttribute.js';
import { CalcStatement } from './CalcStatement.js';

export async function PPLBilling(nodes, connections, job_id, activeCommandModelId) {
  // if pci billing button is clicked
  let readyInvoiceFlag = false;
  if (activeCommandModelId == 'ppl_pci_billing') {
    return FirebaseWorker.ref('photoheight/company_space/katapult/billing/ppl_pci')
      .once('value')
      .then(async (s) => {
        var billing = s.val();
        var pole_counts = {
          ppl_poles: 0,
          foreign_poles: 0,
          malformed_poles: 0,
          total_poles: 0
        };
        for (var nodeId in nodes) {
          var nodeType = PickAnAttribute(nodes[nodeId].attributes, 'node_type');
          if (nodeType == 'pole') {
            pole_counts.total_poles++;
            // Pole Ownership
            var tags = SquashNulls(nodes[nodeId], 'attributes', 'pole_tag');
            var ppl_owned = false;
            var ppl_tagged = false;
            var foreign_owned = false;
            for (var key in tags) {
              if (tags[key].company == 'PPL' || tags[key].company == 'PPL Company') {
                ppl_tagged = true;
                if (tags[key].owner == true) {
                  ppl_owned = true;
                }
              } else if (tags[key].owner == true) {
                foreign_owned = true;
              }
            }
            if (ppl_owned && !foreign_owned) {
              pole_counts['ppl_poles']++;
            } else if (foreign_owned && ppl_tagged) {
              pole_counts['foreign_poles']++;
            } else if (ppl_owned && foreign_owned) {
              pole_counts['malformed_poles']++;
              console.log('Multiple owning companies!!!', nodeId);
            } else if (foreign_owned && !ppl_tagged) {
              if (PickAnAttribute(nodes[nodeId].attributes, 'scid') == '000') {
                pole_counts['foreign_poles']++;
              } else {
                pole_counts['malformed_poles']++;
              }

              console.log('No PPL tag on pole. either add it or turn this into a reference!!!', nodeId);
            } else if (!foreign_owned && !ppl_owned) {
              pole_counts['malformed_poles']++;
              console.log('no owner marked. Fix this!!!', nodeId);
            }
          }
          // Node Totals
          billing.units.forEach((item, index) => {
            if (item.item_types.node && CalcStatement(item.include, nodes[nodeId].attributes)) {
              item.count = item.count ? item.count + 1 : 1;
            }
          });
        }

        var totals = [];
        var app_cost = 0;
        let pciFieldCount =
          (await FirebaseWorker.ref(`photoheight/jobs/${job_id}/metadata/PCI_field_billable_hours`)
            .once('value')
            .then((s) => s.val())) || 0;
        let pciOfficeCount =
          (await FirebaseWorker.ref(`photoheight/jobs/${job_id}/metadata/PCI_office_billable_hours`)
            .once('value')
            .then((s) => s.val())) || 0;
        billing.units.push({ price: 65, count: pciFieldCount, label: 'Field Billable Hours (for Deficiencies)' });
        billing.units.push({ price: 75, count: pciOfficeCount, label: 'Office Billable Hours (for Deficiencies)' });

        billing.units.forEach((item) => {
          item.count = item.count || 0;
          item.total = item.price * item.count;
          app_cost += item.total;
          // change item.total to have 2 decimal places
          item.total = parseFloat(item.total).toFixed(2);
          totals.push(item);
        });

        let pciAmountInvoiced =
          (await FirebaseWorker.ref(`photoheight/jobs/${job_id}/metadata/PCI_amount_invoiced`)
            .once('value')
            .then((s) => s.val())) || 0;
        app_cost -= pciAmountInvoiced;
        pciAmountInvoiced = parseFloat(pciAmountInvoiced).toFixed(2);
        totals.push({ label: 'Amount Previously Invoiced', total: pciAmountInvoiced });

        app_cost = parseFloat(app_cost).toFixed(2);
        return { pole_counts, app_cost, billingTotals: totals, model: 'pci' };
      });
  } else if (activeCommandModelId == 'ppl_billing') {
    return FirebaseWorker.ref('photoheight/company_space/katapult/billing/ppl_9930')
      .once('value')
      .then(async (s) => {
        var billing = s.val();
        let jobMetadata = await FirebaseWorker.ref(`photoheight/jobs/${job_id}/metadata`)
          .once('value')
          .then((s) => s.val());
        let app_type = await FirebaseWorker.ref(`photoheight/jobs/${job_id}/metadata/app_type`)
          .once('value')
          .then((s) => s.val());
        var pole_counts = {
          ppl_poles: 0,
          foreign_poles: 0,
          malformed_poles: 0,
          total_poles: 0,
          one_touch_poles: 0
        };
        let notes = {};
        let municipalPermit = { count: 0, price: 100, total: 0, label: 'Municipal Permit' };
        let sidewalkPermit = { count: 0, price: 100, total: 0, label: 'Sidewalk Permit' };
        let miscPermit = { count: 0, price: 250, total: 0, label: 'Miscellaneous Permit' };
        let miscPermitsWithPlanProfile = { count: 0, price: 500, total: 0, label: 'Miscellaneous Permit (incl drawings)' };
        let permitFees = { count: 0, price: '(Varies)', total: 0, label: 'Reimbursement for permit fees paid on behalf of PPL' };
        for (var nodeId in nodes) {
          var nodeType = PickAnAttribute(nodes[nodeId].attributes, 'node_type');
          if (nodeType == 'pole') {
            // For One Touch, only count Complex and Medium MR Poles
            if (
              app_type == 'one_touch_make_ready_application' &&
              !['Medium Make Ready', 'Complex Make Ready'].includes(PickAnAttribute(nodes[nodeId].attributes, 'mr_category'))
            ) {
              // If added for loading, check to see if it's marked as billable
              if (
                PickAnAttribute(nodes[nodeId].attributes, 'added_for_loading') ||
                PickAnAttribute(nodes[nodeId].attributes, 'added_for_MR')
              ) {
                let billable = PickAnAttribute(nodes[nodeId].attributes, 'billable_to_PPL');
                if (billable == null) {
                  notes.added_poles_not_marked_as_billable = notes.added_poles_not_marked_as_billable || {
                    count: 0,
                    text: 'Poles added for loading or MR not marked if billable.'
                  };
                  notes.added_poles_not_marked_as_billable.count++;
                } else if (!billable) {
                  pole_counts.one_touch_poles++;
                  continue;
                }
              } else {
                pole_counts.one_touch_poles++;
                continue;
              }
            }
            pole_counts.total_poles++;
            // Pole Ownership
            var tags = SquashNulls(nodes[nodeId], 'attributes', 'pole_tag');
            var ppl_owned = false;
            var ppl_tagged = false;
            var foreign_owned = false;
            for (var key in tags) {
              if (tags[key].company == 'PPL' || tags[key].company == 'PPL Company') {
                ppl_tagged = true;
                if (tags[key].owner == true) {
                  ppl_owned = true;
                }
              }
            }
            if (ppl_owned && !foreign_owned) {
              pole_counts['ppl_poles']++;
            } else if (foreign_owned && ppl_tagged) {
              pole_counts['foreign_poles']++;
            } else if (ppl_owned && foreign_owned) {
              pole_counts['malformed_poles']++;
              console.log('Multiple owning companies!!!', nodeId);
            } else if (foreign_owned && !ppl_tagged) {
              if (PickAnAttribute(nodes[nodeId].attributes, 'scid') == '000') {
                pole_counts['foreign_poles']++;
              } else {
                pole_counts['malformed_poles']++;
              }

              console.log('No PPL tag on pole. either add it or turn this into a reference!!!', nodeId);
            } else if (!foreign_owned && !ppl_owned) {
              pole_counts['malformed_poles']++;
              console.log('no owner marked. Fix this!!!', nodeId);
            }
            // special calc the misc permit fees
            let permitFeesAttrs = SquashNulls(nodes[nodeId], 'attributes', 'permit_fees');
            if (permitFeesAttrs) {
              for (let prop in permitFeesAttrs) {
                let permitFeesAttr = permitFeesAttrs[prop];
                if (permitFeesAttr) {
                  permitFees.count++;
                  permitFees.total += Number(permitFeesAttr.replace(/\$/g, ''));
                }
              }
            }
            // special calc for sidewalk and misc permits
            let sidewalkPermits = SquashNulls(nodes[nodeId], 'attributes', 'sidewalk_cut_permit');
            if (sidewalkPermits) {
              for (let prop in sidewalkPermits) {
                let sidewalkPermitValue = sidewalkPermits[prop];
                if (
                  sidewalkPermitValue &&
                  (sidewalkPermitValue == 'Required' || sidewalkPermitValue == 'Submitted' || sidewalkPermitValue == 'Approved')
                )
                  sidewalkPermit.count = sidewalkPermit.count ? sidewalkPermit.count + 1 : 1;
              }
            }
            let miscPermits = SquashNulls(nodes[nodeId], 'attributes', 'misc_permit_status');
            if (miscPermits) {
              for (let prop in miscPermits) {
                let miscPermitValue = miscPermits[prop];
                if (miscPermitValue && (miscPermitValue == 'Required' || miscPermitValue == 'Submitted' || miscPermitValue == 'Approved')) {
                  if (Object.values(nodes[nodeId].attributes?.misc_permit_requires_plan_profile || {}).some((x) => x)) {
                    miscPermitsWithPlanProfile.count = miscPermitsWithPlanProfile.count ? miscPermitsWithPlanProfile.count + 1 : 1;
                  } else {
                    miscPermit.count = miscPermit.count ? miscPermit.count + 1 : 1;
                  }
                }
              }
            }
            // check for new municipal permit
            let municipalPermits = SquashNulls(nodes[nodeId], 'attributes', 'municipal_permit');
            if (municipalPermits) {
              for (let prop in municipalPermits) {
                let municipalPermitValue = municipalPermits[prop];
                if (
                  municipalPermitValue &&
                  (municipalPermitValue == 'Required' || municipalPermitValue == 'Submitted' || municipalPermitValue == 'Approved')
                )
                  municipalPermit.count = municipalPermit.count ? municipalPermit.count + 1 : 1;
              }
            }
          }

          // Node Totals
          billing.units.forEach((item, index) => {
            if (item.item_types.node && CalcStatement(item.include, nodes[nodeId].attributes)) {
              item.count = item.count ? item.count + 1 : 1;
              if (
                jobMetadata.engineering_contractor == 'Westwood Professional Services' &&
                item.label == 'Data Collection  (MR Data and PLA)'
              ) {
                // Add other engineering contractors here
                item.count = 0;
              }
              // If we have PCI Ready for Invoice Don't count this
              // They were already charged once
              if (readyInvoiceFlag) item.count = 0;
            } else if (item.item_types.metadata && CalcStatement(item.include, jobMetadata)) {
              if (jobMetadata.engineering_contractor == 'Westwood Professional Services' && item.label == 'Application Intake/Review') {
                // Add other engineering contractors here
                item.count = 1;
              }
            } else if (item.item_types.metadata && item.metadata_prop?.data_type) {
              let metadataInfo = item.metadata_prop || {};
              if (metadataInfo?.data_type === 'value') {
                if (metadataInfo?.from_key === 'PCI_results') {
                  // Check for PCI ABD Data
                  let readyInvoiceCount = 0;
                  let jobMetaPciAbd = jobMetadata.PCI_results || {};
                  Object.values(jobMetaPciAbd).forEach((obj) => {
                    if (obj != null) {
                      if (obj.invoice_status === 'Ready for Invoicing') {
                        readyInvoiceFlag = true; //We need this to zero some cells
                        readyInvoiceCount++;
                        let pciAbdData = obj;
                        readyInvoiceCount > 1
                          ? (item.count += Number(pciAbdData[metadataInfo.data_key]))
                          : (item.count = Number(pciAbdData[metadataInfo.data_key]));
                      }
                    }
                  });
                }
              }
            } else {
            }
          });
        }
        for (var connId in connections) {
          // special calc the misc permit fees
          let permitFeesAttr = PickAnAttribute(connections[connId].attributes, 'permit_fees');
          if (permitFeesAttr) {
            permitFees.count++;
            permitFees.total += Number(permitFeesAttr.replace(/\$/g, ''));
          }
          // special calc for sidewalk and misc permits
          let sidewalkPermits = SquashNulls(connections[connId], 'attributes', 'sidewalk_cut_permit');
          if (sidewalkPermits) {
            for (let prop in sidewalkPermits) {
              let sidewalkPermitValue = sidewalkPermits[prop];
              if (
                sidewalkPermitValue &&
                (sidewalkPermitValue == 'Required' || sidewalkPermitValue == 'Submitted' || sidewalkPermitValue == 'Approved')
              )
                sidewalkPermit.count = sidewalkPermit.count ? sidewalkPermit.count + 1 : 1;
            }
          }
          let miscPermits = SquashNulls(connections[connId], 'attributes', 'misc_permit_status');
          if (miscPermits) {
            for (let prop in miscPermits) {
              let miscPermitValue = miscPermits[prop];
              if (miscPermitValue && (miscPermitValue == 'Required' || miscPermitValue == 'Submitted' || miscPermitValue == 'Approved')) {
                if (Object.values(connections[connId].attributes?.misc_permit_requires_plan_profile || {}).some((x) => x)) {
                  miscPermitsWithPlanProfile.count = miscPermitsWithPlanProfile.count ? miscPermitsWithPlanProfile.count + 1 : 1;
                } else {
                  miscPermit.count = miscPermit.count ? miscPermit.count + 1 : 1;
                }
              }
            }
          }
          // check for new municipal permit
          let municipalPermits = SquashNulls(connections[connId], 'attributes', 'municipal_permit');
          if (municipalPermits) {
            for (let prop in municipalPermits) {
              let municipalPermitValue = municipalPermits[prop];
              if (
                municipalPermitValue &&
                (municipalPermitValue == 'Required' || municipalPermitValue == 'Submitted' || municipalPermitValue == 'Approved')
              )
                municipalPermit.count = municipalPermit.count ? municipalPermit.count + 1 : 1;
            }
          }
          billing.units.forEach((item, index) => {
            if (item.item_types.connection && CalcStatement(item.include, connections[connId].attributes)) {
              item.count = item.count ? item.count + 1 : 1;
            }
          });
          for (var sectionId in connections[connId].sections) {
            // special calc the misc permit fees
            let permitFeesAttr = PickAnAttribute(connections[connId].sections[sectionId].multi_attributes, 'permit_fees');
            if (permitFeesAttr) {
              permitFees.count++;
              permitFees.total += Number(permitFeesAttr.replace(/\$/g, ''));
            }
            // special calc for sidewalk and misc permits
            let sidewalkPermits = SquashNulls(connections[connId].sections[sectionId], 'multi_attributes', 'sidewalk_cut_permit');
            if (sidewalkPermits) {
              for (let prop in sidewalkPermits) {
                let sidewalkPermitValue = sidewalkPermits[prop];
                if (
                  sidewalkPermitValue &&
                  (sidewalkPermitValue == 'Required' || sidewalkPermitValue == 'Submitted' || sidewalkPermitValue == 'Approved')
                )
                  sidewalkPermit.count = sidewalkPermit.count ? sidewalkPermit.count + 1 : 1;
              }
            }
            let miscPermits = SquashNulls(connections[connId].sections[sectionId], 'multi_attributes', 'misc_permit_status');
            if (miscPermits) {
              for (let prop in miscPermits) {
                let miscPermitValue = miscPermits[prop];
                if (miscPermitValue && (miscPermitValue == 'Required' || miscPermitValue == 'Submitted' || miscPermitValue == 'Approved')) {
                  if (
                    Object.values(connections[connId].sections[sectionId].multi_attributes?.misc_permit_requires_plan_profile || {}).some(
                      (x) => x
                    )
                  ) {
                    miscPermitsWithPlanProfile.count = miscPermitsWithPlanProfile.count ? miscPermitsWithPlanProfile.count + 1 : 1;
                  } else {
                    miscPermit.count = miscPermit.count ? miscPermit.count + 1 : 1;
                  }
                }
              }
            }
            // check for new municipal permit
            let municipalPermits = SquashNulls(connections[connId].sections[sectionId], 'attributes', 'municipal_permit');
            if (municipalPermits) {
              for (let prop in municipalPermits) {
                let municipalPermitValue = municipalPermits[prop];
                if (
                  municipalPermitValue &&
                  (municipalPermitValue == 'Required' || municipalPermitValue == 'Submitted' || municipalPermitValue == 'Approved')
                )
                  municipalPermit.count = municipalPermit.count ? municipalPermit.count + 1 : 1;
              }
            }
            billing.units.forEach((item, index) => {
              if (item.item_types.section && CalcStatement(item.include, connections[connId].sections[sectionId].multi_attributes)) {
                item.count = item.count ? item.count + 1 : 1;
              }
            });
          }
        }
        let engineeringContractor = '';
        engineeringContractor =
          (await FirebaseWorker.ref(`photoheight/jobs/${job_id}/metadata/engineering_contractor`)
            .once('value')
            .then((s) => s.val())) || '';
        var totals = [{ price: 80, count: 0, label: 'Application Fee', total: 80 }];
        var app_cost = 0;
        if (engineeringContractor != 'Westwood Professional Services') {
          totals = [{ price: 80, count: 1, label: 'Application Fee', total: 80 }]; // magic number
          app_cost = 80;
        }

        // If we have PCI Ready for Invoice Don't count this
        // They were already charged once
        if (readyInvoiceFlag) {
          totals = [{ price: 0, count: 0, label: 'Application Fee', total: 0 }]; // magic number;
          app_cost = 0;
        }

        // manual addition for municipal permit
        billing.units.splice(24, 0, miscPermit);
        // special calc for billable hours
        let fieldBillableHours =
          (await FirebaseWorker.ref(`photoheight/jobs/${job_id}/metadata/field_billable_hours`)
            .once('value')
            .then((s) => s.val())) || 0;
        let officeBillableHours =
          (await FirebaseWorker.ref(`photoheight/jobs/${job_id}/metadata/office_billable_hours`)
            .once('value')
            .then((s) => s.val())) || 0;
        let stopwatchHours = await GetBillingHourly(job_id);
        billing.units.push({ price: 96, count: Number(fieldBillableHours) + stopwatchHours.field, label: 'Field Billable Hours' }); // magic number
        billing.units.push({ price: 109, count: Number(officeBillableHours) + stopwatchHours.office, label: 'Office Billable Hours' }); // magic number
        billing.units.forEach((item) => {
          item.count = item.count || 0;
          item.total = item.price * item.count;
          app_cost += item.total;
          if (item.total % 1) {
            // change item.total to have 2 decimal places
            item.total = parseFloat(item.total).toFixed(2);
          }
          totals.push(item);
        });
        // manual addition for misc permit fees
        if (permitFees.total % 1) {
          permitFees.total = parseFloat(permitFees.total).toFixed(2);
        }
        // totals.splice(25, 0, permitFees);
        app_cost += Number(permitFees.total);
        app_cost = parseFloat(app_cost).toFixed(2);
        let notesText = Object.values(notes)
          .map((x) => `WARNING: ${x.count} ${x.text}. `)
          .join('');
        return { pole_counts, app_cost, billingTotals: totals, app_type, notes: notesText };
      });
  }
}

export async function GetBillingHourly(jobId) {
  let userData = await FirebaseWorker.ref(`photoheight/company_space/katapult/user_data`)
    .once('value')
    .then((s) => s.val());
  let billable = { field: 0, office: 0 };
  for (let uid in userData) {
    for (let id in userData[uid].stopwatch?.records) {
      let item = userData[uid].stopwatch?.records[id];
      if (item.started && item.stopped && item.work_type != '5G (Describe)' && item.job_id == jobId) {
        let hr = (new Date(item.stopped).getTime() - new Date(item.started).getTime()) / (1000 * 3600);
        // Round to nearest .25
        hr = Number((Math.round(hr * 4) / 4).toFixed(2));
        if (item.billable_rate == '75') {
          billable.office += hr;
        } else if (item.billable_rate == '65') {
          billable.field += hr;
        }
      }
    }
  }
  return billable;
}
