import { PickAnAttribute } from '../../../modules/PickAnAttribute';

export function getAddressData(coordinateList, index, totalCount, requestDelay, jobRef, options, progressCallback, finalCallback) {
  options = options || {};
  options.addressAttributes = options.addressAttributes || {};

  // Get the geocoder object
  var geocoder = new google.maps.Geocoder();

  // Build a lookup of the address attributes to use for each address component
  let addressAttributeLookup = {};
  // Check if each of the address attributes exist in the options and if they do, use the
  // value as the attribute if the value is a string. Otherwise, use the default attributes
  if (options.addressAttributes.street_number) {
    addressAttributeLookup['street_number'] =
      typeof options.addressAttributes.street_number === 'string' ? options.addressAttributes.street_number : 'street_number';
  }
  if (options.addressAttributes.street_name) {
    addressAttributeLookup['route'] =
      typeof options.addressAttributes.street_name === 'string' ? options.addressAttributes.street_name : 'street_name';
  }
  if (options.addressAttributes.municipality) {
    addressAttributeLookup['locality'] =
      typeof options.addressAttributes.municipality === 'string' ? options.addressAttributes.municipality : 'municipality';
  }
  if (options.addressAttributes.township) {
    addressAttributeLookup['administrative_area_level_3'] =
      typeof options.addressAttributes.township === 'string' ? options.addressAttributes.township : 'township';
  }
  if (options.addressAttributes.county) {
    addressAttributeLookup['administrative_area_level_2'] =
      typeof options.addressAttributes.county === 'string' ? options.addressAttributes.county : 'county';
  }
  if (options.addressAttributes.state) {
    addressAttributeLookup['administrative_area_level_1'] =
      typeof options.addressAttributes.state === 'string' ? options.addressAttributes.state : 'state';
  }
  if (options.addressAttributes.zip_code) {
    addressAttributeLookup['postal_code'] =
      typeof options.addressAttributes.zip_code === 'string' ? options.addressAttributes.zip_code : 'zip_code';
  }

  // Only apply the attributes updates on the first iteration
  if (index == 0) {
    createModelAttributes(addressAttributeLookup, options);
  }

  // Check if we have completed the list
  if (index < coordinateList.length) {
    // Get the node data and make sure we have a lat long
    var nodeData = coordinateList[index];

    // Check if the node already has all of the needed address data
    // (meaning we should skip making a request for the location data)
    var skipRequest = true;
    if (options.addIndividualAddressAttr) {
      for (let addressComponentOption in addressAttributeLookup) {
        let addressComponentAttribute = addressAttributeLookup[addressComponentOption];
        if (!PickAnAttribute(nodeData.attributes, addressComponentAttribute)) {
          skipRequest = false;
        }
      }
    }
    if (options.addFormattedAddressAttr) {
      if (!PickAnAttribute(nodeData.attributes, 'address')) skipRequest = false;
    }

    if (nodeData.lat && nodeData.long && nodeData.key && skipRequest == false) {
      // Update the progress text and percent complete
      if (progressCallback) {
        // todo add support for this in callers
        progressCallback({
          text: `Requesting address data for ${options.modelDefaults.ordering_attribute_label}: ${
            nodeData[options.modelDefaults.ordering_attribute]
          }`,
          percent: (index / totalCount) * 100
        });
      }

      // Set a timeout in order to not overload the geocoder
      setTimeout(
        () => {
          // Set a flag for attempting again
          var shouldReattempt = false;
          // Call the geocoder
          geocoder.geocode(
            {
              location: { lat: nodeData.lat, lng: nodeData.long }
            },
            (results, status) => {
              if (status === 'OK') {
                // Reset delay
                requestDelay = 5;
                // Create an object to hold the node updates
                var nodeUpdate = {};

                // Default all values to blanks
                if (options.addIndividualAddressAttr) {
                  for (let addressComponentOption in addressAttributeLookup) {
                    let addressComponentAttribute = addressAttributeLookup[addressComponentOption];
                    nodeUpdate[`${nodeData.key}/attributes/${addressComponentAttribute}/button_added`] = '';
                  }
                }
                if (options.addFormattedAddressAttr) {
                  // Remove the old formatted_address attribute if it was on the node
                  nodeUpdate[nodeData.key + '/attributes/formatted_address/button_added'] = null;
                  nodeUpdate[nodeData.key + '/attributes/address/button_added'] = '';
                }

                let desiredResults = results[0];
                // If we want to prioritze by street number, loop through all the data until
                // we find a result with a street number and use that result instead of results[0]
                if (options.prioritizeStreetNumber) {
                  outerLoop: for (let i = 0; i < results.length; i++) {
                    if (results[i] && results[i].address_components) {
                      for (let j = 0; j < results[i].address_components.length; j++) {
                        if (
                          results[i].address_components[j].types &&
                          results[i].address_components[j].types.indexOf('street_number') != -1
                        ) {
                          desiredResults = results[i];
                          break outerLoop;
                        }
                      }
                    }
                  }
                }
                // Check if we have the data we need
                if (desiredResults && desiredResults.address_components) {
                  if (options.addIndividualAddressAttr) {
                    for (var i = 0; i < desiredResults.address_components.length; i++) {
                      // Get the address data object
                      let addressComponent = desiredResults.address_components[i];
                      // Check if we have types in the address data
                      if (addressComponent.types) {
                        // Check if the each address component in the lookup is found in the types
                        for (let addressComponentOption in addressAttributeLookup) {
                          if (addressComponent.types.indexOf(addressComponentOption) != -1) {
                            let addressComponentAttribute = addressAttributeLookup[addressComponentOption];
                            // Set the attribute from the lookup
                            if (addressComponentOption == 'administrative_area_level_1' && options.stateAbbreviation)
                              nodeUpdate[`${nodeData.key}/attributes/${addressComponentAttribute}/button_added`] =
                                addressComponent.short_name;
                            else
                              nodeUpdate[`${nodeData.key}/attributes/${addressComponentAttribute}/button_added`] =
                                addressComponent.long_name;
                          }
                        }
                      }
                    }
                  }
                  // Formatted address
                  if (options.addFormattedAddressAttr) {
                    nodeUpdate[nodeData.key + '/attributes/address/button_added'] = desiredResults.formatted_address;
                  }
                }
              } else {
                // If we have a OVER_QUERY_LIMIT error, update the delay and try again
                if (status == 'OVER_QUERY_LIMIT') {
                  requestDelay += 5;
                  shouldReattempt = true;
                }
              }

              // If we aren't reattempting this item, increase the index
              if (!shouldReattempt) index++;

              // Check if we should reattempt or if there are no updates
              if (shouldReattempt == true || !nodeUpdate || (nodeUpdate && Object.keys(nodeUpdate).length == 0)) {
                // Call function again for next item to try
                getAddressData(coordinateList, index, totalCount, requestDelay, jobRef, options, progressCallback, finalCallback);
              } else {
                // Update the node
                jobRef.child('nodes').update(nodeUpdate, (error) => {
                  // Call function again for next item to try
                  getAddressData(coordinateList, index, totalCount, requestDelay, jobRef, options, progressCallback, finalCallback);
                });
              }
            }
          );

          // Delay by the requestDelay plus some random amount added
        },
        requestDelay * (Math.random() / 2 + 1)
      );
    } else {
      // If we don't have a lat and long, skip this item and call the next one
      getAddressData(coordinateList, ++index, totalCount, requestDelay, jobRef, options, progressCallback, finalCallback);
    }
  } else {
    // Call the final callback if we have completed the list
    if (finalCallback) finalCallback();
  }
}

async function createModelAttributes(addressAttributeLookup, options) {
  if (options.firebaseRef && options.jobCreator && options.userGroup && options.otherAttributes) {
    let hasPermission = await options.firebaseRef
      .child(`photoheight/company_space/${options.jobCreator}/companies_with_write_model_access/${options.userGroup}`)
      .once('value')
      .then((s) => s.val());
    let modelUpdate = {};
    let attributePriority = 218;

    if (hasPermission) {
      if (options.addIndividualAddressAttr) {
        for (let addressComponentOption in addressAttributeLookup) {
          let addressComponentAttribute = addressAttributeLookup[addressComponentOption];
          if (options.jobCreator && !options.otherAttributes[addressComponentAttribute]) {
            modelUpdate[`company_space/${options.jobCreator}/models/attributes/${addressComponentAttribute}`] = {
              attribute_types: ['node'],
              gui_element: 'textbox',
              editability: 'uneditable',
              priority: attributePriority++
            };
          }
        }
      }
      if (options.addFormattedAddressAttr) {
        if (options.jobCreator && !options.otherAttributes.address) {
          modelUpdate[`company_space/${options.jobCreator}/models/attributes/address`] = {
            attribute_types: ['node'],
            gui_element: 'textarea',
            editability: 'uneditable',
            priority: 217
          };
        }
      }
    }
  }
}
