import { FirebaseEncode } from './FirebaseEncode.js';
import { UpdateJobPermissions } from './UpdateJobPermissions.js';

/**
 * Updates the job's archive status in the user's job permissions and folder structure
 *
 * @param {String} jobId - The key of the job to update
 * @param {String} userGroup - The user group of the user to archive the job for. The job will only be archived in this user group's job list and folder structure
 * @param {String} archiveStatus - The new archive status to set - either 'active' or 'archived'
 * @param {Object} options - Used to set various options.
 * @param {Object} options.update - The update object to apply the updates to. If this is null, the function will run the update automatically.
 * @param {Object} options.firebase - A reference to firebase that can be supplied in a functions context. If null, will fall back to FirebaseWorker.
 * @param {Object} options.projectFolder - The project folder path for the job found at photoheight/jobs/{jobId}/project_folder. You can save a fetch from Firebase if you provide this
 * @param {Object} options.jobChooserOptions - See documentation for jobChooserOptions in the UpdateJobPermissions module
 */
export async function SetJobArchiveStatus(jobId, userGroup, archiveStatus, options) {
  if (!jobId) {
    throw new Error('No job id provided');
  }
  if (!userGroup) {
    throw new Error('No user group provided');
  }
  if (archiveStatus != 'active' && archiveStatus != 'archived') {
    throw new Error('Invalid archive status');
  }
  options = options || {};
  let shouldRunUpdate = options.update == null;
  options.update = options.update || {};
  let firebaseRef = options.firebase ? options.firebase.database() : FirebaseWorker;

  // Fetch the project folder from the job if not provided in the options
  let projectFolder =
    options.projectFolder ||
    (await firebaseRef
      .ref(`photoheight/jobs/${jobId}/project_folder`)
      .once('value')
      .then((s) => s.val()));
  let encodedPath = projectFolder.split('/').map(FirebaseEncode.encode).join('/folders/');

  // Remove the job from the folder structure if archiving. Add it back if unarchiving.
  options.update[`photoheight/company_space/${userGroup}/project_folders/${encodedPath}/jobs/${jobId}`] =
    archiveStatus == 'archived' ? null : true;

  await UpdateJobPermissions(
    jobId,
    userGroup,
    { status: archiveStatus },
    {
      jobChooserOptions: options.jobChooserOptions,
      firebase: options.firebase,
      update: options.update
    }
  );

  if (shouldRunUpdate) await firebaseRef.ref().update(options.update);
}
