self.CanvasClockScript = class {
  constructor() {
    this.canvas = null;
    this.ctx = null;
    this.prevText = null;
    this.intervalId = null;
    this.active = false;

    self.addEventListener('message', (e) => {
      if (e.data.msg === 'init') {
        this.init(e.data.canvas);
      } else if (e.data.msg === 'start') {
        this.start();
      } else if (e.data.msg === 'stop') {
        this.stop();
      }
    });
  }

  init(canvasToSet) {
    this.canvas = canvasToSet;
    this.ctx = this.canvas.getContext('2d');
  }

  start() {
    if (this.intervalId == null && this.canvas && this.ctx) {
      this.intervalId = setInterval(() => {
        let text = new Date().toTimeString().split(' ')[0];
        if (text !== this.prevText) {
          this.prevText = text;
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          let textHeight = this.canvas.height * 0.8;
          this.ctx.fillStyle = '#212121';
          this.ctx.font = `${textHeight}px Arial`;
          let textWidth = this.ctx.measureText(text).width;
          this.ctx.fillText(text, (this.canvas.width - textWidth) / 2, (this.canvas.height - textHeight) / 2 + textHeight);
        }
      }, 10);
    }
  }

  stop() {
    if (this.intervalId != null) clearInterval(this.intervalId);
    this.intervalId = null;
    this.active = false;
  }
};

new CanvasClockScript();
