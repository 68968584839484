import { SquashNulls } from './SquashNulls.js';
import { GetNewAttributeValue } from './GetNewAttributeValue.js';
import { Path } from './Path.js';
import { GeneratePushID } from './GeneratePushID.js';

export function ApplyGroupAttribute(property, itemAttributes, otherAttributes) {
  // Get the list of attributes to add if it's a group type, or just the single attribute if not
  let attributesToAdd = SquashNulls(otherAttributes, property, 'group_items') || [];
  if (attributesToAdd.length > 0) {
    // Add each attribute item
    attributesToAdd.forEach((item) => {
      let attributeName = typeof item === 'string' ? item : item.attribute;
      let newValue = item.value || GetNewAttributeValue(attributeName, otherAttributes);
      let newKey = GeneratePushID();
      Path.set(itemAttributes, `${attributeName}.${newKey}`, newValue);
    });
    // Delete the group attribute data if we used it
    delete itemAttributes[property];
  }
}
