import { GeneratePushID } from '../GeneratePushID.js';
import { GeofireTools } from '../GeofireTools.js';
import { Path } from '../Path.js';
import { DataLayer } from './DataLayer.js';

export let Nodes = {
  /*
    Used to create a new node
    lat: latitude of the node
    lng: longitude of the node
    options: various options:
      update: an update object to insert the update statement
    return: the new node object
  */
  create: function (lat, lng, otherAttributes, firebase, options) {
    options = options || {};
    options.jobStyles = options.jobStyles || {};
    // Initial node data
    let newNodeKey = options.key || GeneratePushID();
    let newNode = { latitude: lat, longitude: lng };
    DataLayer._addTimeStamp(newNode, 'node', 'created', options.method, firebase);
    // Add button property if given
    if (options.button) newNode.button = options.button;
    // Add gps location property if given
    if (options.gpsLocation) newNode.gps_location = options.gpsLocation;
    // Set attributes
    this.setAttributes(newNodeKey, newNode, options.attributes, otherAttributes, {
      jobId: options.jobId,
      jobStyles: options.jobStyles
    });
    // Modify the update object if provided
    if (options.update) {
      options.update[Path.join([options.jobId, 'nodes', newNodeKey], '/')] = newNode;
      // Calc the geofire path.
      let geoPath = options.geoPath ?? Path.join([options.jobId, 'geohash'], '/') + '/';
      // Set the geofire data for the node in the update as well
      GeofireTools.setGeohash('nodes', newNode, newNodeKey, options.jobStyles, options.update, {
        geoPath,
        nodeConnections: options.nodeConnections
      });
    }
    return {
      key: newNodeKey,
      data: newNode
    };
  },
  move: async function (nodeKey, node, lat, lng, firebase, options) {
    options = options ?? {};
    // Create a copy of the node to apply the new location to.
    let nodeData = Path.copy(node);
    // Apply latitude and longitude to the node copy.
    nodeData.latitude = lat ?? nodeData.latitude;
    nodeData.longitude = lng ?? nodeData.longitude;
    // Apply the changes to the update object if it exists.
    if (options.update) {
      options.update[Path.join([options.jobId, 'nodes', nodeKey, 'latitude'], '/')] = nodeData.latitude;
      options.update[Path.join([options.jobId, 'nodes', nodeKey, 'longitude'], '/')] = nodeData.longitude;
      // Calc the geofire path.
      let geoPath = options.geoPath ?? Path.join([options.jobId, 'geohash'], '/') + '/';
      // Get connections for this node.
      let nodeConnections = await this.getNodeConnections(nodeKey, options.jobId, firebase);
      // Convert it to the connection lookup in geohash.
      let connLookup = {};
      for (let connKey in nodeConnections) connLookup[connKey] = nodeConnections[connKey].node_id_1 == nodeKey ? 1 : 2;
      // Update the geofire location for the node.
      GeofireTools.updateLocation(Path.join([geoPath, nodeKey], '/') + '/', [nodeData.latitude, nodeData.longitude], 10, options.update);
      // Loop through them and update their endpoints.
      for (let connKey in nodeConnections) {
        let connData = nodeConnections[connKey];
        let endpointIndex = connData.node_id_1 == nodeKey ? 1 : 2;
        // Get the adjacent node.
        let otherNodeKey = connData.node_id_1 == nodeKey ? connData.node_id_2 : connData.node_id_1;
        let otherNode = await firebase
          .database()
          .ref(`photoheight/jobs/${options.jobId}/nodes/${otherNodeKey}`)
          .once('value')
          .then((s) => s.val());
        DataLayer.Connections.updateEndpoint(connKey, connData, endpointIndex, nodeKey, nodeData, otherNodeKey, otherNode, options);
      }
    }
    // Return the modified node.
    return {
      key: nodeKey,
      data: nodeData
    };
  },
  setAttributes: function (nodeKey, node, attributes, otherAttributes, options) {
    DataLayer.setItemAttributes('nodes', nodeKey, null, node, attributes, otherAttributes, options);
  },
  delete: async function (nodeKey, firebase, options) {
    options = options ?? {};
    if ([nodeKey, options.jobId, options.update].every((x) => x != null)) {
      // Calc the geopath.
      let geoPath = options.geoPath ?? Path.join([options.jobId, 'geohash'], '/') + '/';
      // Delete the node.
      options.update[Path.join([options.jobId, 'nodes', nodeKey], '/')] = null;
      // Delete the node's geohash.
      GeofireTools.removeGeohash('nodes', null, nodeKey, options.update, { geoPath });
      // Fetch the connection keys for this node.
      let connKeys = Object.keys(await this.getNodeConnections(nodeKey, options.jobId, firebase));
      // Loop connection keys and delete each connection.
      await Promise.all(connKeys.map((connKey) => DataLayer.Connections.delete(connKey, options)));
    } else {
      throw 'Missing Required Options';
    }
  },
  getNodeConnections: async function (nodeKey, jobKey, firebase) {
    return await Promise.all(
      [1, 2].map((i) => {
        // Return a firebase query.
        return firebase
          .database()
          .ref(`photoheight/jobs/${jobKey}/connections`)
          .orderByChild(`node_id_${i}`)
          .equalTo(nodeKey)
          .once('value')
          .then((s) => s.val());
      })
    ).then((res) => {
      // Get an object containing all connections.
      let temp = {};
      res.forEach((val) => {
        for (let key in val) temp[key] = val[key];
      });
      return temp;
    });
  }
};
