import { GetJobData } from './GetJobData.js';
import { IncrementFolderCounter } from './IncrementFolderCounter.js';

/**
 * Function for unassociating one or all photos from a node or section
 * @param {String} jobId - The id of the job where the item and photo exists
 * @param {String} itemType - The type of item (node or section)
 * @param {String} itemId - The id of the item
 * @param {String} connId - The connection id for the section. You only need to provide this if you are updating a section
 * @param {Object} itemData - The data object for the item (this is used to iterate over the item's photo list)
 * @param {Object} update - The update object to apply updates to
 * @param {String} photoId - The id of the photo to remove. If this is not provided, all photos on the item will be removed.
 * @param {String} userGroup - The user group for the user to be used when incrementing the counters
 * @param {Object} options - Used to set options
 * @param {Boolean} options.removePhotoFromItem - Set this to true if you also want to remove the photo from the item's photo
 *    list as part of the update. You might want this false if you are deleting the item entirely and don't want to cause
 *    an update collision. This defaults to true.
 * @param {Boolean} options.updateFolderCount - Set this to update folder counts if the photo has no association on it after being removed. Defaults to true
 */
export async function UnassociatePhotoFromItem(jobId, itemType, itemId, connId, itemData, update, photoId, userGroup, options) {
  options = options || {};
  options.removePhotoFromItem = options.removePhotoFromItem == null ? true : options.removePhotoFromItem;
  options.updateFolderCount = options.updateFolderCount == null ? true : options.updateFolderCount;
  // If a single photoId was not provided, then unassociate all photos
  let unassociatedPhotosIds = [];
  if (photoId) {
    unassociatedPhotosIds = [photoId];
  } else {
    unassociatedPhotosIds = Object.keys(itemData.photos || {});
  }
  // Make a path for the item's associated_locations record on the photo
  let associatedLocationPath = itemType == 'node' ? itemId : `${connId}:${itemId}`;
  // Remove item from associated_locations for all photos
  for (let photoId of unassociatedPhotosIds) {
    update[`photos/${photoId}/associated_locations/${associatedLocationPath}`] = null;
    // Check if we should remove the photo from the item as well
    if (options.removePhotoFromItem) {
      let itemPath = itemType == 'node' ? `nodes/${itemId}` : `connections/${connId}/sections/${itemId}`;
      update[`${itemPath}/photos/${photoId}`] = null;
    }
  }
  // Clean up photo summary and counts
  let data = await GetJobData(
    jobId,
    unassociatedPhotosIds.map((x) => `photos/${x}`)
  );
  for (let photoPath in data) {
    let photoId = photoPath.split('/')[1];
    // Check if the photo still has other associated_locations after the item would be removed
    let associatedLocations = data[photoPath].associated_locations || {};
    delete associatedLocations[associatedLocationPath];
    if (Object.keys(associatedLocations).length == 0) {
      update[`photo_summary/${photoId}/associated`] = null;
      if (options.updateFolderCount) {
        if (data[photoPath].camera_id) {
          if (data[photoPath].folder_id) {
            IncrementFolderCounter(jobId, data[photoPath].folder_id, data[photoPath].camera_id, userGroup, 'numAssociated', {
              negative: true
            });
          } else {
            let folders = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/photo_folders`)
              .orderByChild('tags')
              .equalTo(data[photoPath].tags)
              .once('value')
              .then((s) => s.val());
            for (let folderId in folders) {
              IncrementFolderCounter(jobId, folderId, data[photoPath].camera_id, userGroup, 'numAssociated', { negative: true });
              break;
            }
          }
        }
      }
    }
  }
}
