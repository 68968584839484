import { SquashNulls } from '../modules/SquashNulls.js';
import { DataLayer } from './DataLayer/DataLayer.js';
import { GeofireTools } from '../modules/GeofireTools.js';

export async function InsertJobReferenceConnection(
  activeJobId,
  activeNodeId,
  activeNodeLocation,
  referenceJobId,
  referenceNodeId,
  referenceNodeLocation,
  modelConfig,
  otherAttributes,
  jobStyles,
  jobCreator,
  activeButton,
  mappingButtons,
  firebaseRef
) {
  if (activeNodeId && activeNodeLocation && referenceNodeLocation && referenceJobId != activeJobId && firebaseRef) {
    let buttonModel = mappingButtons[activeButton];

    // Create the same reference connection and reference node in the reference job
    // Add the reference data to the created reference in the reference job
    let activeJobUpdate = {};
    let referenceJobUpdate = {};
    // Update the real poles in each job to be the primary pole for the other job
    activeJobUpdate[`nodes/${activeNodeId}/reference_data`] = {
      primary_pole: true,
      job_id: referenceJobId
    };
    referenceJobUpdate[`nodes/${referenceNodeId}/reference_data`] = {
      primary_pole: true,
      job_id: activeJobId
    };

    // Create reference node object for the active job (meaning it should reference the reference job)
    let newNodeResults = getNodeObject(
      referenceNodeLocation[0],
      referenceNodeLocation[1],
      {
        key: referenceNodeId,
        buttonModel,
        button: activeButton,
        jobStyles,
        otherAttributes
      },
      firebaseRef
    );
    let refNodeForActiveJob = newNodeResults.data;
    // Set up the reference_data for the new reference node
    refNodeForActiveJob.reference_data = {
      primary_pole: false,
      job_id: referenceJobId
    };

    // Make a copy of refNodeForActiveJob and update the data to be for the reference
    // node for the reference job (meaning it should reference the active job)
    let refNodeForRefJob = JSON.parse(JSON.stringify(refNodeForActiveJob));
    refNodeForRefJob.reference_data.job_id = activeJobId;
    refNodeForRefJob.latitude = activeNodeLocation[0];
    refNodeForRefJob.longitude = activeNodeLocation[1];

    // Set the reference nodes for the active and reference job
    activeJobUpdate[`nodes/${referenceNodeId}`] = refNodeForActiveJob;
    referenceJobUpdate[`nodes/${activeNodeId}`] = refNodeForRefJob;

    GeofireTools.setGeohash('nodes', refNodeForActiveJob, referenceNodeId, jobStyles, activeJobUpdate);
    GeofireTools.setGeohash('nodes', refNodeForRefJob, activeNodeId, jobStyles, referenceJobUpdate);

    // If the models are Charter, RDS, or Techserv, then the drawn connection
    // should be an Aerial since this is for a Charter job
    let overrideButtonModel = null;
    if (jobCreator == 'charter' || jobCreator == 'rainbow_design_services' || jobCreator == 'techserv') {
      overrideButtonModel = mappingButtons['aerial_path'];
    }

    // Create reference connections for the active job and reference jobs (active job
    // connection should go to the reference node, and reference job connection
    // should go to the active node)
    let createdConnection = createConnection(
      referenceNodeId,
      refNodeForActiveJob,
      activeNodeId,
      refNodeForRefJob,
      {
        update: activeJobUpdate,
        button: activeButton,
        buttonModel: overrideButtonModel || buttonModel,
        jobStyles,
        modelConfig,
        otherAttributes
      },
      firebaseRef
    );
    createConnection(
      activeNodeId,
      refNodeForRefJob,
      referenceNodeId,
      refNodeForActiveJob,
      {
        update: referenceJobUpdate,
        connId: createdConnection.id,
        button: activeButton,
        buttonModel,
        jobStyles,
        modelConfig,
        otherAttributes
      },
      firebaseRef
    );

    // Merge the updates
    let update = {};
    for (let path in activeJobUpdate) {
      update[`${activeJobId}/${path}`] = activeJobUpdate[path];
    }
    for (let path in referenceJobUpdate) {
      update[`${referenceJobId}/${path}`] = referenceJobUpdate[path];
    }
    if (Object.keys(update).length) {
      return firebaseRef
        .database()
        .ref('photoheight/jobs')
        .update(update)
        .then(() => {
          return { success: true, message: 'Reference created successfully.' };
        })
        .catch((error) => {
          console.log('Error creating refernece', error);
          return { success: false, message: 'There was an error creating the reference.' };
        });
    }
  } else {
    return { success: false, message: 'There was an error creating the reference.' };
  }
}

function createConnection(node1Key, node1, node2Key, node2, options, firebaseRef) {
  options = options || {};
  let button = options.buttonModel;
  if (button.connection != null) {
    // Create an attributes list for the connection
    let attributes = [];
    for (var property in button.connection.attributes) {
      let key = 'button_added';
      if (SquashNulls(options.otherAttributes, property, 'gui_element') == 'table')
        key = firebaseRef.database().ref(`photoheight/jobs/`).push().key;
      if (button.connection.attributes[property].value != null) {
        attributes.push({
          attribute: property,
          key,
          value: button.connection.attributes[property].value
        });
      }
    }
    // Create the connection
    var connectionResults = DataLayer.Connections.create(node1Key, node1, node2Key, node2, options.otherAttributes, firebaseRef, {
      jobStyles: options.jobStyles,
      method: 'mobile',
      button: options.button,
      attributes,
      key: options.connId,
      connection_length_attributes: SquashNulls(options.modelConfig, 'connection_length_attributes'),
      update: options.update
    });
  }
  return {
    id: connectionResults.key
  };
}

function getNodeObject(latitude, longitude, options, firebaseRef) {
  options = options || {};
  // Get the active button so we know what attributes to add
  let button = options.buttonModel;
  // Build an attribute list to pass into the node
  let attributes = [];
  for (var property in button.node.attributes) {
    if (button.node.attributes[property].value != null) {
      let key = 'button_added';
      if (SquashNulls(options.otherAttributes, property, 'gui_element') == 'table')
        key = firebaseRef.database().ref(`photoheight/jobs/`).push().key;
      // Stamp an attribute if the button is NOT an assessment and the value is not null
      if (button.view != 'assessment' && button.node.attributes[property].value != null) {
        attributes.push({
          attribute: property,
          key,
          value: button.node.attributes[property].value
        });
        // Stamp an attribute if the button IS an assessment and the attribute is type 'stamp_on_create'
      } else if (
        button.view == 'assessment' &&
        button.node.attributes[property].type == 'stamp_on_create' &&
        button.node.attributes[property].value != null &&
        button.node.attributes[property].attribute
      ) {
        attributes.push({
          attribute: button.node.attributes[property].attribute,
          key,
          value: button.node.attributes[property].value
        });
      }
    }
  }
  options.attributes = attributes;
  options.method = 'mobile';
  return DataLayer.Nodes.create(latitude, longitude, options.otherAttributes, firebaseRef, options);
}
