import { KatapultElement, html } from '../../mixins/katapult-element.js';

import '../google-map/google-map-elements.js';
import '../google-map/google-map-svg-text-marker.js';

/*global k, Polymer, firebase, google, katapultMaps*/
class MapOverlay extends KatapultElement {
  static get template() {
    return html` <style></style> `;
  }

  static get is() {
    return 'map-overlay';
  }
  static get properties() {
    return {
      map: {
        type: Object,
        value: null
      },
      img: {
        type: Object,
        value: null
      },
      anchor: {
        type: Object,
        value: null
      },
      anchorPos: {
        type: Object,
        value: null
      },
      chain: {
        type: Object,
        value: null
      },
      chainPos: {
        type: Object,
        value: null
      },
      points: {
        //aX, aY, bX, bY, dX, dY
        type: Object,
        value: {}
      },
      initAng: {
        type: Number,
        value: null
      },
      initDist: {
        type: Number,
        value: null
      },
      oldCtr: {
        type: Object,
        value: null
      },
      topLeft: {
        type: Object,
        value: null
      },
      scale: {
        type: Number,
        value: null
      },
      imScale: {
        type: Number,
        value: 1
      },
      imRot: {
        type: Number,
        value: 0
      },
      chainRep: {
        type: Object,
        value: undefined
      },
      actionDialogData: {
        type: Object,
        value: {},
        notify: true
      }
    };
  }

  static get observers() {
    return ['actionDialogDataChanged(actionDialogData.*)'];
  }

  async ready() {
    super.ready();
  }

  actionDialogDataChanged(dat) {
    if (this.actionDialogData && this.img) {
      this.img.style.opacity = this.actionDialogData.sliderValue / 100;
    }
  }

  newOverlay(overlayImg) {
    this.img = document.createElement('img');
    this.map.getDiv().parentNode.appendChild(this.img); //add a new image overlay
    this.img.style.position = 'absolute';
    this.img.style.pointerEvents = 'none';
    this.img.style.overflow = 'visible';
    this.img.style.height = '50%';
    this.img.style.top = '25%';
    this.img.style.left = '25%';
    this.img.style.pointerEvents = 'auto';
    this.img.draggable = false;
    this.img.src = overlayImg; // set src to blob url

    this.img.onload = () => {
      this.imageIsLoaded();
    }; //call a funct when loaded
  }

  imageIsLoaded() {
    this.__dataHost.$.katapultMap.openActionDialog(
      //check inside of katapult-maps, mod the html to get map overlay options
      {
        title: 'Edit Overlay',
        icon: false,
        body: 'mapOverlayOptions',
        annotationData: this.data,
        buttons: [],
        cancel: () => {
          //loadRenderMap.annotationLocations[this.key].leader.setOptions({editable:false});
          this.cancel();
          pageElement.cancelPromptAction();
        }
      }
    );

    this.__dataHost.$.katapultMap.actionDialogData.sliderValue = 100; //set (global) slider value to 100%
    this.__dataHost.$.katapultMap.actionDialogData.overlayName = '';
    this.img.onclick = this.placeFirstAnchor.bind(this);
  }

  placeFirstAnchor(e) {
    //the first time you click the image...
    this.img.style.pointerEvents = 'none'; //make map clickable beneath
    this.img.onmouseout = null; //prevent image from becoming not transparent after this is called

    this.__dataHost.$.dropDetector.style.transform = 'scaleX("0.001") scaleY ("0.001")'; // dropDetector.ignoreDropEvents is undefined?;

    this.points.aX = e.offsetX; //Point A is a position relative to the image.  It's image position relative to the anchor and chain
    this.points.aY = e.offsetY; //gets position relative to element

    //anchor setup
    this.anchor = document.createElement('img');
    this.anchor.src = 'pointer.svg';
    this.anchor.style.pointerEvents = 'none';
    this.anchor.style.height = '40px';
    this.anchor.style.left = e.pageX - 13 + 'px'; //offset of 13,40 due to the size of the image - this centers the pin on the correct point
    this.anchor.style.top = e.pageY - this.__dataHost.$.toolbar.offsetHeight - 40 + 'px';
    this.anchor.style.position = 'absolute';

    this.img.parentNode.appendChild(this.anchor);
    this.points.bX = this.img.offsetLeft;
    this.points.bY = this.img.offsetTop;

    this.oldCtr = this.map.getCenter();

    this.map.addListener('center_changed', () => {
      this.scale = Math.pow(2, this.map.getZoom());

      var newCtr = this.map.getCenter();
      var xChange =
        this.map.getProjection().fromLatLngToPoint(this.oldCtr).x * this.scale -
        this.map.getProjection().fromLatLngToPoint(newCtr).x * this.scale;
      var yChange =
        this.map.getProjection().fromLatLngToPoint(this.oldCtr).y * this.scale -
        this.map.getProjection().fromLatLngToPoint(newCtr).y * this.scale;
      if (this.points.bX !== undefined) {
        this.points.bX += xChange;
        this.points.bY += yChange;
      }
      if (this.points.dX !== undefined) {
        this.points.dX += xChange;
        this.points.dY += yChange;
      }
      this.updatePos();
      this.oldCtr = newCtr;
    });

    this.map.addListener('zoom_changed', () => {
      this.scale = Math.pow(2, this.map.getZoom());

      var proj = this.map.getProjection();

      var topLeftOnMap = proj.fromLatLngToPoint(
        new google.maps.LatLng(this.map.getBounds().getNorthEast().lat(), this.map.getBounds().getSouthWest().lng())
      );

      var proj = this.map.getProjection();
      if (this.points.bX !== undefined) {
        var anchorPoint = proj.fromLatLngToPoint(this.anchorPos);

        this.points.bX = (anchorPoint.x - topLeftOnMap.x) * this.scale;
        this.points.bY = (anchorPoint.y - topLeftOnMap.y) * this.scale;
      }
      if (this.points.dX !== undefined) {
        var chainPoint = proj.fromLatLngToPoint(this.chainPos);
        this.points.dX = (chainPoint.x - topLeftOnMap.x) * this.scale;
        this.points.dY = (chainPoint.y - topLeftOnMap.y) * this.scale;
      }

      this.updatePos();
    });

    var listenA = google.maps.event.addDomListener(this.map.getDiv(), 'mousemove', (e) => {
      this.points.bX = e.offsetX;
      this.points.bY = e.offsetY;
      this.updatePos();
    });

    var listenB = google.maps.event.addDomListener(this.map.getDiv(), 'click', (e) => {
      //next map click...
      this.points.bX = e.offsetX; //Point B is the anchor's position relative to the page
      this.points.bY = e.offsetY;

      this.updatePos();

      this.img.style.pointerEvents = 'auto';
      google.maps.event.removeListener(listenA); //remove this one-shot
      google.maps.event.removeListener(listenB); //remove this one-shot
      this.anchor.style.pointerEvents = 'auto';
      this.anchor.ondragstart = (e) => {
        return false;
      };
      this.anchor.onmousedown = (e) => {
        this.anchor.style.pointerEvents = 'none';
        var listenAnchorA = google.maps.event.addDomListener(this.map.getDiv(), 'mousemove', (e) => {
          this.points.bX = e.offsetX;
          this.points.bY = e.offsetY;
          this.updatePos();
        });
        var listenAnchorB = google.maps.event.addDomListener(this.map.getDiv(), 'mouseup', (e) => {
          this.points.bX = e.offsetX;
          this.points.bY = e.offsetY;
          this.updatePos();
          this.anchor.style.pointerEvents = 'auto';
          google.maps.event.removeListener(listenAnchorA); //remove this one-shot
          google.maps.event.removeListener(listenAnchorB); //remove this one-shot
        });
      };

      this.img.onclick = this.chainPlacement.bind(this); //The next time you click the image, we will place the chain!
    });
  }

  updatePos() {
    var proj = this.map.getProjection();

    this.topLeft = proj.fromLatLngToPoint(
      new google.maps.LatLng(this.map.getBounds().getNorthEast().lat(), this.map.getBounds().getSouthWest().lng())
    );

    this.scale = Math.pow(2, this.map.getZoom());

    if (this.points.bX !== undefined && this.anchor !== undefined) {
      //if we have bX, we likely also have bY, aX, aY, and Anchor.
      this.anchor.style.left = this.points.bX - 13 + 'px';
      this.anchor.style.top = this.points.bY - 40 + 'px';

      this.img.style.left = this.points.bX - this.points.aX + 'px';
      this.img.style.top = this.points.bY - this.points.aY + 'px';

      this.img.style.transformOrigin = this.points.aX + 'px ' + this.points.aY + 'px';

      this.anchorPos = proj.fromPointToLatLng(
        new google.maps.Point(this.points.bX / this.scale + this.topLeft.x, this.points.bY / this.scale + this.topLeft.y)
      );
    }
    if (this.points.dX !== undefined && this.chain !== undefined) {
      //this means we likely have dY and Chain
      this.chain.style.left = this.points.dX - 13 + 'px';
      this.chain.style.top = this.points.dY - 40 + 'px';

      var bdDist = Math.sqrt(Math.pow(this.points.dX - this.points.bX, 2) + Math.pow(this.points.dY - this.points.bY, 2));

      var bdAng = (Math.asin((this.points.dX - this.points.bX) / bdDist) * 180) / Math.PI;
      if (this.points.dY < this.points.bY) {
        bdAng = 90 - bdAng;
      } else {
        bdAng = 270 + bdAng;
      }

      this.imScale = bdDist / this.initDist;

      this.imRot = this.initAng - bdAng;

      this.img.style.transform = `rotate(${this.imRot}deg) scaleX(${this.imScale}) scaleY(${this.imScale})`;

      this.chainPos = proj.fromPointToLatLng(
        new google.maps.Point(this.points.dX / this.scale + this.topLeft.x, this.points.dY / this.scale + this.topLeft.y)
      );
    }
  }

  chainReplacement() {
    if (this.anchor) this.anchor.remove();
    if (this.chain) this.chain.remove();
    this.anchor = undefined;
    this.chain = undefined;

    this.img.style.pointerEvents = 'auto';
    this.img.onclick = this.placeFirstAnchor.bind(this);
  }

  chainPlacement(e) {
    this.img.style.pointerEvents = 'none'; //make map clickable beneath
    this.img.style.position = 'absolute';

    this.points.dX = e.pageX;
    this.points.dY = e.pageY - this.__dataHost.$.toolbar.offsetHeight;
    //this.points.dX = e.offsetX;
    //this.points.dY = e.offsetY;

    //chain setup
    this.chain = document.createElement('img');
    this.chain.src = 'pointer.svg';
    this.chain.style.height = '40px';
    this.chain.style.opacity = '1';
    this.chain.style.position = 'absolute';
    this.chain.style.pointerEvents = 'none';
    this.img.parentNode.appendChild(this.chain);
    this.chain.style.left = this.points.dX - 13 + 'px';
    this.chain.style.top = this.points.dY - 40 + 'px';

    this.img.onclick = null;

    this.initDist = Math.sqrt(Math.pow(this.points.bX - this.points.dX, 2) + Math.pow(this.points.bY - this.points.dY, 2));
    this.initAng = (Math.asin((this.points.dX - this.points.bX) / this.initDist) * 180) / Math.PI;
    if (this.points.dY < this.points.bY) {
      this.initAng = 90 - this.initAng;
    } else {
      this.initAng = 270 + this.initAng;
    }

    this.initAng += this.imRot;
    this.initDist /= this.imScale;

    var listenC = google.maps.event.addDomListener(this.map.getDiv(), 'mousemove', (e) => {
      this.points.dX = e.offsetX;
      this.points.dY = e.offsetY;
      this.updatePos();
    });

    var listenD = google.maps.event.addDomListener(this.map.getDiv(), 'click', (e) => {
      //next map click...
      this.points.dX = e.offsetX;
      this.points.dY = e.offsetY;

      this.chain.style.left = this.points.dX - 13 + 'px';
      this.chain.style.top = this.points.dY - 40 + 'px';

      this.updatePos();

      google.maps.event.removeListener(listenC); //remove this one-shot
      google.maps.event.removeListener(listenD); //remove this one-shot
      this.chain.style.pointerEvents = 'auto';
      this.chain.ondragstart = (e) => {
        return false;
      };
      this.chain.onmousedown = (e) => {
        this.chain.style.pointerEvents = 'none';
        var listenChainA = google.maps.event.addDomListener(this.map.getDiv(), 'mousemove', (e) => {
          this.points.dX = e.offsetX;
          this.points.dY = e.offsetY;
          this.updatePos();
        });
        var listenChainB = google.maps.event.addDomListener(this.map.getDiv(), 'mouseup', (e) => {
          this.points.dX = e.offsetX;
          this.points.dY = e.offsetY;
          this.updatePos();
          this.chain.style.pointerEvents = 'auto';
          google.maps.event.removeListener(listenChainA); //remove this one-shot
          google.maps.event.removeListener(listenChainB); //remove this one-shot
        });
      };

      this.__dataHost.$.katapultMap.openActionDialog(
        //check inside of katapult-maps, mod the html to get map overlay options
        {
          title: 'Edit Overlay',
          icon: false,
          body: 'mapOverlayOptions',
          annotationData: this.data,
          buttons: [
            {
              title: 'Reset Nodes',
              callback: (e) => {
                this.chainReplacement();
              },
              attributes: { grey: '' }
            },
            {
              title: 'Glue to Map',
              callback: () => {
                this.glueImage();
                //loadRenderMap.annotationLocations[this.key].leader.setOptions({editable:false});
                pageElement.cancelPromptAction();
              },
              attributes: { 'secondary-color': '' }
            }
          ],
          cancel: () => {
            //loadRenderMap.annotationLocations[this.key].leader.setOptions({editable:false});
            this.cancel();
            pageElement.cancelPromptAction();
          }
        }
      );
    });
  }

  glueImage() {
    //Turn the IMG into an image using hot new Canvas
    var rect = this.img.getBoundingClientRect();
    var proj = this.map.getProjection();

    this.topLeft = proj.fromLatLngToPoint(
      new google.maps.LatLng(this.map.getBounds().getNorthEast().lat(), this.map.getBounds().getSouthWest().lng())
    );

    this.scale = Math.pow(2, this.map.getZoom());

    var ovTopLeft = proj.fromPointToLatLng(
      new google.maps.Point(
        rect.left / this.scale + this.topLeft.x,
        (rect.top - this.__dataHost.$.toolbar.offsetHeight) / this.scale + this.topLeft.y
      )
    );
    var ovBotRight = proj.fromPointToLatLng(
      new google.maps.Point(
        (rect.left + rect.width) / this.scale + this.topLeft.x,
        (rect.top + rect.height - this.__dataHost.$.toolbar.offsetHeight) / this.scale + this.topLeft.y
      )
    );

    var bounds = new google.maps.LatLngBounds();
    bounds.extend(ovTopLeft);
    bounds.extend(ovBotRight);

    // Create a canvas object.
    let canvas = document.createElement('canvas');

    // Create canvas context.
    let ctx = canvas.getContext('2d');

    // Assign width and height.
    canvas.width = rect.width;
    canvas.height = rect.height;

    let iSin = Math.abs(Math.sin((this.imRot * Math.PI) / 180));
    let iCos = Math.abs(Math.cos((this.imRot * Math.PI) / 180));
    let adjWidth = (iSin * canvas.height - iCos * canvas.width) / (iSin * iSin - iCos * iCos);
    let adjHeight = (iSin * canvas.width - iCos * canvas.height) / (iSin * iSin - iCos * iCos);

    ctx.globalAlpha = this.img.style.opacity;
    ctx.save(); //Canvas rotation
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((this.imRot * Math.PI) / 180);

    ctx.drawImage(this.img, -adjWidth / 2, -adjHeight / 2, adjWidth, adjHeight);
    ctx.restore();

    let key = FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/layers/list`).push().key;
    let photoLocation = `job_files/${this.job_id}/${key}`;

    FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/layers/list/${key}`).set({
      name: this.__dataHost.$.katapultMap.actionDialogData.overlayName || 'Image Overlay',
      type: 'Overlay Layer',
      options: {
        bounds: {
          north: bounds.getNorthEast().lat(),
          south: bounds.getSouthWest().lat(),
          east: bounds.getNorthEast().lng(),
          west: bounds.getSouthWest().lng()
        }
      }
    });

    // convert the canvas to a blob and save
    canvas.toBlob((blob) => {
      firebase
        .storage()
        .ref(photoLocation)
        .put(blob)
        .then(() => {
          const layer = {
            $key: key,
            type: 'Overlay Layer',
            options: {
              bounds: {
                north: bounds.getNorthEast().lat(),
                south: bounds.getSouthWest().lat(),
                east: bounds.getNorthEast().lng(),
                west: bounds.getSouthWest().lng()
              }
            }
          };
          this.__dataHost.toggleMapLayer(layer, true);
          this.cancel();
        });
    }, 'image/png');
  }

  cancel() {
    if (this.anchor) this.anchor.remove();
    if (this.chain) this.chain.remove();
    if (this.img) this.img.remove();
    this.__dataHost.$.dropDetector.style.transform = 'scaleX("1") scaleY ("1")';
  }
}

window.customElements.define(MapOverlay.is, MapOverlay);
