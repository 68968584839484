import { Path } from './Path.js';

export function CalcStatement(statement, properties) {
  // Check to see if statement is an object.
  if (statement && typeof statement === 'object') {
    if (statement.i[0] == '$$poleOwner' && statement.o == '==') {
      let tags = Path.get(properties, 'pole_tag');
      for (let key in tags) {
        if (tags[key].owner && tags[key].company == statement.i[1]) {
          return true;
        }
      }
      return false;
    }
    // Parse all inputs.
    let parsedInstructions = statement.i.map((instruction) => {
      if (typeof instruction === 'string' && instruction[0] == '$') {
        if (instruction == '$null') return null;
        else if (instruction == '$true') return true;
        else if (instruction == '$false') return false;
        else if (instruction == '$undefined') return undefined;
        else {
          return Path.get(properties, instruction.slice(1));
        }
      } else return instruction;
    });
    // Evaluate according to operand.
    if (statement.o == '==') return parsedInstructions[0] == parsedInstructions[1];
    else if (statement.o == '!=') return parsedInstructions[0] != parsedInstructions[1];
    else if (statement.o == '&&') return parsedInstructions.every((x) => CalcStatement(x, properties));
    else if (statement.o == '||') return parsedInstructions.some((x) => CalcStatement(x, properties));
  } else return statement;
}
