import proj4 from 'proj4';

function PplTagCleaner(tag) {
  var tag = tag.toUpperCase().replace(/[\W_A-MO-RT-Z]/g, '');
  var valid = tag.search(/^\d{5}(N|S)\d{5}$/) == 0;
  return {
    valid: valid,
    tag: tag
  };
}

export function PplTagLocation(tag) {
  let cleanTag = PplTagCleaner(tag);
  if (cleanTag.valid) {
    let srid;
    if (cleanTag.tag.includes('N')) {
      srid = 'EPSG:32028';
      proj4.defs(
        srid,
        '+proj=lcc +lat_0=40.1666666666667 +lon_0=-77.75 +lat_1=40.8833333333333 +lat_2=41.95 +x_0=609601.219202438 +y_0=0 +ellps=clrk66 +towgs84=-8,160,176,0,0,0,0 +units=us-ft +no_defs'
      );
    } else {
      srid = 'EPSG:32029';
      //QGIS
      //proj4.defs(srid, "+proj=lcc +lat_0=39.3333333333333 +lon_0=-77.75 +lat_1=40.9666666666667 +lat_2=39.9333333333333 +x_0=609601.219202438 +y_0=0 +ellps=clrk66 +towgs84=-8,160,176,0,0,0,0 +units=us-ft +no_defs");

      // https://spatialreference.org/
      // proj4.defs(srid, "+proj=lcc +lat_1=39.93333333333333 +lat_2=40.8 +lat_0=39.33333333333334 +lon_0=-77.75 +x_0=609601.2192024384 +y_0=0 +ellps=clrk66 +datum=NAD27 +to_meter=0.3048006096012192 +no_defs");

      //KPro /photoheight/coordinate_systems (NAD27 Deprecated)
      // proj4.defs(srid, "+proj=lcc +lat_1=39.93333333333333 +lat_2=40.8 +lat_0=39.33333333333334 +lon_0=-77.75 +x_0=609601.2192024384 +y_0=0 +datum=NAD27 +units=us-ft +no_defs");

      //KPro /photoheight/coordinate_systems (NAD27)
      // proj4.defs(srid, "+proj=lcc +lat_1=40.96666666666667 +lat_2=39.93333333333333 +lat_0=39.33333333333334 +lon_0=-77.75 +x_0=609601.2192024384 +y_0=0 +datum=NAD27 +units=us-ft +no_defs");

      //postgres
      // proj4.defs(srid, "+proj=lcc +lat_1=39.93333333333333 +lat_2=40.8 +lat_0=39.33333333333334 +lon_0=-77.75 +x_0=609601.2192024384 +y_0=0 +datum=NAD27 +units=us-ft +no_defs ");

      // Katapult custom
      proj4.defs(
        srid,
        '+proj=lcc +lat_1=39.93333333333333 +lat_2=40.8 +lat_0=39.33333333333334 +lon_0=-77.75 +x_0=609601.2192024384 +y_0=0 +ellps=clrk66 +towgs84=-10,158,187,0,0,0,0 +units=us-ft +no_defs'
      );
    }
    let coords = {
      x: Number('2' + tag.substring(0, 5) + '0'),
      y: Number(tag.substring(6) + '0')
    };
    let transformed = proj4(proj4(srid), proj4('EPSG:4326'), coords);
    return {
      latitude: transformed.y,
      longitude: transformed.x
    };
  }
  return null;
}
