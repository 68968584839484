import { GeofireTools } from '../GeofireTools.js';
import { KatapultGeometry } from 'katapult-toolbox';
import { Path } from '../Path.js';
import { DataLayer } from './DataLayer.js';
import { GeneratePushID } from '../GeneratePushID.js';

export let Sections = {
  create: function (connKey, connData, lat, lng, otherAttributes, firebase, options) {
    options = options || {};
    options.jobStyles = options.jobStyles || {};
    // Initial section object
    let newSectionKey = options.key || GeneratePushID();
    let newSection = { latitude: lat, longitude: lng };
    DataLayer._addTimeStamp(newSection, 'section', 'created', options.method, firebase);
    // Set attributes
    this.setAttributes(connKey, newSectionKey, newSection, options.attributes, otherAttributes, {
      jobId: options.jobId,
      jobStyles: options.jobStyles
    });
    Path.set(connData, `sections.${newSectionKey}`, newSection);
    // Modify the update object if provided
    if (options.update) {
      let sectionPath = Path.join([options.jobId, 'connections', connKey, 'sections', newSectionKey], '/');
      options.update[sectionPath] = newSection;
      let geoPath = options.geoPath || Path.join([options.jobId, 'geohash'], '/') + '/';
      // Set the geofire data for the section in the updats we well
      GeofireTools.setGeohash('sections', newSection, connKey, options.jobStyles, options.update, {
        sectionId: newSectionKey,
        geoPath
      });
    }
    return {
      key: newSectionKey,
      data: newSection
    };
  },
  setAttributes: function (connKey, sectionKey, section, attributes, otherAttributes, options) {
    DataLayer.setItemAttributes('sections', connKey, sectionKey, section, attributes, otherAttributes, options);
  },
  setLocationOnConnection: function (sectionKey, sectionData, connKey, connData, node1Data, node2Data, options) {
    options = options || {};
    let sectionNewLoc = KatapultGeometry.SnapToLine(
      sectionData.latitude,
      sectionData.longitude,
      node1Data.latitude,
      node1Data.longitude,
      node2Data.latitude,
      node2Data.longitude
    );
    sectionData.latitude = sectionNewLoc.lat;
    sectionData.longitude = sectionNewLoc.long;
    if (options.update) {
      let sectionPath = Path.join([options.jobId, 'connections', connKey, 'sections', sectionKey], '/');
      let geoPath = options.geoPath || Path.join([options.jobId, 'geohash'], '/') + '/';
      options.update[`${sectionPath}/latitude`] = sectionNewLoc.lat;
      options.update[`${sectionPath}/longitude`] = sectionNewLoc.long;
      GeofireTools.updateLocation(`${geoPath}${connKey}:${sectionKey}`, [sectionNewLoc.lat, sectionNewLoc.long], 10, options.update);
    }
  }
};
