import { DateTime } from 'luxon';
import { MLD, useAdminFieldValue as useAdminFieldValueForMLD } from './MasterLocationDirectory.js';

/**
 * @param {Object} adminFieldValue - The `FieldValue` object exported from `firebase-admin/firestore`
 */
export function useAdminFieldValue(adminFieldValue) {
  useAdminFieldValueForMLD(adminFieldValue);
}

/**
 * Returns the label to use for a portal location directory, including the key for the utility company
 * @param {String} utilityCompany - the id of the utility company for the portal
 * @returns {String} the label to use for a portal location directory
 */
function getPortalDirectoryLabel(utilityCompany) {
  return `portal_location_directory:${utilityCompany}`;
}

/**
 * Returns the label to use for a portal collaboration directory, including the key for the utility company
 * @param {String} utilityCompany - the id of the utility company for the portal
 * @returns {String} the label to use for a portal collaboration directory
 */
function getPortalCollaborationDirectoryLabel(utilityCompany) {
  return `portal_collaboration_directory:${utilityCompany}`;
}

/**
 * Checks if the company has a portal location directory and checks the job into it. If no directory exists, one is created.
 * @param {String} companyId - the id of the company to fetch directories for
 * @param {String} jobId - the id of the job to check in
 * @param {Object} nodes - the object of nodes to check in for the job
 * @param {String} utilityCompany - the id of the utility company for the portal
 * @param {Object} [firebaseReference] - a reference to Firebase, either FirebaseWorker in the client side or admin on the server side
 */
export async function checkJobIntoPortalDirectory(companyId, jobId, nodes, utilityCompany, firebaseReference) {
  if (!utilityCompany) throw 'No utility company provided';
  const firebase = firebaseReference || globalThis.FirebaseWorker;
  const db = firebase.database();

  // Get the portal directory
  let directoryId = await getPortalDirectoryId(companyId, utilityCompany, firebaseReference);

  // If no portal directory exists, create one
  if (!directoryId) {
    // Get the name to use for the portal directory
    const portalConfigRef = db.ref(`photoheight/company_space/${utilityCompany}/portal_config`);
    const directoryName =
      (await portalConfigRef.child(`portal_location_directory_name`).once('value')).val() || 'Portal Location Directory';
    // Create labels as additional properties
    const createDirectoryOptions = { labels: [getPortalDirectoryLabel(utilityCompany)] };
    directoryId = (await MLD.createDirectory(companyId, directoryName, createDirectoryOptions, firebaseReference)).id;
  }
  if (!directoryId) throw Error('No portal directory could be found or created.');

  // Check the job in
  await MLD.checkInJob(jobId, nodes, companyId, directoryId, { firebaseReference });
  // Set the last check in time to one minute from now so that it will be ahead the job last updated timestamp
  const oneMinuteFromNow = DateTime.now().plus({ minutes: 1 }).toMillis();
  await db.ref(`photoheight/jobs/${jobId}/metadata/master_location_directory/${directoryId}/last_check_in`).set(oneMinuteFromNow);
}

/**
 * Checks the job in to the portal collaboration directory managed by the utility company. If no directory exists, one is created. Note:
 * this function is not intended to be used on the client side, as it requires access to the utility company's directories collection.
 * @param {String} jobId - the id of the job to check in
 * @param {Object} nodes - the object of nodes to check in for the job
 * @param {String} utilityCompany - the id of the utility company for the portal
 * @param {Object} [firebaseReference] - a reference to Firebase, either FirebaseWorker in the client side or admin on the server side
 * @returns {Promise<void>} a promise that resolves when the job is checked in
 */
export async function checkJobIntoPortalCollaborationDirectory(jobId, nodes, utilityCompany, firebaseReference) {
  if (!utilityCompany) throw 'No utility company provided';
  /** @type {import('firebase/compat/app').default} */
  const firebase = firebaseReference || globalThis.FirebaseWorker;
  const db = firebase.database();

  // Get the portal collaboration directory
  let directoryId = await getPortalCollaborationDirectoryId(utilityCompany, firebaseReference);

  // If no portal collaboration directory exists, create one
  if (!directoryId) {
    // Get the name to use for the portal collaboration directory
    const portalConfigRef = db.ref(`photoheight/company_space/${utilityCompany}/portal_config`);
    const directoryName =
      (await portalConfigRef.child(`portal_collaboration_directory_name`).once('value')).val() || 'Portal Collaboration Directory';
    // Create labels as additional properties
    const createDirectoryOptions = { labels: [getPortalCollaborationDirectoryLabel(utilityCompany)] };
    directoryId = (await MLD.createDirectory(utilityCompany, directoryName, createDirectoryOptions, firebaseReference)).id;
  }
  if (!directoryId) throw Error('No portal collaboration directory could be found or created.');

  // Check the job into the directory
  await MLD.checkInJob(jobId, nodes, utilityCompany, directoryId, { firebaseReference });
  // Set the last check in time to one minute from now so that it will be ahead the job last updated timestamp
  const oneMinuteFromNow = DateTime.now().plus({ minutes: 1 }).toMillis();
  await db.ref(`photoheight/jobs/${jobId}/metadata/master_location_directory/${directoryId}/last_check_in`).set(oneMinuteFromNow);
}

/**
 * Removes a job from the portal collaboration directory managed by the utility company. Note: this function is not intended to be used on
 * the client side, as it requires access to the utility company's directories collection.
 * @param {String} jobId - the id of the job to check out
 * @param {String} utilityCompany - the id of the utility company for the portal
 * @param {Object} [firebaseReference] - a reference to Firebase, either FirebaseWorker in the client side or admin on the server side
 * @returns {Promise<void>} a promise that resolves when the job is checked out
 */
export async function removeJobFromPortalCollaborationDirectory(jobId, utilityCompany, firebaseReference) {
  if (!utilityCompany) throw 'No utility company provided';
  /** @type {import('firebase/compat/app').default} */
  const firebase = firebaseReference || globalThis.FirebaseWorker;
  const db = firebase.database();

  // Get the directory ID for the collaboration directory
  const directoryId = await getPortalCollaborationDirectoryId(utilityCompany, firebaseReference);
  // If no directory ID is found, there is nothing to do
  if (!directoryId) return;

  // Remove the job from the directory
  await MLD.removeJobFromDirectory(jobId, utilityCompany, directoryId, firebaseReference);
  // Unset the last check in time
  await db.ref(`photoheight/jobs/${jobId}/metadata/master_location_directory/${directoryId}/last_check_in`).set(null);
}

/**
 * Finds the directory that is labeled as the portal location directory for the company
 * @param {String} companyId - the id of the company to fetch directories for
 * @param {String} utilityCompany - the id of the utility company for the portal
 * @param {Object} [firebaseReference] - a reference to Firebase, either FirebaseWorker in the client side or admin on the server side
 * @returns {Promise<String>} the ID of the directory labeled as the portal directory (if there is one)
 */
export async function getPortalDirectoryId(companyId, utilityCompany, firebaseReference) {
  if (!utilityCompany) throw 'No utility company provided';
  // Get the portal label
  const portalDirectoryLabel = getPortalDirectoryLabel(utilityCompany);

  // Check if any of the directories are labeled as the portal directory
  const directories = await MLD.getDirectoryList(companyId, { firebaseReference });
  return Object.values(directories || {}).find((x) => x.labels?.includes(portalDirectoryLabel))?._id;
}

/**
 * Gets the id of the portal collaboration directory for the utility company. Note: this function is not intended to be used on the client
 * side, as it requires access to the utility company's directories collection.
 * @param {String} utilityCompany - The utility company of the portal
 * @param {Object} [firebaseReference] - A reference to Firebase, either FirebaseWorker in the client side or admin on the server side
 * @returns {Promise<String|undefined>} The id of the portal collaboration directory (or null if it doesn't exist)
 */
export async function getPortalCollaborationDirectoryId(utilityCompany, firebaseReference) {
  if (!utilityCompany) throw 'No utility company provided';
  /** @type {import('firebase/compat/app').default} */
  const firebase = firebaseReference || globalThis.FirebaseWorker;

  // Get the portal collaboration directory from the utility company's directories list
  const directoryLabel = getPortalCollaborationDirectoryLabel(utilityCompany);
  const utilityDirectoriesCollection = firebase.firestore().collection(`companies/${utilityCompany}/directories`);
  const snapshot = await utilityDirectoriesCollection.where('labels', 'array-contains', directoryLabel).get();

  return snapshot.docs[0]?.id;
}
