import { GeofireTools } from '../GeofireTools.js';
import { Nodes } from './Nodes.js';
import { Connections } from './Connections.js';
import { Sections } from './Sections.js';
import { Path } from '../Path.js';
import { AddAttribute } from '../AddAttribute.js';

/*
  This file is the entry point for the data layer. All interactions with data in Firebase
  should eventually go through the functions in this layer in order to consolidate code
*/

/* global firebase */

export let DataLayer = {
  Nodes,
  Connections,
  Sections,
  _addTimeStamp: function (item, itemType, timestampType, method, firebase) {
    let timestamp = {
      uid: firebase?.auth().currentUser?.uid || 'unknown',
      timestamp: Date.now(),
      method: method || 'unknown'
    };
    if (timestampType == 'created') item._created = timestamp;
    if (timestampType == 'modified') item._modified = timestamp;
  },
  setItemAttributes: function (itemType, itemKey, sectionKey, item, attributes, otherAttributes, options) {
    options = options || {};
    options.jobStyles = options.jobStyles || {};
    let itemPath = itemType;
    let attributePath = itemType == 'sections' ? 'multi_attributes' : 'attributes';
    // Set attributes
    if (Array.isArray(attributes) && attributes.length) {
      if (!item[attributePath]) item[attributePath] = {};
      // Loop through the attributes and add them
      for (let i = 0; i < attributes.length; i++) {
        AddAttribute(attributes[i].attribute, item[attributePath], otherAttributes, {
          attributeKey: attributes[i].key,
          attributeValue: attributes[i].value
        });
      }
      if (options.update) {
        options.update[Path.join([options.jobId, itemPath, itemKey, attributePath], '/')] = item[attributePath];
        // Calc the geofire path.
        let geoPath = options.geoPath ?? Path.join([options.jobId, 'geohash'], '/') + '/';
        // Update the style on the node.
        GeofireTools.updateStyle(itemType, itemKey, item, options.update, options.jobStyles, sectionKey, { geoPath });
      }
    }
  }
};
