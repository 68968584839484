import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { Debouncer } from '@polymer/polymer/lib/utils/debounce.js';
import '../katapult-firebase-worker/katapult-firebase-worker.js';
import { KatapultGeometry } from 'katapult-toolbox';
import '@polymer/paper-toast/paper-toast.js';
import '../katapult-maps-desktop/load-render-map.js';
import '../api-map-layer/api-map-layer.js';
import '../google-map/google-map-elements.js';
import { GeofireTools } from '../../modules/GeofireTools.js';
import '../style-modules/paper-tooltip-style.js';
import { GetJobData } from '../../modules/GetJobData.js';
import { DataLayer } from '../../modules/DataLayer/DataLayer.js';
import '../style-modules/paper-dialog-style.js';
import '../style-modules/paper-table-style.js';
import '../style-modules/katapult-scrollbars';
import '../style-modules/flex';
import '../katapult-photo/katapult-photo-chooser.js';
import { SquashNulls } from '../../modules/SquashNulls.js';
import { GeoStyleToIcon, GetIconSize } from '../../modules/GeoStyleToIcon.js';
import { GetConnectionLookup } from '../../modules/GetConnectionLookup.js';
import { InsertJobReferenceConnection } from '../../modules/InsertJobReferenceConnection.js';
import { FirebaseEncode } from '../../modules/FirebaseEncode.js';
import { UnassociatePhotoFromItem } from '../../modules/UnassociatePhotoFromItem.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { timeOut } from '@polymer/polymer/lib/utils/async.js';
import { PickAnAttribute } from '../../modules/PickAnAttribute.js';
import { IncrementFolderCounter } from '../../modules/IncrementFolderCounter.js';
import { Round } from '../../modules/Round.js';
import { Path } from '../../modules/Path.js';
import { UpdateNodeLocation } from '../../modules/UpdateNodeLocation.js';
import { StyleRuleToIcon } from '../../modules/StyleRuleToIcon.js';
import { MapItemEditor } from './MapItemEditor.js';
import { addFlagListener, removeFlagListener } from '../../modules/FeatureFlags.js';
import { Parallel } from '../../modules/Parallel.js';

/**
 * `katapult-map`
 * Map element that loads, renders and controls interaction with the map and firebase data
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
/* global Polymer, firebase, google, k, CustomEvent, DOMParser, URL, RichMarker */
class KatapultMap extends PolymerElement {
  static get template() {
    return html`
      <style include="katapult-scrollbars paper-tooltip-style paper-dialog-style paper-table-style flex">
        [hidden] {
          display: none !important;
        }
        :host {
          position: relative;
        }
        #finishPolylineButton {
          height: 40px;
          color: black;
          align-self: center;
          background-color: var(--secondary-color);
        }
        .rubberBand {
          position: absolute;
          width: 0;
          outline-width: 2px;
          outline-style: solid;
          outline-color: rgba(255, 255, 0, 0.6);
          transform-origin: 0 0;
        }
        .rubberBand .extension {
          position: absolute;
          top: 0;
          left: 0;
          height: 2000px;
          outline-width: 1px;
          outline-style: dashed;
          outline-color: inherit;
          transform: rotate(180deg);
          transform-origin: 0 0;
        }
        #rubberbandCancelButton {
          height: 40px;
          color: black;
          align-self: center;
          background-color: #eeff41;
        }
        .angle-helpers {
          display: flex;
          color: var(--primary-text-color-faded);
          padding: 0 8px;
          gap: 8px;
        }
        .angle-helpers katapult-button[last-clicked=''] {
          /* This is, admittedly, a hacky way to set katapult-button color */
          background-color: #ffb01f;
        }
        .snappingInputContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 12px;
        }
        .snappingInputContainer iron-icon {
          color: var(--primary-text-color-faded);
        }
        .snappingInputContainer > iron-icon {
          margin: 0px 8px;
          flex-shrink: 0;
        }
        .snappingInputContainer > iron-icon:hover {
          cursor: pointer;
        }
        .snappingInputContainer > iron-icon[icon='refresh'] {
          transform: rotate(-90deg) rotateY(45deg);
        }
        .snappingInputContainer > paper-input {
          flex-grow: 1;
          position: relative;
          z-index: 2;
          color: var(--primary-text-color);
          width: 50px;
        }
        .snappingInputContainer > iron-collapse > iron-icon:hover {
          cursor: pointer;
        }
        katapult-photo-chooser {
          z-index: 100;
        }
        @media all and (min-width: 601px) {
          #confirmDialog {
            width: 600px;
          }
        }
        #actionDialogContainer {
          /* background: red; */
          display: flex;
          justify-content: center;
          position: absolute;
          left: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          z-index: 1;
          pointer-events: none;
          align-items: flex-end;
        }
        #actionDialogContainer > * {
          pointer-events: auto;
        }
        #actionDialogButtons {
          margin-top: 8px;
        }
        #actionDialog {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 16px;
          margin: 0 16px;

          /* height: 120px; */
          /* width: 480px; */
          background-color: white;
          border-radius: 16px 16px 0 0;

          min-width: 260px;

          transition: all 0.3s;
          transform: translateY(120px);

          @apply --shadow-elevation-4dp;
        }
        #actionDialog > div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
        }
        #actionDialog[opened=''] {
          transform: translateY(0px) !important;
        }
        #actionDialogTitle {
          position: relative;
          text-transform: uppercase;
          color: var(--primary-text-color-faded);
          padding: 0 48px;
        }
        #actionDialogCancelButton {
          position: absolute;
          top: -8px;
          right: -8px;
        }
        #actionDialogTitle > iron-icon,
        #actionDialogTitle > material-icon {
          padding: 0px 8px;
        }
        #actionDialogContent {
          flex-grow: 1;
          padding: 12px;
        }
        katapult-toolbar {
          z-index: 3;
        }
        katapult-button.cancel {
          background-color: var(--paper-red-500);
          color: white;
        }
        #overlaySlider {
          --paper-slider-input: {
            width: 75px;
          };
        }
      </style>
      <iron-meta id="meta" type="iconset"></iron-meta>
      <katapult-firebase-worker
        id="streetViewData"
        path="photoheight/company_space/[[userGroup]]/user_data/[[uid]]/streetview_data"
        data="{{streetViewData}}"
        disabled="[[!signedIn]]"
        clear-data-on-disabled=""
      ></katapult-firebase-worker>
      <katapult-firebase-worker
        id="threeDViewData"
        path="photoheight/company_space/[[userGroup]]/user_data/[[uid]]/3d_view_data"
        data="{{threeDViewData}}"
        disabled="[[!signedIn]]"
        clear-data-on-disabled=""
      ></katapult-firebase-worker>

      <iron-a11y-keys keys="ctrl+z meta+z" on-keys-pressed="undoLastAction"></iron-a11y-keys>

      <!--Confirm Dialog-->
      <paper-dialog
        id="confirmDialog"
        layered="false"
        entry-animation="scale-up-animation"
        exit-animation="fade-out-animation"
        on-iron-overlay-canceled="cancel"
        opened="{{confirmDialogOpened}}"
      >
        <div title="" secondary-color="">[[confirmDialogHeading]]</div>
        <div content="">
          <!--style="display:table; min-height:138px; width:calc(100% - 48px);"-->
          <span id="confirmBody" style="display:table-cell; vertical-align:middle;">
            <span>{{confirmDialogBody}}</span>
          </span>
        </div>
        <div buttons="">
          <katapult-button on-click="cancel" style$="{{confirmDialogDismissiveStyle}}" dialog-dismiss
            >{{confirmDialogDismissiveText}}</katapult-button
          >
          <katapult-button
            on-click="confirmDialogCallback"
            dialog-confirm=""
            style$="{{confirmDialogAffirmativeStyle}}"
            disabled="{{isConfirmDisabled}}"
            >{{confirmDialogAffirmativeText}}</katapult-button
          >
        </div>
      </paper-dialog>

      <template is="dom-if" if="[[useAcceleratedMap]]">
        <div
          style="position: absolute; display: flex; align-items: center; margin: var(--sl-spacing-medium); padding: var(--sl-spacing-x-small); gap: var(--sl-spacing-x-small); border-radius: var(--sl-border-radius-large); box-shadow: var(--sl-shadow-medium); z-index: 1; background: white; opacity: 0.9; font-size: var(--sl-font-size-small); pointer-events: none; user-select: none; outline: 2px solid var(--secondary-color);"
        >
          <katapult-icon icon="speed" size="20" color="var(--secondary-color)"></katapult-icon>
          <span>Accelerated Map</span>
        </div>
      </template>

      <div id="actionDialogContainer">
        <!--Dialog Of Multiselected items-->
        <template is="dom-if" if="{{showMultiSelectCountDialog}}">
          <div flex column justify-end border-box style="padding: 24px 24px 24px 72px;">
            <paper-table overflow-hidden white rounded>
              <paper-table-scroll>
                <!--Check if nodes should be shown in the count dialog-->
                <template is="dom-if" if="{{multiSelectIncludedTypes.nodes}}">
                  <paper-header-content slot="before">
                    <paper-cell slot="header">Nodes - [[multiNodeCount]]</paper-cell>
                    <template is="dom-repeat" items="[[multiNodeItems]]">
                      <paper-row slot="content" divider expand>
                        <paper-cell style="height: 36px; padding-top: 6px; padding-bottom: 6px;" icon>
                          <iron-icon icon="[[item.icon]]" src="[[item.iconSrc]]" style="[[item.style]]"></iron-icon>
                        </paper-cell>
                        <paper-cell style="padding-top: 6px; padding-bottom: 6px;">[[item.title]] - [[item.count]]</paper-cell>
                      </paper-row>
                    </template>
                  </paper-header-content>
                </template>
                <!--Check if sections should be shown in the count dialog-->
                <template is="dom-if" if="{{multiSelectIncludedTypes.sections}}">
                  <paper-header-content slot="before">
                    <paper-cell slot="header">Sections - [[multiSectionCount]]</paper-cell>
                    <template is="dom-repeat" items="[[multiSectionItems]]">
                      <paper-row slot="content" divider expand>
                        <paper-cell style="height: 36px; padding-top: 6px; padding-bottom: 6px;" icon>
                          <iron-icon icon="[[item.icon]]" src="[[item.iconSrc]]" style="[[item.style]]"></iron-icon>
                        </paper-cell>
                        <paper-cell style="padding-top: 6px; padding-bottom: 6px;">[[item.title]] - [[item.count]]</paper-cell>
                      </paper-row>
                    </template>
                  </paper-header-content>
                </template>
                <!--Check if connections should be shown in the count dialog-->
                <template is="dom-if" if="{{multiSelectIncludedTypes.connections}}">
                  <paper-header-content slot="before">
                    <paper-cell slot="header">Connections - [[multiConnCount]] ([[multiConnLength]])</paper-cell>
                    <template is="dom-repeat" items="[[multiConnItems]]">
                      <paper-row slot="content" divider expand>
                        <paper-cell style="height: 36px; padding-top: 6px; padding-bottom: 6px;" icon>
                          <iron-icon icon="[[item.icon]]" style="[[item.style]]"></iron-icon>
                        </paper-cell>
                        <paper-cell style="padding-top: 6px; padding-bottom: 6px;"
                          >[[item.title]] - [[item.count]] ([[item.length]])</paper-cell
                        >
                      </paper-row>
                    </template>
                  </paper-header-content>
                </template>
              </paper-table-scroll>
            </paper-table>
          </div>
        </template>
        <div spacer></div>
        <div id="actionDialog" opened$="[[showActionDialog]]" style$="transform: translateY([[actionDialogTranslate]]px);">
          <div id="actionDialogTitle">
            <template is="dom-if" if="[[actionDialogOptions.icon]]" restamp>
              <iron-icon style$="color: [[actionDialogModel.color]];" icon="[[actionDialogModel.icon]]"></iron-icon>
            </template>
            <template is="dom-if" if="[[actionDialogOptions.materialIcon]]" restamp>
              <material-icon style$="color: [[actionDialogModel.color]];" icon="[[actionDialogModel.materialIcon]]"></material-icon>
            </template>
            <template is="dom-if" if="[[equal(actionDialogOptions.title, '$default')]]">
              <span>[[actionDialogModel.label]]</span>
            </template>
            <template is="dom-if" if="[[!equal(actionDialogOptions.title, '$default')]]">
              <span>[[actionDialogOptions.title]]</span>
            </template>
            <katapult-button id="actionDialogCancelButton" icon="clear" iconOnly noBorder on-click="cancelActionDialog"></katapult-button>
          </div>
          <template is="dom-if" if="[[actionDialogOptions.text]]">
            <p>[[actionDialogOptions.text]]</p>
          </template>

          <template is="dom-if" if="[[actionDialogOptions.photoChooser]]">
            <katapult-photo-chooser
              action-dialog="true"
              id="photoChooser"
              job-id="[[editingItemJob]]"
              name="node"
              uid="[[uid]]"
              user-group="[[userGroup]]"
              tier="[[tier]]"
              use-metric-units="[[useMetricUnits]]"
              item="[[actionDialogOptions.photoChooserItem]]"
              on-chooser-select="chooserSelect"
              on-chooser-photo-tap="chooserPhotoTap"
              model-config="[[modelConfig]]"
            ></katapult-photo-chooser>
          </template>

          <template is="dom-if" if="[[actionDialogOptions.body.annotationLeaderOptions]]">
            <paper-checkbox checked="{{actionDialogData.arrowEndpoint}}">Arrow Endpoint</paper-checkbox>
          </template>
          <template is="dom-if" if="[[actionDialogOptions.body.mapOverlayOptions]]">
            <paper-input value="{{actionDialogData.overlayName}}" label="Overlay Name"></paper-input>
            <paper-slider
              id="overlaySlider"
              value="[[actionDialogData.sliderValue]]"
              immediate-value="{{actionDialogData.sliderValue}}"
              min="0"
              max="100"
              editable
            ></paper-slider>
          </template>
          <template is="dom-if" if="[[actionDialogOptions.body.setOrder]]">
            <div style="display: flex;" hidden="{{actionDialogData.scidByPoleAppOrder}}">
              <paper-input
                style="margin-right: 12px; width: 100px;"
                label="Starting SCID"
                value="{{actionDialogData.startingSCID}}"
              ></paper-input
              ><paper-checkbox checked="{{actionDialogData.scidGuysDotOne}}">Use decimals for Guy Poles</paper-checkbox>
            </div>
            <template is="dom-if" if="[[equal(config.appName, 'ppl-kws')]]">
              <div style="display: flex; padding-top: 16px;">
                <paper-checkbox checked="{{actionDialogData.scidByPoleAppOrder}}">Scid Poles By Pole App Order</paper-checkbox>
              </div>
            </template>
          </template>
          <template is="dom-if" if="[[showRubberBandPoly]]">
            <div id="actionDialogContent">
              <template is="dom-if" if="[[showSpanDistanceInput]]">
                <div class="snappingInputContainer">
                  <paper-tooltip position="top">Span Distance</paper-tooltip>
                  <iron-icon icon="image:straighten"></iron-icon>
                  <paper-input
                    id="inputLength"
                    pattern="[0-9]+([.][0-9]+)?"
                    auto-validate
                    value="{{snappingLength}}"
                    on-focus="lockGeometry"
                    on-input="setRubberBandPosition"
                    no-label-float
                  >
                    <div slot="suffix">
                      <template is="dom-if" if="{{useMetricUnits}}"><span>m</span></template>
                      <template is="dom-if" if="{{!useMetricUnits}}"><span>&#39;</span></template>
                    </div>
                  </paper-input>
                  <iron-collapse opened="[[lockedGeometryLength]]" horizontal>
                    <iron-icon icon="lock-outline" on-click="unlockGeometry" name="length"></iron-icon>
                  </iron-collapse>
                </div>
              </template>
              <div class="snappingInputContainer">
                <paper-tooltip position="top">Span Angle</paper-tooltip>
                <iron-icon icon="refresh"></iron-icon>
                <paper-input
                  id="inputAngle"
                  pattern="[0-9]+([\\.][0-9]+)?"
                  auto-validate=""
                  value="{{snappingAngle}}"
                  on-focus="lockGeometry"
                  on-input="setRubberBandPosition"
                  no-label-float=""
                >
                  <div slot="suffix">
                    <span>°</span>
                  </div>
                </paper-input>
                <iron-collapse opened="[[lockedGeometryAngle]]" horizontal="">
                  <iron-icon icon="lock-outline" on-click="unlockGeometry" name="angle"></iron-icon>
                </iron-collapse>
              </div>
              <template is="dom-if" if="[[showAngleHelpers]]">
                <div class="angle-helpers">
                  <katapult-button
                    iconOnly
                    icon="call_merge"
                    last-clicked$="[[equal(_lastClickedAngleButton, 'bisect')]]"
                    title="Set angle to bisect spans"
                    data-action="bisect"
                    on-click="toggleBisectorInline"
                  ></katapult-button>
                  <katapult-button
                    iconOnly
                    icon="east"
                    last-clicked$="[[equal(_lastClickedAngleButton, 'inline')]]"
                    title="Set angle to be inline with spans"
                    data-action="inline"
                    on-click="toggleBisectorInline"
                  ></katapult-button>
                </div>
              </template>
            </div>
          </template>
          <!-- Extra detail for photo association -->
          <template is="dom-if" if="[[associationCheckbox]]">
            <paper-checkbox checked="{{associationStarCheck}}">Auto-star height and midspan photos</paper-checkbox>
          </template>
          <!-- Extra detail for starring photos -->
          <template is="dom-if" if="[[starFirstCheckbox]]">
            <paper-checkbox checked="{{starFirstCheck}}">Star the first photo if no height photo is found</paper-checkbox>
          </template>
          <!--Multi Delete Box-->
          <template is="dom-if" if="[[actionDialogOptions.selectByType]]">
            <div class="dialogSubHeader">Items to delete:</div>
            <paper-radio-group selected="{{multiDeleteItem}}">
              <paper-radio-button name="nodes" aria-checked="true">Nodes</paper-radio-button>
              <paper-radio-button name="connections">Connections</paper-radio-button>
              <paper-radio-button name="sections">Sections</paper-radio-button>
            </paper-radio-group>
          </template>
          <div id="actionDialogButtons">
            <template is="dom-repeat" items="[[actionDialogOptions.buttons]]" as="button">
              <katapult-button
                name$="[[button.key]]"
                on-click="actionDialogButtonHandler"
                temp="[[addActionDialogButtonAttributes(button)]]"
                style="margin-left: 8px;"
                >[[button.title]]</katapult-button
              >
            </template>
          </div>
        </div>
        <div style="flex-grow: 1;"></div>
      </div>
      <slot name="content"></slot>
      <load-render-map
        id="loadRenderMap"
        map="[[map]]"
        layers="[[jobIds]]"
        main-job-id="[[jobId]]"
        label-font-size="{{labelFontSize}}"
        map-is-ready="[[googleIsReady]]"
        polyline-points="{{enterNextPolylinePoint.points}}"
        cluster-max-zoom="{{clusterMaxZoom}}"
        show-angles-of-node="[[selectedNode]]"
        show-span-distances="[[showSpanDistances]]"
        labeled-attributes="[[nodeLabels]]"
        clickable-nodes="{{clickableNodes(activeCommand)}}"
        clickable-connections="{{clickableConnections(activeCommand)}}"
        draggable="{{computeCanDrag(readOnly, modelConfig.*, canDragOverride, notDraggable, activeCommand)}}"
        cable-tracing="{{cableTracing}}"
        connection-tracing="{{connectionTracing}}"
        hidden-markers="{{hiddenMarkers}}"
        hidden-nodes="[[hiddenNodes]]"
        hidden-connections="[[hiddenConnections]]"
        use-metric-units="[[useMetricUnits]]"
        on-node-click="selectNode"
        on-node-mouseout="nodeMouseout"
        on-node-mouseover="nodeMouseover"
        on-node-dbl-click="doubleClickNode"
        on-conn-click="selectConnection"
        on-section-click="selectSection"
        on-node-drag="dragNode"
        on-node-drag-start="dragNodeStart"
        on-node-drag-end="dragNodeEnd"
        on-section-drag="dragSection"
        on-section-drag-end="dragSectionEnd"
        read-only="[[readOnly]]"
        model-config="{{modelConfig}}"
        projection="{{projection}}"
        other-attributes="[[otherAttributes]]"
        use-accelerated-map="[[useAcceleratedMap]]"
        compute-style="[[loadRenderMapComputeStyle]]"
      >
      </load-render-map>
      <api-map-layer map="[[map]]" layers="[[jobIds]]" map-is-ready="[[googleIsReady]]"></api-map-layer>
      <!-- google map layer -->
      <!--measurable-->
      <google-map
        id="googlemap"
        api-key="[[apiKey]]"
        api-channel="[[userGroup]]"
        latitude="{{latitude}}"
        longitude="{{longitude}}"
        street-view-window="{{linkedStreetViewWindow}}"
        zoom="{{zoom}}"
        max-zoom="30"
        map-type="{{mapBase}}"
        map-type-ids="{{mapTypeIds}}"
        map="{{map}}"
        measurable="[[measurable]]"
        no-auto-tilt=""
        additional-map-options="{{additionalMapOptions}}"
        click-events=""
        on-google-map-click="clickMap"
        on-google-map-rightclick="rightClickMap"
        mouse-events=""
        on-google-map-mouseover="startMapMouse"
        on-google-map-mousemove="mapMouse"
        drag-events=""
        drag-n-drop-events
        on-file-drag-over="mapDragover"
        on-file-drop="mapDrop"
        on-google-map-drag="mapMouse"
        on-map-bounds-changed="mapMoved"
        on-google-map-idle="mapMoved"
        on-google-map-ready="googleMapReady"
        on-measure-map="measureOnMap"
        on-streetview-visible-changed="openStreetView"
        on-streetview-position-changed="setStreetViewPosition"
        on-streetview-pov-changed="setStreetViewPov"
      >
        <!-- gps location marker -->
        <google-map-marker
          id="gpsMarker"
          slot="markers"
          latitude="{{gpsLat}}"
          longitude="{{gpsLng}}"
          icon="{{getMarkerIcon('katapult-map:gps', 32, '#c3fafa')}}"
          cursor="grab"
          title="Your Location"
        >
        </google-map-marker>

        <!-- zoom to location marker -->
        <template is="dom-if" if="{{showZoomToLocationMarker}}" restamp="">
          <google-map-marker
            id="zoomToLocationMarker"
            slot="markers"
            latitude="{{zoomToLocationLat}}"
            longitude="{{zoomToLocationLng}}"
            title="Searched Location"
          >
            {{zoomToLocationContent}}
          </google-map-marker>
        </template>

        <!--hoverItem marker-->
        <template is="dom-if" restamp="" if="[[hoverItem]]">
          <google-map-marker slot="markers" latitude="[[hoverNodeLatitude]]" longitude="[[hoverNodeLongitude]]" icon="[[hoverIcon]]">
          </google-map-marker>
        </template>

        <!--selectedIcon marker-->
        <template is="dom-if" restamp="" if="[[showSelectedIcon(selectedNode, activeSection)]]">
          <google-map-marker
            slot="markers"
            z-index="-5"
            latitude="[[selectedIconLatitude]]"
            longitude="[[selectedIconLongitude]]"
            icon="[[selectedIcon]]"
          >
          </google-map-marker>
        </template>

        <!-- Rubber Band -->
        <template is="dom-if" restamp="" if="[[showRubberBandPoly]]">
          <div class="rubberBand" style$="[[rubberBandStyle]]">
            <div class="extension"></div>
          </div>
        </template>

        <!-- Rubber Band Dropper -->
        <template is="dom-if" restamp="" if="{{showRubberBandDropper(mouseLat, mouseLng, activeCommand, tier, linkMapPhotoActions)}}">
          <google-map-marker
            id="rubberbandDropper"
            slot="markers"
            latitude="[[mouseLat]]"
            longitude="[[mouseLng]]"
            icon="{{getMarkerIcon('katapult-map:rubberband',48,'rgba(0\\,0\\,0\\,0.5)',24,46)}}"
            cursor="none"
          >
          </google-map-marker>
        </template>

        <!-- street view avatar -->
        <template is="dom-if" if="{{streetViewData.showAvatar}}" restamp="">
          <google-map-rich-marker
            id="streetviewAvatar"
            latitude="{{streetViewData.position.lat}}"
            longitude="{{streetViewData.position.lng}}"
            rich-content="{{getAvatar('#F9BC2B', streetViewData.pov.heading)}}"
            z-index="100"
            cursor="grab"
            clickable="true"
            on-google-map-marker-click="streetViewAvatarClick"
            draggable="true"
            drag-events="true"
            on-google-map-marker-drag="streetViewAvatarDrag"
            on-google-map-marker-dragend="streetViewAvatarDragEnd"
          >
          </google-map-rich-marker>
        </template>

        <!-- 3d view avatar -->
        <template is="dom-if" if="{{showThreeDViewAvatar}}" restamp="">
          <google-map-rich-marker
            id="threeDViewAvatar"
            latitude="[[threeDViewData.position.lat]]"
            longitude="[[threeDViewData.position.lng]]"
            rich-content="[[getAvatar('#2196F3', threeDViewData.pov.heading)]]"
            z-index="100"
            cursor="grab"
            clickable="true"
            draggable="true"
            drag-events="true"
            on-google-map-marker-dragend="threeDViewAvatarDragEnd"
          >
          </google-map-rich-marker>
        </template>

        <!-- highlight selected cable trace overlay -->
        <template is="dom-if" restamp="" if="{{showTraceHighlight(polygonMapHighlights)}}">
          <template is="dom-repeat" items="[[polygonMapHighlights]]" as="path">
            <google-map-poly
              stroke-color="[[path.strokeColor]]"
              stroke-weight="[[path.strokeWeight]]"
              stroke-opacity="[[path.strokeOpacity]]"
              z-index="1000"
            >
              <template is="dom-repeat" items="[[path.points]]" as="point">
                <google-map-point latitude$="[[point.latitude]]" longitude$="[[point.longitude]]"> </google-map-point>
              </template>
            </google-map-poly>
          </template>
        </template>

        <slot name="markers" slot="markers"></slot>
      </google-map>

      <!-- device geolocation -->
      <geo-location
        id="geoLocation"
        latitude="{{gpsLat}}"
        longitude="{{gpsLng}}"
        timeout="1e7"
        high-accuracy=""
        watch-pos=""
      ></geo-location>
    `;
  }

  static get is() {
    return 'katapult-map';
  }
  static get properties() {
    return {
      actionDialogOptions: {
        type: Object,
        value: {},
        notify: true
      },
      actionDialogData: {
        type: Object,
        value: {},
        notify: true
      },
      editingNodeAndConn: {
        type: Boolean,
        value: false,
        notify: true
      },
      jobId: {
        type: String
      },
      jobIds: {
        type: Object
      },
      editingItemJob: {
        type: String,
        notify: true
      },
      jobStyles: Object,
      nodeLabels: Object,
      additionalMapOptions: {
        type: Object,
        value: {
          backgroundColor: '#002b38',
          fullscreenControl: false,
          mapTypeControl: false,
          // mapTypeControlOptions: { style: 2, position: 2 },
          rotateControlOptions: { position: 6 },
          scaleControl: true,
          streetViewControl: true,
          streetViewControlOptions: { position: 6 },
          zoomControl: false
        }
      },
      katapultMapStyle: {
        type: String,
        value: [
          { featureType: 'administrative.country', elementType: 'geometry.fill', stylers: [{ visibility: 'on' }] },
          { featureType: 'administrative.country', elementType: 'labels', stylers: [{ visibility: 'off' }] },
          { featureType: 'landscape.man_made', elementType: 'geometry.fill', stylers: [{ hue: '#ff0000' }] },
          { featureType: 'landscape.natural', elementType: 'geometry.fill', stylers: [{ color: 'var(--secondary-color)' }] },
          { featureType: 'landscape.natural', elementType: 'labels', stylers: [{ visibility: 'off' }] },
          { featureType: 'landscape.natural', elementType: 'labels.text', stylers: [{ visibility: 'off' }] },
          { featureType: 'landscape.natural.landcover', elementType: 'labels.text.fill', stylers: [{ hue: '#ff0000' }] },
          {
            featureType: 'landscape.natural.terrain',
            elementType: 'geometry.fill',
            stylers: [{ color: '#ff0000' }, { visibility: 'off' }]
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [
              { color: 'var(--primary-color)' },
              { gamma: '0.81' },
              { weight: '1.19' },
              { invert_lightness: true },
              { visibility: 'on' }
            ]
          },
          { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#2D333C' }] },
          { featureType: 'water', elementType: 'geometry.fill', stylers: [{ color: 'var(--primary-color)' }] }
        ]
      },
      bisectorBearings: {
        type: Array,
        value: () => {
          return [];
        }
      },
      bisectorIndex: {
        type: Number,
        value: 0
      },
      canDragOverride: {
        type: Boolean,
        value: false
      },
      editing: {
        type: String,
        value: null,
        notify: true
      },
      selectedNode: {
        type: String,
        value: null,
        notify: true
      },
      editingNode: {
        type: String,
        value: null,
        notify: true
      },
      gpsLat: {
        type: Number,
        notify: true
      },
      gpsLng: {
        type: Number,
        notify: true
      },
      activeConnection: {
        type: String,
        value: null,
        notify: true
      },
      activeSection: {
        type: String,
        value: null,
        notify: true
      },
      selectionLocationUpdated: {
        type: Boolean,
        value: false
      },
      activeCommand: {
        type: String,
        observer: 'activeCommandChanged',
        notify: true
      },
      activeCommandModel: Object,
      activeCommandData: Object,
      actionDialogModel: Object,
      clusterMaxZoom: {
        type: Number,
        value: 15,
        notify: true
      },
      mapTypeIds: {
        type: Object,
        notify: true,
        readOnly: true,
        value: () => ({
          hybrid: 'Hybrid',
          roadmap: 'Roadmap',
          terrain: 'Terrain',
          satellite: 'Satellite'
        })
      },
      multiSelectIncludedTypes: {
        type: Object,
        value: () => ({
          nodes: true,
          sections: false,
          connections: true
        }),
        notify: true
      },
      multiSelectClickedNodes: {
        type: Array,
        value: () => {
          return [];
        }
      },
      multiSelectClickedSections: {
        type: Array,
        value: () => {
          return [];
        }
      },
      multiSelectNodeStyles: {
        type: Object,
        value: {}
      },
      multiSelectConnectionStyles: {
        type: Object,
        value: {}
      },
      multiSelectSectionStyles: {
        type: Object,
        value: {}
      },
      mapBase: {
        type: String,
        notify: true,
        value: 'hybrid',
        observer: 'mapBaseChanged'
      },
      prevMapBase: {
        type: String,
        notify: true,
        value: 'hybrid'
      },
      snappingAngle: {
        type: Number,
        notify: true
      },
      recordNodeMoveAttribute: {
        type: String
      },
      noEditButtons: {
        type: Boolean,
        value: false
      },
      draggingNode: {
        type: String,
        value: null
      },
      hoverItem: {
        type: String,
        value: null
      },
      inlineBearings: {
        type: Array,
        value: () => {
          return [];
        }
      },
      inlineIndex: {
        type: Number,
        value: 0
      },
      zoom: {
        type: Number,
        value: 3,
        notify: true,
        observer: 'zoomChanged'
      },
      latitude: {
        type: Number,
        value: 25.3241665257384,
        notify: true
      },
      longitude: {
        type: Number,
        value: 12.65625,
        notify: true
      },
      map: {
        type: Object,
        notify: true
      },
      measurable: {
        type: Boolean,
        value: false
      },
      mouseLat: {
        type: Number,
        notify: true
      },
      mouseLng: {
        type: Number,
        notify: true
      },
      lockedGeometryAngle: {
        type: Boolean,
        value: false
      },
      lockedGeometryLength: {
        type: Boolean,
        value: false
      },
      projection: {
        type: Object,
        notify: true
      },
      readOnly: {
        type: Boolean,
        value: false
      },
      rubberBandAngle: {
        computed: 'calcRubberAngle(mouseLat,mouseLng,selectedNode)',
        observer: 'rubberBandAngleChanged'
      },
      rubberBandLength: {
        computed: 'calcRubberLength(mouseLat,mouseLng,selectedNode,useMetricUnits)',
        observer: 'rubberBandLengthChanged'
      },
      rubberBandStyle: {
        computed:
          'calcRubberBandStyle(mouseLat, mouseLng, selectedNodeLatitude, selectedNodeLongitude, useMetricUnits, latitude, longitude)'
      },
      hasWaitedAtMaxZoom: {
        type: Boolean,
        value: false
      },
      multiSelectPolygonUpdated: {
        type: Boolean,
        value: false
      },
      notDraggable: {
        type: Boolean,
        value: false
      },
      otherAttributes: {
        type: Object
      },
      pixelScale: {
        //todo
        type: Number,
        value: 262144 // based on Google Maps' default zoom level (i.e. 2^18)
      },
      selectedIcon: {
        type: Object,
        value: () => ({
          anchor: { x: 18, y: 18 },
          url: 'https://storage.googleapis.com/katapult-pro-shared-files/photos/halo-circle-yellow.svg',
          scaledSize: { width: 36, height: 36 },
          size: { width: 36, height: 36 }
        })
      },
      hoverIcon: {
        type: Object,
        value: () => ({
          anchor: { x: 18, y: 18 },
          url: 'https://storage.googleapis.com/katapult-pro-shared-files/photos/halo-circle-green.svg',
          scaledSize: { width: 36, height: 36 },
          size: { width: 36, height: 36 }
        })
      },
      defaultSelectionHoverIconSize: {
        type: Object,
        value: () => ({
          anchor: { x: 18, y: 18 },
          scaledSize: { width: 36, height: 36 },
          size: { width: 36, height: 36 }
        })
      },
      showMultiSelectCountDialog: {
        type: Boolean,
        value: false
      },
      showRubberBandPoly: {
        type: Boolean,
        value: false
      },
      undoLog: {
        type: Array,
        notify: true,
        value: function () {
          return [];
        }
      },
      cableTracing: {
        type: Boolean,
        value: false,
        notify: true
      },
      connectionTracing: {
        type: Boolean,
        value: false,
        notify: true
      },
      customMapTypes: {
        type: Array,
        value: () => {
          return [
            {
              name: 'Roadmap Printed',
              styles: [
                {
                  stylers: [
                    {
                      visibility: 'off'
                    }
                  ]
                },
                {
                  featureType: 'landscape',
                  stylers: [
                    {
                      color: '#ffffff'
                    },
                    {
                      visibility: 'on'
                    }
                  ]
                },
                {
                  featureType: 'road',
                  stylers: [
                    {
                      visibility: 'on'
                    }
                  ]
                },
                {
                  featureType: 'road',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#808080'
                    }
                  ]
                },
                {
                  featureType: 'road',
                  elementType: 'geometry.fill',
                  stylers: [
                    {
                      color: '#ffffff'
                    }
                  ]
                },
                {
                  featureType: 'road',
                  elementType: 'geometry.stroke',
                  stylers: [
                    {
                      color: '#808080'
                    }
                  ]
                },
                {
                  featureType: 'road',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#000000'
                    }
                  ]
                },
                {
                  featureType: 'road',
                  elementType: 'labels.text.stroke',
                  stylers: [
                    {
                      visibility: 'off'
                    }
                  ]
                },
                {
                  featureType: 'water',
                  stylers: [
                    {
                      visibility: 'on'
                    }
                  ]
                }
              ]
            }
          ];
        }
      },
      useMetricUnits: {
        type: Boolean,
        value: false
      },
      showSpanDistances: {
        type: Boolean,
        value: false
      },
      signedIn: Boolean,
      uid: String,
      userGroup: String,
      showThreeDViewAvatar: {
        computed: 'computeShowThreeDViewAvatar(threeDViewData, jobId)'
      },
      enabledFeatures: {
        type: Object,
        value: () => ({})
      }
    };
  }

  static get observers() {
    return [
      'updateSelectedIcon(selectedNode, activeConnection, activeSection, selectedNodeLatitude, selectedNodeLongitude)',
      'getSelectedLocation(selectedNode, activeConnection, activeSection, selectionLocationUpdated, signedIn)',
      'updateMapItemEditor(editingNode, editingItemJob, activeConnection, activeSection, selectedNodeLatitude, selectedNodeLongitude, editing, noEditButtons, activeCommand, readOnly)',
      'getHoverLocation(hoverItem)',
      'updateCustomMapTypes(customMapTypes.*, modelConfig.custom_map_base, googleIsReady)',
      'countSelectedItems(multiSelectClickedNodes.*, multiSelectClickedSections.*, multiSelectPolygonUpdated, useMetricUnits, hiddenNodes.*)',
      'buildJobStyleLookup(jobStyles.default.*)',
      'updateShowRubberBandPoly(mouseLat, mouseLng, selectedNode, activeCommandModel, activeCommand, tier, linkMapPhotoActions)',
      'toggleClickableReferenceJob(activeCommandModel)',
      'scidByPoleAppOrderChanged(actionDialogData.scidByPoleAppOrder)',
      'setSelectedNodeIdOn3dViewUserData(selectedNode)',
      'setSelectedNodeFrom3dView(threeDViewData.selectedNodeId)'
    ];
  }

  constructor() {
    super();
    this.config = config;
    this.apiKey = config.mapsApiKey;
  }

  connectedCallback() {
    super.connectedCallback();

    this.mouseupHandler = () => {
      this.mouseup = true;
      this.mousedown = false;
    };

    this.mouseDownHandler = () => {
      this.mouseup = false;
      this.mousedown = true;
    };

    document.addEventListener('mouseup', this.mouseupHandler);
    document.addEventListener('mousedown', this.mouseDownHandler);

    this.legacyInsertPoleFlagListener = addFlagListener('legacy_insert_pole', (enabled) => {
      this.set('enabledFeatures.legacy_insert_pole', enabled);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    document.removeEventListener('mousedown', this.mouseDownHandler);
    document.removeEventListener('mouseup', this.mouseupHandler);

    removeFlagListener('legacy_insert_pole', this.legacyInsertPoleFlagListener);
  }

  get multiSelectedNodes() {
    var nodes = this.multiSelectClickedNodes.slice(0);
    for (var nodeId in this.$.loadRenderMap.pointLocations) {
      // Check if the node appears in the polygon
      if (
        this.multiSelectPolygon &&
        nodeId.indexOf(':') == -1 &&
        this.$.loadRenderMap.pointLocations[nodeId].map &&
        this.$.loadRenderMap.pointLocations[nodeId].jobId == this.jobId &&
        google.maps.geometry.poly.containsLocation(this.$.loadRenderMap.pointLocations[nodeId].position, this.multiSelectPolygon)
      ) {
        nodes.push(nodeId);
        this.multiSelectNodeStyles[nodeId] = this.$.loadRenderMap.pointLocations[nodeId].data;
        let connections = this.$.loadRenderMap.pointLocations[nodeId].data.n;
        for (let key in connections) {
          this.multiSelectConnectionStyles[key] = this.$.loadRenderMap.lineLocations[key];
        }
      }
    }
    return nodes;
  }
  get multiSelectedSections() {
    var sections = this.multiSelectClickedSections.slice(0);
    for (var itemId in this.$.loadRenderMap.pointLocations) {
      // Check if the node appears in the polygon
      if (
        this.multiSelectPolygon &&
        this.$.loadRenderMap.pointLocations[itemId].map &&
        itemId.indexOf(':') != -1 &&
        this.$.loadRenderMap.pointLocations[itemId].jobId == this.jobId &&
        google.maps.geometry.poly.containsLocation(this.$.loadRenderMap.pointLocations[itemId].position, this.multiSelectPolygon)
      ) {
        this.multiSelectSectionStyles[itemId] = this.$.loadRenderMap.pointLocations[itemId].data;
        sections.push(itemId);
      }
    }
    return sections;
  }
  addActionDialogButtonAttributes(button) {
    if (button.attributes) {
      setTimeout(() => {
        let elem = this.$.actionDialogButtons.querySelector(`[name=${button.key}]`);
        if (elem)
          for (let attr in button.attributes) {
            elem.setAttribute(attr, button.attributes[attr]);
          }
      });
    }
  }
  actionDialogButtonHandler(e) {
    if (e.model.button.callback) e.model.button.callback();
  }
  equal(a, b) {
    return a == b;
  }
  if(condition, then, otherwise) {
    return condition ? then : otherwise;
  }
  ready() {
    if (!window.katapultMap) window.katapultMap = this;

    super.ready();
    this.$.googlemap.$.map.addEventListener('wheel', this.triedZooming.bind(this), true);
    this._boundLineByKnownLengthKeybinds = this.lineByKnownLengthKeybinds.bind(this);
  }

  confirm(heading, body, affirmativeText, dismissiveText, affirmativeStyle, confirmDialogBodyType, callback) {
    this.confirmDialogHeading = heading || '';
    this.confirmDialogBody = body || '';
    this.confirmDialogAffirmativeText = affirmativeText || '';
    this.confirmDialogDismissiveText = dismissiveText || '';
    this.confirmDialogAffirmativeStyle = affirmativeStyle || '';
    if (affirmativeStyle != null && affirmativeStyle != '') {
      this.confirmDialogDismissiveStyle = 'color:black';
    } else {
      this.confirmDialogDismissiveStyle = '';
    }
    this.confirmDialogCallback = callback || function () {};
    this.confirmDialogBodyType = confirmDialogBodyType;
    this.$.confirmDialog.open();
  }
  cancel() {
    this.activeCommand = null;
    this.mouseLat = null;
    this.mouseLng = null;
    if (this.map) this.map.setOptions({ draggableCursor: null });
    this.polygonMapHighlights = null;
    this.countTouchedConnections = false;
    this.lockedGeometryLength = false;
    this.lockedGeometryAngle = false;
    this.closeActionDialog();
    this.bisectorBearings = [];
    this.inlineBearings = [];
    this.multiSelectNodeStyles = {};
    this.multiSelectConnectionStyles = {};
    this.multiSelectIncludedTypes = {
      nodes: false,
      sections: false,
      connections: false
    };
    if (this.enterNextPolylinePoint) {
      this.togglePolylineEditing(this.enterNextPolylinePoint.connId);
      this.enterNextPolylinePoint = null;
    }
    if (this.multiSelectPolygon) this.multiSelectPolygon.getPath().clear();

    // Reset bisector and inline buttons.
    this._lastClickedAngleButton = null;
    this._lastSelectedNodeForBearings = null;
  }
  cancelActionDialog() {
    if (this.actionDialogCancelCallback) this.actionDialogCancelCallback();
    this.cancelPromptAction();
  }
  cancelPromptAction() {
    this.dispatchEvent(new CustomEvent('cancel'));
    this.cancel();
  }
  togglePolylineEditing(connId, override) {
    connId = SquashNulls(connId, 'detail', 'connId') || connId;
    var line = this.$.loadRenderMap.lineLocations[connId];
    if (line) {
      line.setEditable(typeof override === 'boolean' ? override : !line.getEditable());
      this.$.loadRenderMap.setupPolylineListeners(line.getPath(), line, connId, this.jobId);
    }
  }
  googleMapReady() {
    // Property used in bindings to ensure `google.maps` is loaded
    this.googleIsReady = true;
    this.dispatchEvent(new CustomEvent('google-map-ready'));
    this.multiSelectPolygon = new google.maps.Polygon({
      map: this.map,
      editable: true,
      clickable: true,
      fillColor: 'yellow',
      fillOpacity: 0.1,
      strokeColor: 'yellow',
      strokeOpacity: 0.7,
      strokePosition: 0
    });
    google.maps.event.addListener(this.multiSelectPolygon, 'rightclick', (e) => {
      if (e.vertex != null) {
        this.multiSelectPolygon.getPath().removeAt(e.vertex);
        this.multiSelectPolygonUpdated = !this.multiSelectPolygonUpdated;
      }
    });
    google.maps.event.addListener(this.multiSelectPolygon, 'mouseup', (e) => {
      this.multiSelectPolygonUpdated = !this.multiSelectPolygonUpdated;
    });
    google.maps.event.addListener(this.multiSelectPolygon, 'click', (e) => {
      this.multiSelectPolygon.getPath().push(new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()));
      this.multiSelectPolygonUpdated = !this.multiSelectPolygonUpdated;
    });
  }
  updateMapStyle(primaryColor, secondaryColor) {
    var styleString = JSON.stringify(this.katapultMapStyle);
    styleString = styleString.replace(/var\(--primary-color\)/g, primaryColor);
    styleString = styleString.replace(/var\(--secondary-color\)/g, secondaryColor);
    this.katapultMapStyle = JSON.parse(styleString);
    if (this.additionalMapOptions.styles != null) {
      this.set('additionalMapOptions.styles', this.katapultMapStyle);
    }
  }
  activeCommandChanged() {
    if (this.activeCommand == '_multiSelectItems') {
      this.multiSelectClickedNodes = [];
      this.multiSelectClickedSections = [];
      this.showMultiSelectCountDialog = true;
    } else if (this.activeCommand == null) {
      this.dispatchEvent(new CustomEvent('highlight-conn', { detail: null }));
      this.multiSelectClickedNodes = [];
      this.multiSelectClickedSections = [];
      this.showMultiSelectCountDialog = false;
    }
  }
  triedZooming(e) {
    // Only allow zoom past for these map bases.
    if (['hybrid', 'satellite', 'blank', 'terrain', 'roadmap'].includes(this.mapBase)) {
      // Initialize max zoom service.
      if (!this.maxZoom) this.maxZoom = new google.maps.MaxZoomService();

      this.maxZoom.getMaxZoomAtLatLng(this.map.getCenter(), (res) => {
        this._atMaxZoomDebounce = Debouncer.debounce(
          this._atMaxZoomDebounce,
          timeOut.after(100),
          () => (this.hasWaitedAtMaxZoom = this.zoom === res.zoom)
        );

        // If we are at the max zoom or past it.
        if (this.zoom >= res.zoom && this.mapBase !== 'blank') {
          // If the user is at the max zoom level and they are currently zooming in, change the map base so that they may exceed it.
          if (((this.hasWaitedAtMaxZoom && this.zoom === res.zoom) || this.zoom > res.zoom) && e.deltaY < 0) {
            this.mapBase = 'blank';
            // For some reason, zoom gets stuck at max zoom.  Kick it past manually.
            this.zoom += 1;
            this.hasWaitedAtMaxZoom = false;
            this.hasZoomedPast = true;
            this.superzoomOverlay();
          }
        }
        if (this.zoom <= res.zoom) {
          if (this.hasZoomedPast) {
            this.superzoomOverlayDisable();
          }
        }
      });
    } else {
      this.superzoomOverlayDisable(true);
    }
  }

  superzoomOverlay() {
    let imgUrl = 'https://khms0.googleapis.com/kh?v=871&hl=en-US&x=';
    if (this.prevMapBase == 'satellite') {
      imgUrl = 'http://mt0.google.com/vt/lyrs=s&hl=en&x=';
    } else if (this.prevMapBase == 'roadmap') {
      imgUrl = 'https://mt0.google.com/vt/lyrs=m&hl=en&x=';
    } else if (this.prevMapBase == 'terrain') {
      imgUrl = 'https://mt0.google.com/vt/lyrs=p&hl=en&x=';
    } else if (this.prevMapBase == 'hybrid') {
      imgUrl = 'https://mt0.google.com/vt/lyrs=y&hl=en&x=';
    } else {
      this.superzoomOverlayDisable();
    }

    let zscale = 20;

    let x = Math.floor((0.5 + this.longitude / 360) * (1 << zscale)); //derived from https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
    let y = Math.floor(
      (0.5 -
        Math.log(
          (1 + Math.min(Math.max(Math.sin((this.latitude * Math.PI) / 180), -0.9999), 0.9999)) /
            (1 - Math.min(Math.max(Math.sin((this.latitude * Math.PI) / 180), -0.9999), 0.9999))
        ) /
          (4 * Math.PI)) *
        (1 << zscale)
    );

    if (x == this.lastx && y == this.lasty) {
      return;
    }
    this.lastx = x;
    this.lasty = y;

    let xRad = Math.max(Math.ceil(this.getBoundingClientRect().width / 1024), 0);
    let yRad = Math.max(Math.ceil(this.getBoundingClientRect().height / 1024), 0);
    let i = 0;

    if (!this.zpOverlays) this.zpOverlays = [];

    for (let xp = -xRad; xp <= xRad; xp++) {
      for (let yp = -yRad; yp <= yRad; yp++) {
        let xx = x + xp;
        let yy = y + yp;

        let nwLatLng = this.map
          .getProjection()
          .fromPointToLatLng(new google.maps.Point((xx * 256) / (1 << zscale), (yy * 256) / (1 << zscale))); //px offset add to the 256
        let seLatLng = this.map
          .getProjection()
          .fromPointToLatLng(new google.maps.Point(((xx + 1) * 256) / (1 << zscale), ((yy + 1) * 256) / (1 << zscale)));

        let imgSrc = imgUrl + xx + '&y=' + yy + '&z=' + zscale + '#superzoom';
        let bounds = {
          north: nwLatLng.lat(),
          south: seLatLng.lat(),
          east: seLatLng.lng(),
          west: nwLatLng.lng()
        };

        if (this.zpOverlays[i]) {
          this.zpOverlays[i].setMap(null);
        }

        this.zpOverlays[i] = new google.maps.GroundOverlay(imgSrc, bounds, {
          clickable: false,
          map: this.map
        });
        i++;
      }
    }
    if (i < this.zpOverlays.length) {
      for (let j = i; j < this.zpOverlays.length; j++) {
        this.zpOverlays[j].setMap(null);
      }
      this.zpOverlays = this.zpOverlays.slice(0, (xRad * 2 + 1) * (yRad * 2 + 1));
    }
  }

  superzoomOverlayDisable(zooming) {
    Debouncer.debounce(this.superzoomOverlayDisable, timeOut.after(30), () => {
      if (this.mapBase == 'blank') this.mapBase = this.prevMapBase;
      if (this.zoom > 20 && !zooming) this.zoom = 20;
      this.hasZoomedPast = false;
      this.hasWaitedAtMaxZoom = false;

      if (this.zpOverlays) {
        for (let i = 0; i < this.zpOverlays.length; i++) {
          if (this.zpOverlays[i]) this.zpOverlays[i].setMap(null);
        }
      }

      this.lastx = null;
      this.lasty = null;
    });
  }

  mapBaseChanged() {
    if (this.mapBase != 'blank') {
      this.superzoomOverlayDisable();
    }
  }

  buildJobStyleLookup() {
    var styleLookup = {};
    if (this.jobStyles) {
      for (var property in this.jobStyles.default) {
        if (property[0] != '_') {
          for (var i = 0; i < this.jobStyles.default[property].length; i++) {
            styleLookup[this.jobStyles.default[property][i].id] = this.jobStyles.default[property][i];
          }
        }
      }
    }

    // Add in the default styles for miscellaneous items
    styleLookup['mn'] = { color: '#f0f', icon: 'katapult-map:circle', size: 9, id: 'mn' };
    styleLookup['mc'] = { color: '#aa00ff', pattern: 'solid', width: 7, opacity: 0.7, id: 'mc' };
    styleLookup['mms'] = { color: '#00f', icon: 'katapult-map:triangle-up', size: 18, id: 'mms' };
    styleLookup['ms'] = { color: '#0ff', icon: 'katapult-map:triangle-up', size: 18, id: 'ms' };

    this.jobStylesLookup = styleLookup;
  }
  countSelectedItems() {
    // Set the polygonSelect polygon onto the loadRenderMap element to use when determining a geo boundary
    this.$.loadRenderMap.polygonSelect = this.multiSelectPolygon || null;
    // Tell loadRenderMap to update the geo query
    this.$.loadRenderMap.updateGeoQuery();

    this.countSelectedItemsDebouncer = Debouncer.debounce(this.countSelectedItemsDebouncer, timeOut.after(1), () => {
      let connCount = 0;
      let connLength = 0;
      const nodeItemCounts = {};
      const sectionItemCounts = {};
      const connItemCounts = {};
      const foundConns = {};
      const countedConns = {};

      const multiNodes = this.multiSelectedNodes;
      const multiSections = this.multiSelectedSections;

      for (let i = 0; i < multiNodes.length; i++) {
        const nodeId = multiNodes[i];
        const styleId = this.multiSelectNodeStyles[nodeId].u;

        // Build a key of all stacked styles on the node
        let styleIds = '';
        if (this.multiSelectNodeStyles[nodeId].is) {
          for (let j = 0; j < this.multiSelectNodeStyles[nodeId].is.length; j++) {
            const styleId = this.multiSelectNodeStyles[nodeId].is[j].u;
            // Skip adding the style if it is hidden
            if (this.hiddenMarkers?.[styleId] === true) continue;

            styleIds += styleId;
          }
        } else {
          styleIds = styleId;
        }

        if (nodeItemCounts[styleIds] == null) {
          const styleIcon = StyleRuleToIcon(
            this.jobStylesLookup[styleId],
            this.getRootNode().host.isLiteTier(this.getRootNode().host.tier)
          );
          // Check if the item has a geoStyleIcon and convert to object url
          const iconData = GeoStyleToIcon(this.multiSelectNodeStyles[nodeId], {
            hiddenItems: this.hiddenMarkers
          });
          if (iconData) {
            let svg = iconData.url.replace('data:image/svg+xml,', '');
            // Put the # back in the SVG
            svg = svg.replace(/\%23/g, '#');
            // Convert to blob and then to image URL
            const blob = new Blob([svg], { type: 'image/svg+xml' });
            styleIcon.iconSrc = URL.createObjectURL(blob);
            // Delete the normal icon and style
            delete styleIcon.icon;
            delete styleIcon.style;
          }
          // Handle the title for stacked styles
          if (this.multiSelectNodeStyles[nodeId].is) {
            let title = '';
            for (let j = this.multiSelectNodeStyles[nodeId].is.length - 1; j > -1; j--) {
              const style = StyleRuleToIcon(
                this.jobStylesLookup[this.multiSelectNodeStyles[nodeId].is[j].u],
                this.getRootNode().host.isLiteTier(this.getRootNode().host.tier)
              );
              // Skip adding the style to the title if it is hidden
              if (this.hiddenMarkers?.[style.id] === true) continue;
              title += title.length > 0 ? ` & ${style.title}` : style.title;
            }
            styleIcon.title = title;
          }
          styleIcon.count = 0;
          nodeItemCounts[styleIds] = styleIcon;
        }
        nodeItemCounts[styleIds].count++;

        // Mark that we have one or both of the endpoints of the connections on this node
        for (const connId in this.multiSelectNodeStyles[nodeId].n) {
          if (this.multiSelectConnectionStyles[connId] && this.multiSelectConnectionStyles[connId].map) {
            // If we havent seen this conn yet, and we are only counting encapsulated conns, add it to the list of conns we have one endpoint for
            if (!this.countTouchedConnections && foundConns[connId] == null) {
              foundConns[connId] = true;
            }
            // If we are counting encapsulated connections or we arent and haven't seen this connection before
            else if (!this.countTouchedConnections || foundConns[connId] == null) {
              foundConns[connId] = true;
              countedConns[connId] = true;
              const connGeoData = this.multiSelectConnectionStyles[connId].data;
              connCount++;
              connLength += connGeoData.d;
              if (connItemCounts[connGeoData.u] == null) {
                const styleIcon = StyleRuleToIcon(
                  this.jobStylesLookup[connGeoData.u],
                  this.getRootNode().host.isLiteTier(this.getRootNode().host.tier)
                );
                styleIcon.count = 0;
                styleIcon.length = 0;
                connItemCounts[connGeoData.u] = styleIcon;
              }
              connItemCounts[connGeoData.u].count++;
              connItemCounts[connGeoData.u].length += connGeoData.d;
            }
          }
        }
      }

      for (var i = 0; i < multiSections.length; i++) {
        const sectionId = multiSections[i];
        const styleId = this.multiSelectSectionStyles[sectionId].u;

        // Build a key of all stacked styles on the section
        let styleIds = '';
        if (this.multiSelectSectionStyles[sectionId].is) {
          for (let j = 0; j < this.multiSelectSectionStyles[sectionId].is.length; j++) {
            const styleId = this.multiSelectSectionStyles[sectionId].is[j].u;
            // Skip adding the style if it is hidden
            if (this.hiddenMarkers?.[styleId] === true) continue;

            styleIds += styleId;
          }
        } else {
          styleIds = styleId;
        }

        if (sectionItemCounts[styleIds] == null) {
          const styleIcon = StyleRuleToIcon(
            this.jobStylesLookup[styleId],
            this.getRootNode().host.isLiteTier(this.getRootNode().host.tier),
            {
              sectionKey: sectionId
            }
          );
          // Check if the item has a geoStyleIcon and convert to object url
          const iconData = GeoStyleToIcon(this.multiSelectSectionStyles[sectionId], {
            hiddenItems: this.hiddenMarkers
          });
          if (iconData) {
            let svg = iconData.url.replace('data:image/svg+xml,', '');
            // Put the # back in the SVG
            svg = svg.replace(/\%23/g, '#');
            // Convert to blob and then to image URL
            const blob = new Blob([svg], { type: 'image/svg+xml' });
            styleIcon.iconSrc = URL.createObjectURL(blob);
            // Delete the normal icon and style
            delete styleIcon.icon;
            delete styleIcon.style;
          }
          // Handle the title for stacked styles
          if (this.multiSelectSectionStyles[sectionId].is) {
            let title = '';
            for (let j = this.multiSelectSectionStyles[sectionId].is.length - 1; j > -1; j--) {
              const style = StyleRuleToIcon(
                this.jobStylesLookup[this.multiSelectSectionStyles[sectionId].is[j].u],
                this.getRootNode().host.isLiteTier(this.getRootNode().host.tier)
              );
              // Skip adding the style to the title if it is hidden
              if (this.hiddenMarkers?.[style.id] === true) continue;
              title += title.length > 0 ? ` & ${style.title}` : style.title;
            }
            styleIcon.title = title;
          }
          styleIcon.count = 0;
          sectionItemCounts[styleIds] = styleIcon;
        }
        sectionItemCounts[styleIds].count++;
      }
      for (const key in connItemCounts) {
        connItemCounts[key].length = this.useMetricUnits
          ? Round(connItemCounts[key].length * 0.3048, 1) + 'm'
          : Round(connItemCounts[key].length, 1) + "'";
      }
      connLength = this.useMetricUnits ? Round(connLength * 0.3048, 1) + 'm' : Round(connLength, 1) + "'";
      this.multiNodeCount = multiNodes.length;
      this.multiConnCount = connCount;
      this.multiSectionCount = multiSections.length;
      this.multiConnLength = connLength;
      this.multiSelectedConnections = countedConns;
      this.multiSectionItems = Object.values(sectionItemCounts).sort((a, b) => b.count - a.count);
      this.multiNodeItems = Object.values(nodeItemCounts).sort((a, b) => b.count - a.count);
      this.multiConnItems = Object.values(connItemCounts).sort((a, b) => b.count - a.count);
    });
  }

  getMarkerIcon(icon, size, color, anchorX, anchorY) {
    size = size || 16;
    let name, set, temp;
    if (icon && icon.indexOf(':') > -1) {
      icon = icon.split(':');
      name = icon.pop();
      set = this.$.meta.byKey(icon.pop());
    } else {
      name = icon;
      set = this.$.meta.byKey('katapult-map');
    }
    if (!set) return null;
    temp = set.applyIcon(document.createElement('text'), name);
    if (!temp || temp.localName != 'svg' || !temp.innerHTML) return null;
    return {
      url:
        'data:image/svg+xml;utf-8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill:' +
        (color || '#000') +
        ';">' +
        temp.innerHTML +
        '</svg>',
      anchor: { x: anchorX || size / 2, y: anchorY || size / 2 },
      scaledSize: {
        width: size,
        height: size
      },
      size: {
        width: size,
        height: size
      }
    };
  }

  updateMapItemEditor(
    editingNode,
    activeConnection,
    activeSection,
    selectedNodeLatitude,
    selectedNodeLongitude,
    editing,
    noEditButtons,
    activeCommand,
    readOnly
  ) {
    const itemJobId = this.editingItemJob;
    const nodeId = this.editingNode;
    const connId = this.activeConnection;
    const sectionId = this.activeSection;
    const latitude = this.selectedNodeLatitude;
    const longitude = this.selectedNodeLongitude;
    const itemKey = nodeId ? nodeId : connId && sectionId ? `${connId}:${sectionId}` : null;

    const exit = !latitude || !longitude || !itemKey || this.activeCommand || this.readOnly || itemJobId != this.jobId;

    if (this._updateMapItemEditorItemKey != itemKey || exit) {
      this.mapItemEditor?.remove();
      this.mapItemEditor = null;
      this._updateMapItemEditorItemKey = itemKey;
    }

    if (exit) return;

    if (!globalThis.google) return;
    const position = new globalThis.google.maps.LatLng(latitude, longitude);
    const hideEditButtons = this.editing != null || this.noEditButtons;

    if (this.mapItemEditor) {
      Object.assign(this.mapItemEditor, { position, hideEditButtons });
    } else {
      const getItemDragEvent = (e) => {
        // Create initial details object for the infoMarker
        const detail = { latLng: e.latLng, key: itemKey };
        // Get the marker object from loadRenderMap by the node id
        const pointLocation = this.$.loadRenderMap.pointLocations[itemKey];
        if (pointLocation?.jobId == this.jobId) {
          Object.assign(detail, { geoData: pointLocation.data, marker: pointLocation });
          if (pointLocation.data.t == 's') Object.assign(detail, { connId, sectionId });
        }
        return { detail };
      };
      const isNode = !itemKey.includes(':');
      const editor = (this.mapItemEditor ??= new MapItemEditor({
        map: this.map,
        position,
        hideEditButtons,
        onEdit: () =>
          setTimeout(() => {
            this.editing = isNode ? 'Node' : 'Section';
          }, 25),
        onDelete: () =>
          setTimeout(() => {
            this.deleteItem(isNode ? 'delete node' : 'delete section');
          }, 25),
        onDragstart: (e) => this[isNode ? 'dragNodeStart' : 'dragSection'](getItemDragEvent(e)),
        onDrag: (e) => this[isNode ? 'dragNode' : 'dragSection'](getItemDragEvent(e)),
        onDragend: (e) => this[isNode ? 'dragNodeEnd' : 'dragSectionEnd'](getItemDragEvent(e))
      }));
    }
  }

  getSelectedLocation(selectedNode, activeConnection, activeSection) {
    if (selectedNode != null || activeSection != null) {
      var path = this.getGeoPath(selectedNode, activeConnection, activeSection);
      if (path) {
        GetJobData(this.jobId, path).then((data) => {
          // Double check that the location we are getting matches the current location
          if (data && data[path] && path == this.getGeoPath(this.selectedNode, this.activeConnection, this.activeSection)) {
            this.selectedNodeLatitude = data[path][0];
            this.selectedNodeLongitude = data[path][1];
          }
        });
      }
    } else {
      this.selectedNodeLatitude = null;
      this.selectedNodeLongitude = null;
    }
  }
  getGeoPath(selectedNode, activeConnection, activeSection) {
    if (selectedNode) {
      return 'geohash/' + selectedNode + '/l';
    } else {
      return 'geohash/' + activeConnection + ':' + activeSection + '/l';
    }
  }
  getHoverLocation(hoverItem) {
    if (hoverItem == null) return null;
    let nodeKey = hoverItem[0] == 'n' ? hoverItem.slice(1) : null;
    let sectionKey = hoverItem[0] == 's' ? hoverItem.slice(1) : null;
    let geoHashPath = `geohash/${hoverItem.slice(1)}/l`;
    GetJobData(this.jobId, geoHashPath).then((data) => {
      this.updateHoverIcon(nodeKey, sectionKey, data[geoHashPath][0], data[geoHashPath][1]);
    });
  }
  rightClickMap(e) {
    if (this.activeCommand === '_measure_map') return this.popMeasureMarker();
    if (this.activeCommand === '_drawPolygon') return;
    if (this.activeCommand != null) e.preventDefault();
    this.dispatchEvent(new CustomEvent('cancel'));
    this.cancel();
  }
  measureOnMap(e, d) {
    if (d && d.start) {
      this.activeCommand = '_measure_map';
      this.set('measureMarkers', []);
      this.map.setOptions({ draggableCursor: 'default' });
      this.toast('Left Click = Add Point to Map. Right Click = Remove Most Recent Point. Click the Ruler again to stop.', null, -1);
    } else if (d && d.stop) {
      this.dispatchEvent(new CustomEvent('cancel'));
      this.cancel();
      this.map.setOptions({ draggableCursor: null });
      if (this.measureMarkerPoly) this.measureMarkerPoly.setMap(null);
      if (this.measureMarkersMark) for (let i of this.measureMarkersMark) i.setMap(null);
      this.toast('Done Measuring!', null, 3000);
    } else this.toast('Measure Map tool was incorrectly configured! 🤐');
  }
  addMeasureMarker(loc) {
    this.push('measureMarkers', loc);
    let len = google.maps.geometry.spherical.computeLength(this.measureMarkers);
    if (len > 0) this.toast('Measured Dist: ' + Round(3.28084 * len, 2) + ' ft (' + Round(len, 2) + ' m)', null, -1);
    else this.toast('Left Click = Add Point to Map. Right Click = Remove Most Recent Point. Click the Ruler again to stop.', null, -1);
    this.drawMeasuredMarkers();
  }
  popMeasureMarker() {
    this.pop('measureMarkers');
    let len = google.maps.geometry.spherical.computeLength(this.measureMarkers);
    if (len > 0) this.toast('Measured Dist: ' + Round(3.28084 * len, 2) + ' ft (' + Round(len, 2) + ' m)', null, -1);
    else this.toast('Left Click = Add Point to Map. Right Click = Remove Most Recent Point. Click the Ruler again to stop.', null, -1);
    this.drawMeasuredMarkers();
  }
  drawMeasuredMarkers() {
    if (this.measureMarkerPoly) this.measureMarkerPoly.setMap(null);
    if (this.measureMarkersMark) for (let i of this.measureMarkersMark) i.setMap(null);
    this.measureMarkersMark = this.measureMarkersMark || [];
    for (let i of this.measureMarkers)
      this.measureMarkersMark.push(new google.maps.Marker({ map: this.map, position: i, clickable: false }));
    this.measureMarkerPoly = new google.maps.Polyline({
      map: this.map,
      path: this.measureMarkers,
      strokeColor: 'black',
      strokeOpacity: 0.8,
      strokeWeight: 4
    });
  }
  /**   Initialize rubber band line   */
  startMapMouse(e, d) {
    if (this.map) {
      if (this.activeCommand) {
        this.mouseLat = d.latLng.lat();
        this.mouseLng = d.latLng.lng();
      }
    }
  }
  /**   Changes properties on google-map-mouseover or google-map-drag (specifically for rubber band line)   */
  mapMouse(e, d) {
    if (this.activeCommand == null && this.mouseLat == null && this.mouseLng == null) return;

    if (d.latLng) {
      let lat = d.latLng.lat();
      let lng = d.latLng.lng();
      let snap;
      if (this.lockedGeometryAngle || this.lockedGeometryLength) {
        let latLng = new google.maps.LatLng({ lat: this.selectedNodeLatitude, lng: this.selectedNodeLongitude });
        if (this.lockedGeometryAngle && this.lockedGeometryLength)
          snap = google.maps.geometry.spherical.computeOffset(
            latLng,
            this.snappingLength * (this.useMetricUnits ? 1 : 0.3048),
            this.snappingAngle
          );
        else if (this.lockedGeometryAngle) {
          let distance = google.maps.geometry.spherical.computeDistanceBetween(latLng, new google.maps.LatLng({ lat: lat, lng: lng }));
          snap = google.maps.geometry.spherical.computeOffset(latLng, distance, this.snappingAngle);
        } else if (this.lockedGeometryLength) {
          let bearing = google.maps.geometry.spherical.computeHeading(latLng, new google.maps.LatLng({ lat: lat, lng: lng }));
          snap = google.maps.geometry.spherical.computeOffset(latLng, this.snappingLength * (this.useMetricUnits ? 1 : 0.3048), bearing);
        }
      }
      if (snap) {
        this.mouseLat = snap.lat();
        this.mouseLng = snap.lng();
        this.map.setOptions({ draggableCursor: null });
      } else {
        this.mouseLat = lat;
        this.mouseLng = lng;
        if (this.showRubberBandDropper(this.mouseLat, this.mouseLng, this.activeCommand, this.tier, this.linkMapPhotoActions)) {
          this.map.setOptions({ draggableCursor: 'none' });
        } else if (this.map.draggableCursor == 'none') {
          this.map.setOptions({ draggableCursor: null });
        }
      }
    }
  }
  //for superzoomOverlay, detects when map is moved
  mapMoved() {
    if (this.lastx != null) this.superzoomOverlay();
  }
  getPixelCoord(lat, lng) {
    if (!this.map || !google || !this.zoom) return {};
    let projection = this.map.getProjection();
    if (!projection) return {};
    let bounds = this.map.getBounds();
    if (!bounds) return {};
    let topRight = projection.fromLatLngToPoint(bounds.getNorthEast()),
      bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest()),
      scale = 1 << this.zoom,
      worldPoint = projection.fromLatLngToPoint(new google.maps.LatLng(lat, lng));
    return new google.maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
  }
  clickMap(e, d) {
    let _activeCommand = this.activeCommand;
    if (e.currentTarget.localName != 'google-map') return;
    if (this.activeCommand == '_multiSelectItems') {
      this.multiSelectPolygon.getPath().push(new google.maps.LatLng(d.latLng.lat(), d.latLng.lng()));
      this.multiSelectPolygonUpdated = !this.multiSelectPolygonUpdated;
    } else if (this.activeCommand == '_measure_map') this.addMeasureMarker(d.latLng);
    else if (this.activeCommand == '$moveAnchor') {
      const nodeId = this.editingNode;
      const latLng = new google.maps.LatLng(this.mouseLat, this.mouseLng);
      const geoData = this.$.loadRenderMap.pointLocations[nodeId].data;
      this.push('undoLog', {
        type: 'move',
        nodeId: nodeId,
        latitude: geoData.l[0],
        longitude: geoData.l[1],
        connections: {}
      });
      this.moveNode(nodeId, { latLng, geoData });
      this.cancelPromptAction();
    } else if (this.activeCommand != null) {
      let b = this.activeCommandModel;
      if (SquashNulls(b, 'node', 'location') == 'new' || SquashNulls(b, 'section', 'location') == 'new') this.addFromButton(e, d);
    } else if (!['_rotateIcon'].includes(_activeCommand)) {
      this.editingNode = null;
      this.selectedNode = null;
      this.activeConnection = null;
      this.activeSection = null;
    }
    this.dispatchEvent(new CustomEvent('click-map', { detail: e.detail }));
  }
  addFromButton(e, d) {
    // We're adding a new node so we don't want to automatically zoom to the job anymore
    this.zoomToJob = false;
    this.activeConnection = null;
    this.lockedGeometryAngle = false;
    this.lockedGeometryLength = false;
    let originalNode = this.editingNode;

    // Reset caching props used for snapping
    this._lastClickedAngleButton = null;
    this._lastSelectedNodeForBearings = null;

    let jobRef = FirebaseWorker.ref('photoheight/jobs/' + this.jobId);
    let update = {};
    let dataFetches = [];
    let new_node_id, node, setSelectedNode, setSelectedSection;

    let n = 'node',
      c = 'connection',
      s = 'section',
      l = 'location',
      editNode = false,
      editConn = false,
      editSection = false,
      latitude = this.mouseLat || d.latLng.lat(),
      longitude = this.mouseLng || d.latLng.lng(),
      button = this.activeCommandModel,
      keepOriginalNode;

    if (this.enterNextPolylinePoint) {
      var breakpoints = this.enterNextPolylinePoint.points.slice(0);
      var newEndpoint = [latitude, longitude];
      update['geohash/' + this.enterNextPolylinePoint.connId + '~1/b'] = breakpoints;
      update['geohash/' + this.enterNextPolylinePoint.connId + '~2/b'] = breakpoints;
      update['connections/' + this.enterNextPolylinePoint.connId + '/breakpoints'] = breakpoints;
      update['geohash/' + this.enterNextPolylinePoint.connId + '~1/l'] = newEndpoint;
      update['geohash/' + this.enterNextPolylinePoint.connId + '~2/l2'] = newEndpoint;
      update['geohash/' + this.enterNextPolylinePoint.endpointNodeId + '/l'] = newEndpoint;
      update['nodes/' + this.enterNextPolylinePoint.endpointNodeId + '/latitude'] = latitude;
      update['nodes/' + this.enterNextPolylinePoint.endpointNodeId + '/longitude'] = longitude;
      this.enterNextPolylinePoint.points.unshift(newEndpoint);
      var length = this.getConnPolylineLength(
        null,
        this.enterNextPolylinePoint.points.concat([this.enterNextPolylinePoint.firstPointLocation])
      );
      update['geohash/' + this.enterNextPolylinePoint.connId + '~1/d'] = length;
      update['geohash/' + this.enterNextPolylinePoint.connId + '~2/d'] = length;
      FirebaseWorker.ref('photoheight/jobs/' + this.jobId).update(update, (err) => {
        if (err) {
          this.toast(err);
        }
        this.selectionLocationUpdated = !this.selectionLocationUpdated;
      });
      return;
    }
    if (SquashNulls(button, n, l) == 'new') {
      let bn = button.node;
      let nodeResults = {};

      let attributes = [];
      // Populate node's attributes with defaults from button
      for (const property in bn.attributes) {
        if (bn.attributes[property].value != null) {
          let key = 'button_added';
          const guiElement = this.otherAttributes?.[property]?.gui_element;

          // We need to handle multi_dropdown attributes differently because they have a different data structure
          if (guiElement == 'multi_dropdown') {
            const values = bn.attributes[property].value ?? {};
            const valuesEntries = Object.entries(values);
            for (const [key, value] of valuesEntries) {
              attributes.push({ attribute: property, key, value });
            }
          } else {
            // For table attributes we use a push id as the key instead of button_added
            if (guiElement == 'table') key = FirebaseWorker.ref(`photoheight/jobs/${this.jobId}/nodes`).push().key;

            attributes.push({
              attribute: property,
              key,
              value: bn.attributes[property].value
            });
          }
        }
      }

      // new insert pole functionality here
      if (this.enabledFeatures.legacy_insert_pole || button.label != 'Insert Pole') {
        //  This code will run for when users try to create a new node that is NOT a pole AND if they want to
        //  the legacy way of inserting nodes.
        nodeResults = DataLayer.Nodes.create(latitude, longitude, this.otherAttributes, FirebaseWorker, {
          update,
          method: 'desktop',
          button: this.activeCommand,
          jobStyles: this.jobStyles,
          attributes
        });
      } else {
        let closestConnections = this.findClosest(latitude, longitude, {
          includeConnections: true,
          absoluteClosest: true
        });
        let closestConnectionId = closestConnections.item.slice(1);

        var snap = KatapultGeometry.SnapToLine(
          latitude,
          longitude,
          this.$.loadRenderMap.lineLocations[closestConnectionId].data.l[0],
          this.$.loadRenderMap.lineLocations[closestConnectionId].data.l[1],
          this.$.loadRenderMap.lineLocations[closestConnectionId].data.l2[0],
          this.$.loadRenderMap.lineLocations[closestConnectionId].data.l2[1]
        );

        // Create the node
        nodeResults = DataLayer.Nodes.create(snap.lat, snap.long, this.otherAttributes, FirebaseWorker, {
          update,
          method: 'desktop',
          button: this.activeCommand,
          jobStyles: this.jobStyles,
          attributes
        });
      }

      node = nodeResults.data;
      new_node_id = nodeResults.key;

      this.editingNode = new_node_id;
      var lastSelectedNode = this.selectedNode;
      var lastSelectedNodeLat = this.selectedNodeLatitude;
      var lastSelectedNodeLng = this.selectedNodeLongitude;
      editNode = !!bn.open_for_editing;

      if (SquashNulls(bn, 'insert') == true) {
        let conn = null;
        // Loop through the points on the map to get the data based on the closest node on the map
        for (let id in this.$.loadRenderMap.lineLocations) {
          // Get the geo data for the point
          let geoData = this.$.loadRenderMap.lineLocations[id].data;
          // Get the distance from the clicked location to the current point
          let distance = KatapultGeometry.CalcDistanceToLine(latitude, longitude, geoData.l[0], geoData.l[1], geoData.l2[0], geoData.l2[1]);
          // Update conn if we found a closer point
          if (!conn || conn.distance > distance) conn = { id, distance, geoData };
        }

        // If we have data for the connection, then continue to create the data
        if (conn && conn.distance < 10) {
          // Get the ids of the nodes connected to the existing connection
          for (let id in this.$.loadRenderMap.pointLocations) {
            // Check to see if the connection's id is in the node's geodata
            if (SquashNulls(this.$.loadRenderMap.pointLocations, id, 'data', 'n', conn.id) == 1) {
              // Get the location for the first node
              conn.node1Location = SquashNulls(this.$.loadRenderMap.pointLocations, id, 'data', 'l');
            }
            if (SquashNulls(this.$.loadRenderMap.pointLocations, id, 'data', 'n', conn.id) == 2) {
              conn.nId2 = id;
              // Get the location for the second node
              conn.node2Location = SquashNulls(this.$.loadRenderMap.pointLocations, id, 'data', 'l');
            }
          }

          dataFetches.push(
            GetJobData(this.jobId, 'connections/' + conn.id).then((data) => {
              let connData = data['connections/' + conn.id];
              // Build a list of attributes to assign to the new connection (based off the existing connection's attributes)
              let connectionAttributes = [];
              for (let attribute in connData.attributes) {
                for (let attributeKey in connData.attributes[attribute]) {
                  connectionAttributes.push({
                    attribute,
                    key: attributeKey,
                    value: connData.attributes[attribute][attributeKey]
                  });
                }
              }
              // Create the new connection
              let node1Data = { latitude: conn.node1Location[0], longitude: conn.node1Location[1] };
              let node2Data = { latitude: conn.node2Location[0], longitude: conn.node2Location[1] };
              let connectionResults = DataLayer.Connections.insertNode(
                conn.id,
                connData,
                node1Data,
                node2Data,
                new_node_id,
                node,
                this.otherAttributes,
                FirebaseWorker,
                {
                  update,
                  method: 'desktop',
                  attributes: connectionAttributes,
                  jobStyles: this.jobStyles,
                  connection_length_attributes: SquashNulls(this.modelConfig, 'connection_length_attributes')
                }
              );
            })
          );
        } else {
          this.toast('Please select closer to an existing connection');
          setTimeout(() => {
            this.showSnappingBox = true;
          }, 2000);
          return;
        }
      }
      if (bn.take_focus || this.selectedNode == null) setSelectedNode = new_node_id;
      if (!bn.open_for_editing && !bn.take_focus) keepOriginalNode = true;

      // this.setVirtualPosition([{ latitude, longitude }]);
    }
    if (SquashNulls(button, c, l + '_1') == 'active' && SquashNulls(button, c, l + '_2') == 'new' && lastSelectedNode) {
      editConn = this.createConnection(
        this.editingNode,
        lastSelectedNode,
        [latitude, longitude],
        [lastSelectedNodeLat, lastSelectedNodeLng],
        true,
        update
      );
      if (button.connection.polyline) {
        this.enterNextPolylinePoint = {
          connId: this.activeConnection,
          points: [[latitude, longitude]],
          endpointNodeId: this.editingNode,
          firstPointLocation: [lastSelectedNodeLat, lastSelectedNodeLng]
        };
        this.$.loadRenderMap.setConnEditable = this.activeConnection;
      }
      this.showSnappingBox = true;
    }

    if (SquashNulls(button, s, l) == 'new') {
      let conn, point;
      for (let id in this.$.loadRenderMap.lineLocations) {
        let geoData = this.$.loadRenderMap.lineLocations[id].data,
          distance = KatapultGeometry.CalcDistanceToLine(latitude, longitude, geoData.l[0], geoData.l[1], geoData.l2[0], geoData.l2[1]);
        if (!conn || conn.distance > distance) conn = { id, distance, geoData };
      }
      if (conn.distance < 10) {
        point = KatapultGeometry.SnapToLine(
          latitude,
          longitude,
          conn.geoData.l[0],
          conn.geoData.l[1],
          conn.geoData.l2[0],
          conn.geoData.l2[1]
        );
        if (button.section.open_for_editing) {
          editSection = true;
        }
        // Check if a 'primary' section exists at  'midpoint_section' location && set key for section accordingly;
        let path = 'connections/' + conn.id + '/sections/midpoint_section';
        dataFetches.push(
          GetJobData(this.jobId, path).then((data) => {
            var sectKey;
            if (data[path] != null) sectKey = jobRef.push().key;
            else sectKey = 'midpoint_section';
            if (button.section.open_for_editing) {
              setSelectedSection = { sectionId: sectKey, connId: conn.id };
            }

            let attributes = [];
            var sectionAttributes = SquashNulls(this.modelConfig, 'section_attributes');
            for (var key in sectionAttributes) {
              attributes.push({
                attribute: key,
                key: 'button_added',
                value: sectionAttributes[key]
              });
            }
            // Populate node's attributes with defaults from button
            for (var key in button.section.attributes) {
              if (button.section.attributes[key].value != null) {
                attributes.push({
                  attribute: key,
                  key: 'button_added',
                  value: button.section.attributes[key].value
                });
              }
            }
            DataLayer.Sections.create(conn.id, null, point.lat, point.long, this.otherAttributes, FirebaseWorker, {
              update,
              method: 'desktop',
              key: sectKey,
              attributes,
              jobStyles: this.jobStyles
            });
            // this.setVirtualPosition([{ latitude: conn.n1.latitude, longitude: conn.n1.longitude}, { latitude: conn.n2.latitude, longitude: conn.n2.longitude }]);
          })
        );
      } else {
        this.toast('Please select closer to an existing connection');
        setTimeout(() => {
          this.showSnappingBox = true;
        }, 2000);
      }
    }
    var refreshGeoQuery = false;
    if (this.$.loadRenderMap.pointCount == 0) {
      refreshGeoQuery = true;
    }
    Promise.all(dataFetches).then(() => {
      // Run the update
      jobRef.update(update, (err) => {
        if (setSelectedNode) this.selectedNode = setSelectedNode;
        if (setSelectedSection) {
          this.activeSection = setSelectedSection.sectionId;
          this.activeConnection = setSelectedSection.connId;
        }
        if (refreshGeoQuery) this.$.loadRenderMap.updateGeoQuery();
        if (new_node_id) this.dispatchEvent(new CustomEvent('item-drawn', { detail: { nodeId: new_node_id } }));
      });
    });
    if (editNode || editConn) {
      if (this.activeCommand != 'spatial_note') this.editingNodeAndConn = true;
      this.editing = 'Node';
    } else if (editSection) {
      this.editing = 'Section';
    } else if (SquashNulls(button, n, 'next_button')) this.selectMapButton(null, null, { id: button.node.next_button });
    else if (SquashNulls(button, c, 'next_button')) this.selectMapButton(null, null, { id: button.connection.next_button });
    else if (SquashNulls(button, s, 'next_button')) this.selectMapButton(null, null, { id: button.section.next_button });

    if (keepOriginalNode) this.editingNode = originalNode;
  }
  getAvatar(color = '#F9BC2B', heading) {
    // #E0812D
    return `<div style="position: absolute; top: 0; left: 0; height: 16px; width: 16px; background-color: ${color}; border-radius: 100%; box-shadow: 1px 1px 2px rgba(0,0,0,0.4); border: 1px solid #444;"></div>
            <div style="position: absolute; top: 0; left: 0; height: 18px; width: 18px; transform: rotateZ(${heading || 0}deg)">
              <div style="position: absolute; top: -9px; left: 1px; width: 0; height: 0; border-left: 8px solid transparent; border-right: 8px solid transparent; border-bottom: 8px solid #444;"></div>
              <div style="position: absolute; top: -8px; left: 3px; width: 0; height: 0; border-left: 6px solid transparent; border-right: 6px solid transparent; border-bottom: 6px solid ${color};"></div>
            </div>`;
    /*${8 + this.get('streetViewData.pov.zoom') * 2}
              ${7 + this.get('streetViewData.pov.zoom') * 2}*/
  }
  streetViewAvatarDragEnd(e) {
    this.setStreetViewPosition({
      detail: { position: { lat: e.currentTarget.marker.getPosition().lat(), lng: e.currentTarget.marker.getPosition().lng() } }
    });
  }
  changeStreetViewPov(e) {
    // function used to track mouse position and set pov
    if (e.ctrlKey == false && e.metaKey == false) {
      // remove the listener that keeps track of mouse position
      this.removeEventListener('mousemove', this.changeStreetViewPov, { capture: false });
      // allow the map to be drug
      this.map.setOptions({ gestureHandling: 'greedy' });
    }
    // get the mouse coords
    let mouseX = e.clientX;
    let mouseY = e.clientY;
    // get the difference between streetViewAvatars coords and the mouse's current coords
    let dx = this.streetViewAvatarX - mouseX;
    let dy = this.streetViewAvatarY - mouseY;
    // get an angle from the coords
    let theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    theta -= 90; // subtract 90 degrees for some reason
    if (theta < 0) theta = 360 + theta; // range [0, 360)
    // set the firebase data for the pov angle
    this.setStreetViewPov({
      detail: { pov: { heading: theta, pitch: this.get('streetViewData.pov.pitch'), zoom: this.get('streetViewData.pov.zoom') } }
    });
  }
  streetViewAvatarDrag(e) {
    let streetViewAvatar = this.shadowRoot.querySelector('#streetviewAvatar');
    // check if the Ctrl key is being pressed
    if (e.detail.ctrlKey || e.detail.metaKey) {
      this.streetViewAvatarX = e.detail.clientX;
      this.streetViewAvatarY = e.detail.clientY;
      // stop the streetview marker from being draggable
      streetViewAvatar.marker.stopDrag();
      // stop the map from dragging
      this.map.setOptions({ gestureHandling: 'none' });
      // create new listener to keep track of mouse position
      this.addEventListener('mousemove', this.changeStreetViewPov, { capture: false });
    }
  }
  streetViewAvatarClick(e) {
    if (this.linkedStreetViewWindow) this.linkedStreetViewWindow.focus();
  }
  threeDViewAvatarDragEnd(e) {
    this.setThreeDViewPosition({
      detail: { position: { lat: e.currentTarget.marker.getPosition().lat(), lng: e.currentTarget.marker.getPosition().lng() } }
    });
  }
  closeActionDialog() {
    this.showActionDialog = false;
  }
  openActionDialog(options) {
    if (options) {
      if (options.body) {
        if (!Array.isArray(options.body)) options.body = [options.body];
        let temp = {};
        options.body.forEach((x) => (temp[x] = true));
        options.body = temp;
        // check box for arrowEndpoint properly reflects state of selected annotation
        if (options.body.annotationLeaderOptions && options.annotationData) {
          if (options.annotationData.leader.endPoint) this.set('actionDialogData.arrowEndpoint', true);
          else this.set('actionDialogData.arrowEndpoint', false);
        }
      }
      if (options.buttons) options.buttons.forEach((x) => (x.key = FirebaseWorker.ref().push().key));
    }
    //extra detail for photo association
    this.associationStarCheck = false; //start checkbox as unchecked
    if (options.associationCheckbox) this.associationCheckbox = true;
    else this.associationCheckbox = false;

    //extra detail for starring photos
    this.starFirstCheck = false; //start checkbox as unchecked
    if (options.starFirstCheckbox) this.starFirstCheckbox = true;
    else this.starFirstCheckbox = false;

    if (options.title === undefined) options.title = '$default';
    if (options.icon === undefined && options.materialIcon === undefined) options.icon = true;
    this.actionDialogCancelCallback = options.cancel;
    this.set('actionDialogOptions', options);
    if (this.actionDialogOptions.photoChooserItem) {
      let openPhotoChooser = () => {
        let photoChooser = this.$.actionDialog.querySelector('#photoChooser');
        if (photoChooser) {
          photoChooser.$.container.style = `
            background-color: rgba(225,225,225,0);
            position: absolute;
            top: 20%;
            left: 0;
            height: 75%;
            width: 100%;
            overflow: hidden;
          }`;
          photoChooser.$.slideContainer.style.paddingTop = 0;
          if (photoChooser.opened === false) photoChooser.open();
          return;
        }
        //if no photochooser, try again
        setTimeout(() => {
          openPhotoChooser();
        }, 250);
      };

      setTimeout(() => {
        openPhotoChooser();
      }, 500);
    }
    this.showActionDialog = true;
    setTimeout(() => {
      this.actionDialogTranslate = this.$.actionDialog.offsetHeight + 10;
    });
  }
  chooserPhotoTap(e) {
    let jsonObj = SquashNulls(this, 'actionDialogOptions', 'jsonObj');
    if (jsonObj) e.detail.jsonObj = jsonObj;
    this.dispatchEvent(new CustomEvent('jcop-transfer', e));
  }
  scidByPoleAppOrderChanged(value) {
    if (value !== undefined) {
      if (value == true) {
        this.set('actionDialogOptions.text', 'Click any pole to order');
      } else {
        this.set('actionDialogOptions.text', 'Enter a starting SCID number, then click the first pole in your job');
      }
      // resize the action dialog
      this.actionDialogTranslate = this.$.actionDialog.offsetHeight + 10;
    }
  }
  openStreetView(e) {
    if (this.mouseup) {
      if (this.linkedStreetViewWindow == null || !this.linkedStreetViewWindow.opener || this.linkedStreetViewWindow.opener.closed) {
        if (!this.dontLinkSpawnedWindows)
          this.linkedStreetViewWindow = window.open('', 'Street View', this.dontLinkSpawnedWindows ? 'noopener,toolbar,menubar' : '');
        if (this.linkedStreetViewWindow == null || this.linkedStreetViewWindow.location.href === 'about:blank') {
          this.linkedStreetViewWindow = window.open(
            encodeURI(
              window.location.pathname
                .replace('/map/', `/street-view/${this.userGroup == '_custom_auth' ? window.location.hash : ''}`)
                .replace('/pole-application/', '/street-view/')
            ),
            'Street View',
            this.dontLinkSpawnedWindows ? 'noopener,toolbar,menubar' : ''
          ) || { opener: { closed: false }, focus: () => {} };
        } else {
          this.linkedStreetViewWindow.focus();
        }
      } else {
        this.linkedStreetViewWindow.focus();
      }
    }
  }
  setStreetViewPosition(e) {
    this.setStreetViewPositionDebouncer = Debouncer.debounce(this.setStreetViewPositionDebouncer, timeOut.after(50), () => {
      FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.uid + '/streetview_data/').update({
        position: e.detail.position,
        setBy: 'maps'
      });
    });
  }
  setStreetViewPov(e) {
    this.setStreetViewPovDebouncer = Debouncer.debounce(this.setStreetViewPovDebouncer, timeOut.after(150), () => {
      FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.uid + '/streetview_data/').update({
        pov: e.detail.pov,
        setBy: 'maps'
      });
    });
  }
  setThreeDViewPosition(e) {
    this.setThreeDViewPositionDebouncer = Debouncer.debounce(this.setThreeDViewPositionDebouncer, timeOut.after(50), () => {
      // Create an object hold data for the 3d view to use
      const threeDViewData = {
        position: e.detail.position,
        setBy: 'maps'
      };
      // Run the update
      FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.uid + '/3d_view_data/').update(
        threeDViewData
      );
    });
  }
  nodeMouseover(e, d) {
    this.dispatchEvent(new CustomEvent('item-mouseover', { detail: d }));
  }
  nodeMouseout(e) {
    this.dispatchEvent(new CustomEvent('item-mouseout'));
  }
  selectNode(e) {
    if (this.activeCommand == '$moveAnchor') return;
    // Allow node selection if the models are charter and the user is using a reference button
    let allowNodeSelection = this.isCharterModels() && this.isReferenceButton();
    // only allow a node to be selected there is no active command, or if there is an active command and the node is not a reference or job layer
    if (this.activeCommand == null || allowNodeSelection == true || (this.activeCommand != null && e.detail.jobId == this.jobId)) {
      this.showZoomToLocationMarker = false;
      // only change the selectedNode if the node is not a map layer
      if (e.detail.jobId.indexOf('__ref') != 0) {
        this.activeConnection = null;
        this.activeSection = null;
      }
      var clickedNode = SquashNulls(e, 'detail', 'key');
      var path = null;
      var geoData = e.detail.geoData || SquashNulls(this.$.loadRenderMap.pointLocations[clickedNode], 'data');
      let actionTaken = false;

      let clickedNodeJobId = SquashNulls(loadRenderMap, 'pointLocations', clickedNode, 'jobId');

      if ((this.readOnly || e.detail.jobId != this.jobId) && allowNodeSelection !== true) {
        // only change the selectedNode if the node is not a map layer
        if (e.detail.jobId.indexOf('__ref') != 0) {
          this.editingItemJob = e.detail.jobId;
          this.editingNode = clickedNode;
          this.editing = 'Node';
        }
      } else if (this.activeCommand == '_multiSelectItems') {
        actionTaken = true;
        // Get the index of the selected node in multiSelectClickedNodes
        var selectedNodeIndex = this.multiSelectClickedNodes.indexOf(e.detail.key);
        // Add or remove the node
        if (selectedNodeIndex == -1) {
          this.push('multiSelectClickedNodes', e.detail.key);
          this.multiSelectNodeStyles[e.detail.key] = this.$.loadRenderMap.pointLocations[e.detail.key].data;
          let connections = this.$.loadRenderMap.pointLocations[e.detail.key].data.n;
          for (let key in connections) {
            this.multiSelectConnectionStyles[key] = this.$.loadRenderMap.lineLocations[key];
          }
        } else {
          this.splice('multiSelectClickedNodes', selectedNodeIndex, 1);
          this.multiSelectNodeStyles[e.detail.key] = null;
          let connections = this.$.loadRenderMap.pointLocations[e.detail.key].data.n;
          for (let key in connections) {
            this.multiSelectConnectionStyles[key] = null;
          }
        }
      } else if (
        SquashNulls(this.activeCommandModel, 'connection', 'location_1') == 'target' ||
        SquashNulls(this.activeCommandModel, 'connection', 'location_2') == 'target' ||
        (SquashNulls(this.activeCommandModel, 'connection', 'polyline') && !this.enterNextPolylinePoint)
      ) {
        actionTaken = true;
        if (clickedNode && this.selectedNode && this.selectedNode != clickedNode) {
          // Fetch the selected node geohash since we can't rely on it being in load render map
          let selectedNode = this.selectedNode;
          let selectedNodeLatitude = this.selectedNodeLatitude;
          let selectedNodeLongitude = this.selectedNodeLongitude;
          let dataPaths = [`geohash/${selectedNode}`];
          GetJobData(this.jobId, dataPaths).then((data) => {
            var selectedNodeGeoData = data[dataPaths[0]];
            var alreadyConnected = false;
            for (var connId in selectedNodeGeoData.n) {
              if (SquashNulls(geoData.n, connId) != '' && this.activeCommand != 'measure_bearing') {
                alreadyConnected = true;
                break;
              }
            }
            if (alreadyConnected) {
              this.confirm(
                'Items already connected.',
                'These items are already connected, are you sure you want to create another connection?',
                'Create',
                'Cancel',
                'background-color:var(--secondary-color); color:var(--secondary-color-text-color);',
                null,
                () => {
                  var edit = this.createConnection(
                    clickedNode,
                    selectedNode,
                    [geoData.l[0], geoData.l[1]],
                    [selectedNodeLatitude, selectedNodeLongitude]
                  );
                  if (edit) this.editing = 'Connection';
                  this.editingNode = clickedNode;
                }
              );
            } else if (this.activeCommand != 'measure_bearing') {
              var edit = this.createConnection(
                clickedNode,
                selectedNode,
                [geoData.l[0], geoData.l[1]],
                [selectedNodeLatitude, selectedNodeLongitude]
              );
              if (edit) this.editing = 'Connection';
              this.editingNode = clickedNode;
            }
          });
        }
      } else if (this.enterNextPolylinePoint) {
        actionTaken = true;
        var newEndpoint = [geoData.l[0], geoData.l[1]];
        var breakpoints = this.enterNextPolylinePoint.points.slice(0);
        let update = {};
        update['geohash/' + this.enterNextPolylinePoint.connId + '~1/b'] = breakpoints;
        update['geohash/' + this.enterNextPolylinePoint.connId + '~2/b'] = breakpoints;
        update['connections/' + this.enterNextPolylinePoint.connId + '/breakpoints'] = breakpoints;
        update['geohash/' + this.enterNextPolylinePoint.connId + '~1/l'] = newEndpoint;
        update['geohash/' + this.enterNextPolylinePoint.connId + '~2/l2'] = newEndpoint;
        update['geohash/' + clickedNode + '/n/' + this.enterNextPolylinePoint.connId] = 1;
        update['connections/' + this.enterNextPolylinePoint.connId + '/node_id_1'] = clickedNode;
        update['geohash/' + this.enterNextPolylinePoint.endpointNodeId] = null;
        update['nodes/' + this.enterNextPolylinePoint.endpointNodeId] = null;
        this.enterNextPolylinePoint.points.unshift(newEndpoint);
        var length = this.getConnPolylineLength(
          null,
          this.enterNextPolylinePoint.points.concat([this.enterNextPolylinePoint.firstPointLocation])
        );
        update['geohash/' + this.enterNextPolylinePoint.connId + '~1/d'] = length;
        update['geohash/' + this.enterNextPolylinePoint.connId + '~2/d'] = length;
        FirebaseWorker.ref('photoheight/jobs/' + this.jobId).update(update, (err) => {
          if (err) {
            this.toast(err);
          }
          this.selectionLocationUpdated = !this.selectionLocationUpdated;
        });
        this.togglePolylineEditing(this.enterNextPolylinePoint.connId);
        this.enterNextPolylinePoint = null;
      } else if (allowNodeSelection === true) {
        if (clickedNodeJobId != this.jobId) {
          // Fetch the selected node data since we can't rely on it being in load render map
          let dataPaths = [`nodes/${this.selectedNode}`];
          GetJobData(this.jobId, dataPaths).then((data) => {
            let activeNodeData = data[dataPaths[0]];
            let activeNodeLocation = [activeNodeData.latitude, activeNodeData.longitude];
            let referenceJobId = SquashNulls(loadRenderMap, 'pointLocations', clickedNode, 'jobId');
            let referenceNodeLocation = SquashNulls(loadRenderMap, 'pointLocations', clickedNode, 'data', 'l');
            this.getRootNode().host.$.projectFolderPanel.turnOffJobLayer(clickedNodeJobId, this.getRootNode().host.projectFolder);
            InsertJobReferenceConnection(
              this.jobId,
              this.selectedNode,
              activeNodeLocation,
              referenceJobId,
              clickedNode,
              referenceNodeLocation,
              this.modelConfig,
              this.otherAttributes,
              this.jobStyles,
              this.jobCreator,
              this.activeCommand,
              this.mappingButtons,
              FirebaseWorker
            ).then((results) => {
              if (results.success) {
                this.toast(results.message);
              }
            });
          });
          return;
        }
      }

      // Set additional properties on the event detail
      e.detail.actionTaken = actionTaken;
      e.detail.type = 'node';

      this.dispatchEvent(new CustomEvent('select-item', { detail: e.detail }));
      // only change the selectedNode if the node is not a map layer
      if (e.detail.jobId.indexOf('__ref') != 0) {
        if (!['_linkMapPhotoData'].includes(this.activeCommand)) {
          if (geoData) {
            this.selectedNodeLatitude = geoData.l[0];
            this.selectedNodeLongitude = geoData.l[1];
          }
          this.selectedNode = clickedNode;
        }
      }
    }
  }
  doubleClickNode(e) {
    this.$.confirmDialog.close();
    // only change the selectedNode if the node is not a map layer
    if (e.detail.jobId.indexOf('__ref') != 0) {
      this.activeConnection = null;
      this.activeSection = null;
      let clickedNode = SquashNulls(e, 'detail', 'key') || null;
      if (this.activeCommand == null && !this.noEditButtons) {
        this.editingNode = clickedNode;
        this.selectedNode = clickedNode;
        this.editing = 'Node';
      } else this.selectNode(e);
    } else {
      this.selectNode(e);
    }
  }
  doubleClickSection(e) {
    this.$.confirmDialog.close();
    // only change the selectedNode if the section is not a map layer
    if (e.detail.jobId.indexOf('__ref') != 0) {
      let t = (this.activeConnection = (e && e.model && e.model.connKey) || null);
      if (t) t = this.activeSection = (e && e.model && e.model.sectionKey) || null;
      if (t) {
        this.editing = 'Section';
        this.selectedNode = null;
      }
    }
  }
  selectConnection(e) {
    this.showZoomToLocationMarker = false;
    // only change the activeConnection if the connection is not a map layer
    if (e.detail.jobId.indexOf('__ref') != 0) {
      var connId = e.detail.key || null;
      var selectedJob = e.detail.jobId;
      // If we are not cable tracing, or the job data doesn't match, then update active items
      if (
        (!this.cableTracing && !['_addMapLengthDimension', '_addMapAngleDimension', '_offsetLines'].includes(this.activeCommand)) ||
        selectedJob != this.jobId
      ) {
        this.activeSection = null;
        this.selectedNode = null;
        this.editingNode = null;
        this.activeConnection = connId;
        this.editingItemJob = selectedJob;
        // Set the editing type
        if (this.activeConnection) {
          this.editing = 'Connection';
        }
      }
      e.detail.type = 'connection';
    }
    this.dispatchEvent(new CustomEvent('select-item', { detail: e.detail }));
  }
  selectSection(e) {
    this.showZoomToLocationMarker = false;
    let conn = (this.activeConnection = e.detail.connId || null);
    let sec = e.detail.sectionId || null;
    let actionTaken = false;
    var selectedJob = e.detail.jobId;
    if (conn && sec) this.activeSection = sec;
    else this.activeSection = null;

    if (this.activeCommand === '_makeSectionPrimary' && selectedJob === this.jobId) {
      actionTaken = true;
      this.dispatchEvent(new CustomEvent('make-section-primary', { detail: e.detail }));
    } else if (this.activeCommand == '_multiSelectItems') {
      actionTaken = true;
      // Get the index of the selected section in multiSelectClickedSections
      var selectedSectionIndex = this.multiSelectClickedSections.indexOf(conn + ':' + sec);
      // Add or remove the node
      if (selectedSectionIndex == -1) {
        this.push('multiSelectClickedSections', conn + ':' + sec);
      } else {
        this.splice('multiSelectClickedSections', selectedSectionIndex, 1);
      }
    } else if (this.activeSection && !['_addMapLengthDimension'].includes(this.activeCommand)) {
      this.editing = 'Section';
      if (this.activeCommand != '_linkMapPhotoData') {
        this.selectedNode = null;
      }
      this.editingNode = null;
      this.editingItemJob = selectedJob;
    }
    e.detail.actionTaken = actionTaken;
    e.detail.type = 'section';
    this.dispatchEvent(new CustomEvent('select-item', { detail: e.detail }));
  }
  updateCustomMapTypes() {
    if (this.googleIsReady == true) {
      let customMapTypes = this.customMapTypes;
      if (SquashNulls(this.modelConfig, 'custom_map_base')) customMapTypes = customMapTypes.concat(this.modelConfig.custom_map_base);
      customMapTypes.forEach((mapType) => {
        let key = this.customMapTypeNameToKey(mapType.name);
        // Reassign mapTypeIds object for google maps api to pickup the change.
        this.set('mapTypeIds.' + key, mapType.name);
        this.map.mapTypes.set(key, new google.maps.StyledMapType(mapType.styles, { name: mapType.name }));
      });
    }
  }
  customMapTypeNameToKey(name) {
    return 'custom:' + name.toLowerCase().replace(' ', '_');
  }
  dragNodeStart(e) {
    const nodeId = e.detail.key;
    const geoData = e.detail.geoData;
    const [nodeLat, nodeLng] = geoData.l;
    this._dragNodeStartEventDetail = e.detail;

    if (geoData.n) {
      let path = 'nodes/' + nodeId + '/attributes/node_type';
      GetJobData(this.jobId, path).then((data) => {
        let lookForGuying = true;
        if (data[path]) {
          let nodeType = Object.values(data[path])[0];
          if (nodeType == 'existing anchor' || nodeType == 'new anchor' || nodeType == 'pushbrace') {
            lookForGuying = false;
          }
        }
        if (lookForGuying) {
          var conns = [];
          for (var connId in geoData.n) {
            conns.push('connections/' + connId + '/attributes/connection_type');
          }
          GetJobData(this.jobId, conns).then((data) => {
            let endpoints = [];
            for (var key in data) {
              if (data[key]) {
                let connType = Object.values(data[key])[0];
                if (connType == 'down guy' || connType == 'pushbrace') {
                  let connId = key.split('/')[1];
                  endpoints.push('connections/' + connId + '/node_id_' + (geoData.n[connId] == 1 ? 2 : 1));
                }
              }
            }
            GetJobData(this.jobId, endpoints).then((data) => {
              let nodeMoveRecord = [];
              let nodeLatLng = new google.maps.LatLng(nodeLat, nodeLng);
              for (var key in data) {
                if (data[key]) {
                  let connId = key.split('/')[1];
                  let line = this.$.loadRenderMap.lineLocations[connId];
                  let path = line.getPath();
                  let index = 0;
                  if (geoData.n[connId] != line.idNumber) {
                    if (line.idNumber == 1) index = index == 0 ? 0 : path.getLength() - 1;
                    else index = index == 1 ? path.getLength() - 1 : 0;
                  } else {
                    if (line.idNumber == 2) index = index == 0 ? path.getLength() - 1 : 0;
                    else index = index == 1 ? 0 : path.getLength() - 1;
                  }
                  let endPoint = line.data[index == 0 ? 'l' : 'l2'];
                  let offsetDist = google.maps.geometry.spherical.computeDistanceBetween(
                      new google.maps.LatLng(endPoint[0], endPoint[1]),
                      nodeLatLng
                    ),
                    offsetDir = google.maps.geometry.spherical.computeHeading(nodeLatLng, new google.maps.LatLng(endPoint[0], endPoint[1]));
                  nodeMoveRecord.push({
                    nodeId: data[key],
                    connId,
                    latitude: endPoint[0],
                    longitude: endPoint[1],
                    offsetDist,
                    offsetDir
                  });
                }
              }
              if (nodeMoveRecord.length > 0) {
                nodeMoveRecord.unshift({
                  nodeId,
                  latitude: nodeLat,
                  longitude: nodeLng
                });
                // We have to modify the last item added to the undoLog (the
                // push that happens right before this callback) because we need to
                // specify that the undo should also undo moved anchors
                this.set('undoLog.' + (this.undoLog.length - 1) + '.type', 'move with anchor');
                this.set('undoLog.' + (this.undoLog.length - 1) + '.nodes', nodeMoveRecord);
                this.nodeMoveRecord = nodeMoveRecord;
              }
            });
          });
        }
      });
    }
    this.nodeMoveRecord = [];
    this.push('undoLog', {
      type: 'move',
      nodeId: nodeId,
      latitude: nodeLat,
      longitude: nodeLng,
      connections: {}
    });
  }
  dragNode({ detail }) {
    const nodeId = (this.draggingNode = detail.key);
    this.moveNode(nodeId, detail, { commit: false });
  }
  moveNode(nodeId, eventDetail, { commit = true } = {}) {
    // Do nothing if job ID is not set
    if (!this.jobId) return;
    // Get the job reference
    const jobRef = FirebaseWorker.database().ref(`photoheight/jobs/${this.jobId}`);

    const update = {};
    const { lat, lng } = eventDetail.latLng.toJSON();

    const _geohashLayers = this.geohashLayers || ['geohash'];
    _geohashLayers.forEach((layer) => {
      GeofireTools.updateLocation(`${layer}/${nodeId}`, [lat, lng], 10, update);
    });

    const marker = this.$.loadRenderMap.pointLocations[nodeId];
    marker.position = eventDetail.latLng;
    if (marker.labelMarker) marker.labelMarker.position = eventDetail.latLng;

    // If this element doesn't have the geohash layer property set, or it includes "geohash", proceed
    if (this.geohashLayers == null || this.geohashLayers.includes('geohash')) {
      update[`/nodes/${nodeId}/latitude`] = lat;
      update[`/nodes/${nodeId}/longitude`] = lng;

      for (const connId in eventDetail.geoData.n) {
        const idNumber = eventDetail.geoData.n[connId];
        const line = this.$.loadRenderMap.lineLocations[connId];
        const path = line.getPath();
        let index = 0;
        if (idNumber != line.idNumber) index = path.getLength() - 1;
        if (line.idNumber == 2) index = index == 0 ? path.getLength() - 1 : 0;
        if (this.enterNextPolylinePoint) {
          // if the node we moved is the endpoint
          if (this.enterNextPolylinePoint.endpointNodeId == nodeId) this.enterNextPolylinePoint.points[0] = [lat, lng];
          // otherwise it has the be the starting node
          else this.enterNextPolylinePoint.firstPointLocation = [lat, lng];
        }
        path.dontSaveChange = true;
        path.setAt(index, new google.maps.LatLng(lat, lng));
        const length = this.getConnPolylineLength(path);
        line.data.d = length;
        line.length = length;
        update[`/geohash/${connId}~${idNumber == 1 ? 2 : 1}/l2`] = [lat, lng];
        update[`/geohash/${connId}~1/d`] = length;
        update[`/geohash/${connId}~2/d`] = length;
        GeofireTools.updateLocation(`geohash/${connId}~${idNumber}`, [lat, lng], 10, update);
        this.set(`undoLog.${this.undoLog.length - 1}.connections.${connId}`, { idNumber, sections: {} });

        // Get the ends of the connection
        const pointA = path.getAt(0).toJSON();
        const pointB = path.getAt(1).toJSON();

        // Get an accumulator for updated section IDs
        const updatedSectionIds = [];

        // Loop the section list and update positions accordingly
        if (this.$.loadRenderMap.sectionList[connId]) {
          for (const sectionId in this.$.loadRenderMap.sectionList[connId].sections) {
            // Get the section marker and its lat/lng
            const sectionMarker = this.$.loadRenderMap.pointLocations[`${connId}:${sectionId}`];
            const { lat: sectionLat, lng: sectionLng } = sectionMarker.position.toJSON();

            // Get the new position for the section marker
            const newPosition = KatapultGeometry.SnapToLine(sectionLat, sectionLng, pointA.lat, pointA.lng, pointB.lat, pointB.lng);

            // Update the section marker position on the map
            sectionMarker.position = new google.maps.LatLng(newPosition.lat, newPosition.long);

            // Add the position change to the database update
            update[`/connections/${connId}/sections/${sectionId}/latitude`] = newPosition.lat;
            update[`/connections/${connId}/sections/${sectionId}/longitude`] = newPosition.long;
            GeofireTools.updateLocation(`geohash/${connId}:${sectionId}`, [newPosition.lat, newPosition.long], 10, update);

            // Update the undo log and mark the section ID as updated
            this.set(`undoLog.${this.undoLog.length - 1}.connections.${connId}.sections.${sectionId}`, { newPosition });
            updatedSectionIds.push(sectionId);
          }
        }

        this.$.loadRenderMap.updateConnLabel(connId);

        // Get connection data from Firebase (local may be incomplete due to geohash filtering) and update sections again
        jobRef
          .child(`connections/${connId}`)
          .once('value')
          .then((snapshot) => {
            const conn = snapshot.val();
            const connsUpdate = {};

            // For each section, update the position and geohash
            for (const [sectionId, section] of Object.entries(conn.sections ?? {})) {
              const { lat: newLat, long: newLng } = KatapultGeometry.SnapToLine(
                section.latitude,
                section.longitude,
                pointA.lat,
                pointA.lng,
                pointB.lat,
                pointB.lng
              );
              // If the section has already been updated, skip it
              if (updatedSectionIds.includes(sectionId)) continue;

              // Otherwise, add the update to the connections update object
              connsUpdate[`/connections/${connId}/sections/${sectionId}/latitude`] = newLat;
              connsUpdate[`/connections/${connId}/sections/${sectionId}/longitude`] = newLng;
              GeofireTools.updateLocation(`geohash/${connId}:${sectionId}`, [newLat, newLng], 10, connsUpdate);
            }

            // If we have a model config with connection length attributes, update them as well
            if (this.modelConfig) {
              for (let key in this.modelConfig.connection_length_attributes) {
                let item = this.modelConfig.connection_length_attributes[key];
                let matches = (item.min == null || item.min <= length) && (item.max == null || item.max >= length);
                for (let prop in item.attributes) {
                  if (matches) conn.attributes[prop] = { map_added: item.attributes[prop] };
                  else delete conn.attributes[prop];
                  connsUpdate[`/connections/${connId}/attributes/${prop}`] = matches ? { map_added: item.attributes[prop] } : null;
                }
                GeofireTools.updateStyle('connections', connId, conn, connsUpdate, this.jobStyles);
              }
            }

            // Commit the update to firebase (if not suppressed)
            if (commit) jobRef.update(connsUpdate).catch((error) => this.toast(error));
          });
      }

      // If anchors are attached to the node being dragging, move the anchors too
      if (this.nodeMoveRecord?.length) {
        for (const recordIndex in this.nodeMoveRecord) {
          // Skip the first record (the "pole" node)
          if (recordIndex == '0') continue;
          const nodeMove = this.nodeMoveRecord[recordIndex];
          // Update the anchor position
          const newAnchorLatLng = google.maps.geometry.spherical.computeOffset(eventDetail.latLng, nodeMove.offsetDist, nodeMove.offsetDir);
          const marker = this.$.loadRenderMap.pointLocations[nodeMove.nodeId];
          if (marker) marker.position = newAnchorLatLng;
          const { lat: newAnchorLat, lng: newAnchorLng } = newAnchorLatLng.toJSON();
          update[`/nodes/${nodeMove.nodeId}/latitude`] = newAnchorLat;
          update[`/nodes/${nodeMove.nodeId}/longitude`] = newAnchorLng;
          GeofireTools.updateLocation(`geohash/${nodeMove.nodeId}`, [newAnchorLat, newAnchorLng], 10, update);

          // Update the connection position
          const connId = nodeMove.connId;
          const idNumber = eventDetail.geoData.n[connId];
          const line = this.$.loadRenderMap.lineLocations[connId];
          const path = line.getPath();
          let index = path.getLength() - 1;
          if (idNumber != line.idNumber) index = 0;
          if (line.idNumber == 2) index = index == 0 ? path.getLength() - 1 : 0;
          path.dontSaveChange = true;
          path.setAt(index, new google.maps.LatLng(newAnchorLat, newAnchorLng));
          const length = this.getConnPolylineLength(path);
          line.data.d = length;
          line.length = length;
          update[`/geohash/${connId}~${idNumber}/l2`] = [newAnchorLat, newAnchorLng];
          update[`/geohash/${connId}~1/d`] = length;
          update[`/geohash/${connId}~2/d`] = length;
          GeofireTools.updateLocation(`geohash/${connId}~${idNumber == 1 ? 2 : 1}`, [newAnchorLat, newAnchorLng], 10, update);
          this.$.loadRenderMap.updateConnLabel(connId);
        }
      }
    }

    // Update the halo location
    this.selectedNodeLatitude = lat;
    this.selectedNodeLongitude = lng;

    // Commit the update to firebase
    if (commit) {
      return jobRef.update(update).catch((error) => console.log('Node Move Error:', error));
    }
  }
  getConnPolylineLength(path, points) {
    if (!path) {
      for (var i = 0; i < points.length; i++) {
        points[i] = new google.maps.LatLng(points[i][0], points[i][1]);
      }
      path = new google.maps.MVCArray(points);
    }
    return Round(google.maps.geometry.spherical.computeLength(path) / 0.3048, 1);
  }
  async dragNodeEnd(e) {
    if (!this.draggingNode) return;

    // Save and unset the dragging node
    const nodeId = this.draggingNode;
    this.draggingNode = null;
    // We need to make sure the location update is allowed (we assume it is to start)
    let updateAllowed = true;

    // Check if the node is a primary pole for a reference in another job
    let shouldUpdateReference = false;
    const { lat, lng } = e.detail.latLng.toJSON();
    const nodeRef = FirebaseWorker.ref(`photoheight/jobs/${this.jobId}/nodes/${nodeId}`);
    const nodeReferenceData = await nodeRef
      .child('reference_data')
      .once('value')
      .then((s) => s.val());
    const referenceJobId = nodeReferenceData?.job_id;

    // If we have a referenced job for this node, check if the node is a primary pole
    if (nodeReferenceData?.job_id) {
      // If the node is the primary pole, we need to update the reference
      if (nodeReferenceData.primary_pole === true) shouldUpdateReference = true;

      // If the node is explicitly not the primary pole, we need to undo the move
      if (nodeReferenceData.primary_pole === false) {
        // Prevent the update from persisting
        updateAllowed = false;
        // Undo the move, remove the undo log entry, and notify the user
        this.moveNode(nodeId, this._dragNodeStartEventDetail, { commit: false });
        this.undoLog.pop();
        let otherJobName;
        try {
          otherJobName = await FirebaseWorker.ref(`photoheight/jobs/${nodeReferenceData.job_id}/name`)
            .once('value')
            .then((s) => s.val());
        } catch (err) {
          // Catch the error and ignore it because we show the user a warning below
        }
        this.toast(
          `The node you tried to move is a secondary pole for a reference in ${otherJobName ?? 'another job'}. The move has been undone.`
        );
      }
    }

    if (updateAllowed) {
      // Update the node location (wait for the update to complete)
      await this.moveNode(nodeId, e.detail);

      this.selectionLocationUpdated = !this.selectionLocationUpdated;

      // Record the move in a node attribute (if configured)
      if (this.recordNodeMoveAttribute) {
        GetJobData(this.jobId, `nodes/${nodeId}`).then((data) => {
          var update = {};
          update[`/nodes/${nodeId}/attributes/${this.recordNodeMoveAttribute}/value`] = true;
          Path.set(data, `attributes.${this.recordNodeMoveAttribute}.value`, true);
          GeofireTools.updateStyle('nodes', nodeId, data, update, this.jobStyles);
          FirebaseWorker.ref(`photoheight/jobs/${this.jobId}`).update(update);
        });
      }

      // Update the reference job if needed
      if (shouldUpdateReference) this.updateNodeLocationInOtherJob(referenceJobId, nodeId, lat, lng);
    }

    // Clear the node move record and fire the drag end event
    this.nodeMoveRecord = [];
    this.dispatchEvent(new CustomEvent('drag-node-end', { detail: { nodeId } }));

    if (this.cancelAfterDrag) {
      this.dispatchEvent(new CustomEvent('cancel'));
      this.cancel();
    }
  }

  async updateNodeLocationInOtherJob(otherJobId, otherNodeId, latitude, longitude) {
    let update = {};

    // Fetch all connection and node data needed from other job
    let otherNodeData = await FirebaseWorker.ref(`photoheight/jobs/${otherJobId}/nodes/${otherNodeId}`)
      .once('value')
      .then((s) => s.val());
    let otherNodeGeohashConnections = await FirebaseWorker.ref(`photoheight/jobs/${otherJobId}/geohash/${otherNodeId}/n`)
      .once('value')
      .then((s) => s.val());

    // Build lookups of the connection and node data for everything connected to the node
    let otherNodeConnections = {};
    let connectedNodes = {};
    for (let connId in otherNodeGeohashConnections) {
      otherNodeConnections[connId] = await FirebaseWorker.ref(`photoheight/jobs/${otherJobId}/connections/${connId}`)
        .once('value')
        .then((s) => s.val());
      let connectedNodeId =
        otherNodeConnections[connId].node_id_1 == otherNodeId
          ? otherNodeConnections[connId].node_id_2
          : otherNodeConnections[connId].node_id_1;
      connectedNodes[connectedNodeId] = await FirebaseWorker.ref(`photoheight/jobs/${otherJobId}/nodes/${connectedNodeId}`)
        .once('value')
        .then((s) => s.val());
    }
    // Add the node itself to the object for the connection lookup
    connectedNodes[otherNodeId] = otherNodeData;

    let connectionLookup = GetConnectionLookup(connectedNodes, otherNodeConnections);
    let nodeConnections = connectionLookup[otherNodeId];

    if (otherNodeData) {
      UpdateNodeLocation(otherJobId, otherNodeId, otherNodeData, latitude, longitude, nodeConnections, connectedNodes, update);
      FirebaseWorker.ref(`photoheight/jobs/${otherJobId}`).update(update);
    }
  }
  moveSection(connId, sectionId, eventDetail, { commit = true } = {}) {
    const { lat: dragLat, lng: dragLng } = eventDetail.latLng.toJSON();
    const update = {};
    const conn = this.$.loadRenderMap.lineLocations[connId];
    const { lat, long: lng } = KatapultGeometry.SnapToLine(
      dragLat,
      dragLng,
      conn.data.l[0],
      conn.data.l[1],
      conn.data.l2[0],
      conn.data.l2[1]
    );
    eventDetail.marker.position = new google.maps.LatLng(lat, lng);

    if (commit) {
      update[`/connections/${connId}/sections/${sectionId}/latitude`] = lat;
      update[`/connections/${connId}/sections/${sectionId}/longitude`] = lng;
      GeofireTools.updateLocation(`geohash/${connId}:${sectionId}`, [lat, lng], 10, update);
      FirebaseWorker.ref(`photoheight/jobs/${this.jobId}`).update(update);
    }

    // Update the halo location
    this.selectedNodeLatitude = lat;
    this.selectedNodeLongitude = lng;
  }
  dragSection(e) {
    const update = {};
    const { connId, sectionId } = e.detail;
    this.draggingNode = sectionId;
    this.moveSection(connId, sectionId, e.detail, { commit: false });
  }
  dragSectionEnd(e, d) {
    const { connId, sectionId } = e.detail;
    this.moveSection(connId, sectionId, e.detail);
    this.selectionLocationUpdated = !this.selectionLocationUpdated;
    if (this.cancelAfterDrag) {
      this.dispatchEvent(new CustomEvent('cancel'));
      this.cancel();
    }
  }
  createConnection(node_id_1, node_id_2, l1, l2, dontAddLookupForNode1, update) {
    var edit = false;
    // Node_id_1 was just clicked, so it is definitely in this job (and potentially not created yet), but node_id_2 was a previously existing node, so we need to make sure it's from this job
    var commit = false;
    if (!update) {
      update = {};
      commit = true;
    }
    var button = this.activeCommandModel;
    if (button.connection != null) {
      let attributes = [];
      for (var property in button.connection.attributes) {
        if (button.connection.attributes[property].value != null) {
          let key = 'button_added';
          if (SquashNulls(this.otherAttributes, property, 'gui_element') == 'table')
            key = FirebaseWorker.ref(`photoheight/jobs/${this.jobId}/nodes`).push().key;
          attributes.push({
            attribute: property,
            key,
            value: button.connection.attributes[property].value
          });
        }
      }
      let node1 = { latitude: l1[0], longitude: l1[1] };
      let node2 = { latitude: l2[0], longitude: l2[1] };
      var connectionResults = DataLayer.Connections.create(node_id_1, node1, node_id_2, node2, this.otherAttributes, FirebaseWorker, {
        jobStyles: this.jobStyles,
        method: 'desktop',
        button: this.activeCommand,
        attributes,
        connection_length_attributes: SquashNulls(this.modelConfig, 'connection_length_attributes'),
        update
      });
      var conn = connectionResults.data;
      if (conn.node_id_1 != null && conn.node_id_2 != null && conn.node_id_1 != conn.node_id_2) {
        this.activeConnection = connectionResults.key;
        if (commit) {
          FirebaseWorker.ref('photoheight/jobs/' + this.jobId).update(update, (err) => {
            if (err) {
              this.toast(err);
            }
          });
        }
        edit = button.connection.open_for_editing;
        if (button.connection.open_for_tracing == true) {
          // Turn on cable tracing
          this.cableTracing = true;
          this.toast(`Continue in Photos to trace the connection.`);
          this.cancelPromptAction();
          setTimeout(() => {
            // Tell the maps element that we clicked on the connection
            let customEventDetail = {
              key: this.activeConnection,
              jobId: this.jobId,
              type: 'connection'
            };
            this.dispatchEvent(new CustomEvent('select-item', { detail: customEventDetail }));
          }, 1);
        }
      }
    }
    return edit;
  }
  calcRubberBandStyle(mLat, mLng, nLat, nLng, metric) {
    if (window.google?.maps?.geometry) {
      // let length = window.google.maps.geometry.spherical.computeDistanceBetween(new window.google.maps.LatLng(mLat, mLng), new window.google.maps.LatLng(nLat, nLng));
      let angle = window.google.maps.geometry.spherical.computeHeading(
        new window.google.maps.LatLng(mLat, mLng),
        new window.google.maps.LatLng(nLat, nLng)
      );
      let mPointPixels = this.getPixelCoord(mLat, mLng);
      let nPointPixels = this.getPixelCoord(nLat, nLng);
      let length = Math.sqrt(Math.pow(mPointPixels.x - nPointPixels.x, 2) + Math.pow(mPointPixels.y - nPointPixels.y, 2));
      let style = `top: ${nPointPixels.y}px; left: ${nPointPixels.x}px; height: ${length}px; transform: rotate(${angle}deg);`;
      return style;
    }
  }
  calcRubberAngle(lat, lng, sel) {
    if (!lat || !lng || !this.selectedNodeLatitude || !this.selectedNodeLongitude) return null;
    return Round(
      (google.maps.geometry.spherical.computeHeading(
        new google.maps.LatLng(this.selectedNodeLatitude, this.selectedNodeLongitude),
        new google.maps.LatLng(lat, lng)
      ) +
        360) %
        360,
      1
    );
  }
  calcRubberLength(lat, lng, sel) {
    if (!lat || !lng || !this.selectedNodeLatitude || !this.selectedNodeLongitude) return null;
    let dist = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(this.selectedNodeLatitude, this.selectedNodeLongitude),
      new google.maps.LatLng(lat, lng)
    );
    return Round(dist / (this.useMetricUnits ? 1 : 0.3048), 1);
  }
  rubberBandAngleChanged() {
    if (!this.lockedGeometryAngle) this.snappingAngle = this.rubberBandAngle;
  }
  rubberBandLengthChanged() {
    if (!this.lockedGeometryLength) this.snappingLength = this.rubberBandLength;
  }
  lineByKnownLengthKeybinds(e) {
    if (this.activeCommand != null) {
      if (e.keyCode == 65) {
        //a
        this.shadowRoot.querySelector('#inputAngle').focus();
        e.preventDefault();
      } else if (e.keyCode == 68) {
        //d
        this.shadowRoot.querySelector('#inputLength').focus();
        e.preventDefault();
      } else if (e.keyCode == 85 || e.keyCode == 27) {
        //u or esc
        if (document.activeElement.id == 'input') {
          this.lockedGeometryLength = false;
          this.lockedGeometryAngle = false;
          e.preventDefault();
        }
      }
    }
  }
  getLockIcon(bool) {
    if (bool) return 'lock-outline';
    return 'lock-open';
  }
  lockGeometry(e) {
    this.dispatchEvent(new CustomEvent('highlight-conn', { detail: null }));
    let tar = (e && e.currentTarget && e.currentTarget.id) || null;
    if (tar == 'inputLength') this.lockedGeometryLength = true;
    else if (tar == 'inputAngle') {
      this.lockedGeometryAngle = true;
      // Clear the button styles
      this._lastClickedAngleButton = null;
    }
  }
  unlockGeometry(e) {
    this.dispatchEvent(new CustomEvent('highlight-conn', { detail: null }));
    if (e.currentTarget.getAttribute('name') === 'angle') {
      this.lockedGeometryAngle = false;
      // Clear the button styles
      this._lastClickedAngleButton = null;
    }
    if (e.currentTarget.getAttribute('name') === 'length') this.lockedGeometryLength = false;
  }
  async toggleBisectorInline(e) {
    const buttonAction = e.currentTarget.dataset.action;
    const isFirstClick = this._lastClickedAngleButton != buttonAction || this._lastSelectedNodeForBearings != this.selectedNode;
    this._lastClickedAngleButton = buttonAction;
    this._lastSelectedNodeForBearings = this.selectedNode;

    switch (buttonAction) {
      case 'bisect': {
        // If we're clicking the bisector button for the first time on this item, get the bearings
        if (isFirstClick) this.bisectorBearings = await this.getSnapConnectionBearings('bisector');

        // If we have bearings, set the snapping angle and highlight the connection
        if (this.bisectorBearings.length > 0) {
          // If this is not the first click, increment the index (and loop back to 0 if we're at the end)
          if (!isFirstClick) this.bisectorIndex = (this.bisectorIndex + 1) % this.bisectorBearings.length;

          this.snappingAngle = this.bisectorBearings[this.bisectorIndex].angle;
          this.dispatchEvent(new CustomEvent('highlight-conn', { detail: this.bisectorBearings[this.bisectorIndex].conn }));
        }
        break;
      }
      case 'inline': {
        // If we're clicking the inline button for the first time on this item, get the bearings
        if (isFirstClick) this.inlineBearings = await this.getSnapConnectionBearings('inline');

        // If we have bearings, set the snapping angle and highlight the connection
        if (this.inlineBearings.length > 0) {
          // If this is not the first click, increment the index (and loop back to 0 if we're at the end)
          if (!isFirstClick) this.inlineIndex = (this.inlineIndex + 1) % this.inlineBearings.length;

          this.snappingAngle = this.inlineBearings[this.inlineIndex].angle;
          this.dispatchEvent(new CustomEvent('highlight-conn', { detail: this.inlineBearings[this.inlineIndex].conn }));
        }
        break;
      }
    }

    this.lockedGeometryAngle = true;
    this.setRubberBandPosition();
  }
  async getSnapConnectionBearings(type) {
    let nodes = await FirebaseWorker.ref(`photoheight/jobs/${this.jobId}/nodes`)
      .once('value')
      .then((s) => s.val());
    let connections = await FirebaseWorker.ref(`photoheight/jobs/${this.jobId}/connections`)
      .once('value')
      .then((s) => s.val());
    let defaultPoleNodeTypes = this.modelDefaults.pole_node_types;
    let defaultReferenceNodeTypes = this.modelDefaults.reference_node_types;
    return Promise.resolve().then(() => {
      var bearings = [];
      var nodeId = this.selectedNode;
      var node = SquashNulls(nodes, nodeId);
      var linkedConnections = [];
      for (let connId in connections) {
        var conn_type = PickAnAttribute(connections[connId].attributes, 'connection_type');
        if (
          (conn_type == 'aerial cable' ||
            conn_type == 'overlash' ||
            conn_type == 'slack span' ||
            conn_type == 'overhead guy' ||
            conn_type == 'pole to pole guy' ||
            conn_type == 'reference') &&
          (connections[connId].node_id_1 == nodeId || connections[connId].node_id_2 == nodeId)
        )
          linkedConnections.push(connections[connId]);
      }
      if (type == 'inline') {
        for (let j = 0; j < linkedConnections.length; j++) {
          let otherNode, node_type, theta;
          if (linkedConnections[j].node_id_1 == nodeId) otherNode = SquashNulls(nodes, linkedConnections[j].node_id_2);
          else otherNode = SquashNulls(nodes, linkedConnections[j].node_id_1);
          node_type = PickAnAttribute(SquashNulls(otherNode, 'attributes'), 'node_type');
          if (
            defaultPoleNodeTypes.includes(node_type) ||
            defaultReferenceNodeTypes.includes(node_type) ||
            node_type == 'crossover' ||
            node_type == 'midspan takeoff'
          ) {
            let p1 = new google.maps.LatLng(node.latitude, node.longitude),
              p2 = new google.maps.LatLng(otherNode.latitude, otherNode.longitude);
            theta = google.maps.geometry.spherical.computeHeading(p1, p2);
            theta = (theta + 360) % 360;
            bearings.push({ angle: Round(theta, 1), conn: { p1, p2 }, opp: false });
            if (theta < 180) bearings.push({ angle: Round(theta + 180, 1), conn: { p1, p2 }, opp: true });
            else bearings.push({ angle: Round(theta - 180, 1), conn: { p1, p2 }, opp: true });
          }
        }
        bearings.sort((a, b) => a.angle - b.angle);
        bearings.sort((a, b) => {
          if (!a.opp && b.opp) {
            return 1;
          } else if (a.opp && !b.opp) {
            return -1;
          } else {
            return 0;
          }
        });
        return bearings;
      } else if (type == 'bisector') {
        var connectionBearings = [];
        for (let j = 0; j < linkedConnections.length; j++) {
          let otherNode, node_type;
          if (linkedConnections[j].node_id_1 == nodeId) otherNode = SquashNulls(nodes, linkedConnections[j].node_id_2);
          else otherNode = SquashNulls(nodes, linkedConnections[j].node_id_1);
          node_type = PickAnAttribute(SquashNulls(otherNode, 'attributes'), 'node_type');
          if (
            defaultPoleNodeTypes.includes(node_type) ||
            defaultReferenceNodeTypes.includes(node_type) ||
            node_type == 'crossover' ||
            node_type == 'midspan takeoff'
          ) {
            let p1 = new google.maps.LatLng(node.latitude, node.longitude),
              p2 = new google.maps.LatLng(otherNode.latitude, otherNode.longitude);
            connectionBearings.push({ angle: google.maps.geometry.spherical.computeHeading(p1, p2), conn: { p1, p2 } });
          }
        }
        for (let i = 0; i < connectionBearings.length; i++) {
          for (let j = i + 1; j < connectionBearings.length; j++) {
            let theta = (connectionBearings[i].angle + connectionBearings[j].angle) / 2;
            theta = (theta + 360) % 360;
            let conn1 = connectionBearings[i].conn,
              conn2 = connectionBearings[j].conn;
            let diff1 = Math.abs(connectionBearings[i].angle - connectionBearings[j].angle);
            let diff2 = 360 - diff1;
            bearings.push({ angle: Round(theta, 1), conn: [conn1, conn2], diff: diff1 });
            if (theta < 180) bearings.push({ angle: Round(theta + 180, 1), conn: [conn1, conn2], diff: diff2 });
            else bearings.push({ angle: Round(theta - 180, 1), conn: [conn1, conn2], diff: diff2 });
          }
        }
        bearings.sort((a, b) => b.diff - a.diff);
        return bearings;
      }
    });
  }
  setRubberBandPosition() {
    // convert rubberband length to meters
    var len = this.snappingLength * (this.useMetricUnits ? 1 : 0.3048);
    var latLng = google.maps.geometry.spherical.computeOffset(
      new google.maps.LatLng(this.selectedNodeLatitude, this.selectedNodeLongitude),
      len,
      this.snappingAngle
    );
    // set mouse coords to update rubberband position
    this.mouseLat = latLng.lat();
    this.mouseLng = latLng.lng();
  }
  showSnappingBoxChanged() {
    if (this.showSnappingBox) {
      window.addEventListener('keydown', this._boundLineByKnownLengthKeybinds);
    } else {
      window.removeEventListener('keydown', this._boundLineByKnownLengthKeybinds);
    }
  }

  // Custom actions for Charter to make the nodes in any reference jobs
  // clickable if they are using a reference tool
  isCharterModels() {
    return this.jobCreator == 'charter' || this.jobCreator == 'rainbow_design_services' || this.jobCreator == 'techserv';
  }
  isReferenceButton() {
    return SquashNulls(this.activeCommandModel, 'node', 'attributes', 'node_type', 'value').toLowerCase() == 'reference';
  }
  toggleClickableReferenceJob(commandModel) {
    let isCorrectModels = this.isCharterModels();
    let isReferenceButton = this.isReferenceButton();
    if (isCorrectModels) {
      // Make all point locations outside of the current job clickable
      for (let locationKey in this.$.loadRenderMap.pointLocations) {
        let pointLocation = this.$.loadRenderMap.pointLocations[locationKey];
        // If the node is not part of the current job, make it draggable and clickable anyway
        if (pointLocation.jobId != this.jobId) {
          if (commandModel && isReferenceButton) {
            pointLocation.draggable = true;
            pointLocation.clickable = true;
          } else {
            // Revert mouse actions for item
            pointLocation.draggable = false;
            pointLocation.clickable = this.clickableNodes(this.activeCommand);
          }
        }
      }
    }
  }
  /**  Returns true if activeCommand is supposed to show the rubber band polyline  */
  updateShowRubberBandPoly(lat, lng, sel, commandModel, command, tier, actions) {
    if (lat == null || lng == null || sel == null) this.showRubberBandPoly = false;
    else {
      const isConnCommandModel = !!commandModel?.connection && !commandModel?.function;
      const isLiteTier = tier == 'katapult pro lite';
      const isLinkingHardwareAngle = command == '_linkMapPhotoData' && actions?.[0]?.action_type == 'hardwareAngle';
      const isRotatingItem = ['_rotateIcon', '$moveAnchor'].includes(command);
      this.showRubberBandPoly = isConnCommandModel || isLiteTier || isLinkingHardwareAngle || isRotatingItem;
    }
    if (this.showRubberBandPoly) {
      this.showSpanDistanceInput = this.activeCommand !== '_rotateIcon';
      this.showAngleHelpers = true;
    }
  }
  /**  Returns true if activeCommand is supposed to show the rubber band dropper  */
  showRubberBandDropper(lat, lng, command, tier, actions) {
    if (lat == null || lng == null || command == null) return false;
    return (
      (this.activeCommandModel != null &&
        !this.activeCommandModel.function &&
        this.activeCommandModel.type != 'attribute' &&
        this.activeCommandModel.action != 'run_qc_modules') ||
      tier == 'katapult pro lite' ||
      (command == '_linkMapPhotoData' && actions?.[0]?.action_type == 'hardwareAngle') ||
      ['_drawPolygon', '_addMapPrintItem', '$moveAnchor'].includes(command)
    );
  }
  showSelectedIcon(selectedNode, activeSection) {
    return selectedNode || activeSection;
  }
  updateSelectedIcon(selectedNode, activeConnection, activeSection, lat, lng) {
    if (lat && lng) {
      let newSize = this.getNewSelectedOrHoverIconSize(selectedNode, `${activeConnection}:${activeSection}`);
      this.selectedIcon = Object.assign({ url: this.selectedIcon.url }, newSize);
      // Update position
      setTimeout(() => {
        this.selectedIconLatitude = lat;
        this.selectedIconLongitude = lng;
      }, 1);
    }
  }
  updateHoverIcon(node, section, lat, lng) {
    if (lat && lng) {
      let newSize = this.getNewSelectedOrHoverIconSize(node, section);
      this.hoverIcon = Object.assign({ url: this.hoverIcon.url }, newSize);
      // Update position
      this.hoverNodeLatitude = lat;
      this.hoverNodeLongitude = lng;
    }
  }
  getNewSelectedOrHoverIconSize(selectedNode, activeSection) {
    if (selectedNode || activeSection) {
      // Get the geodata for the node
      let geoData = SquashNulls(loadRenderMap, 'pointLocations', selectedNode || activeSection, 'data');
      if (geoData) {
        let iconSize = GetIconSize(geoData);
        // Increase the size of the highlight by 50% of the icon, but don't go less than 36
        let size = Math.max(iconSize.width * 1.5, 36);
        // Create new size values
        return {
          scaledSize: { width: size, height: size },
          size: { width: size, height: size },
          anchor: { x: size / 2, y: size / 2 }
        };
      }
    }
    // Otherwise return the default size
    return JSON.parse(JSON.stringify(this.defaultSelectionHoverIconSize));
  }
  computeCanDrag(readOnly, modelConfigChange, canDragOverride, notDraggable, command) {
    if (command == '_measure_map') return false;
    return (
      !readOnly &&
      !notDraggable &&
      (canDragOverride ||
        SquashNulls(this.modelConfig, 'disable_map_item_dragging') === '' ||
        SquashNulls(this.modelConfig, 'disable_map_item_dragging') === false)
    );
  }
  /**  Returns true if nodes should be clickable  */
  clickableNodes(command) {
    if (command == null) return true;
    if (command == '_measure_map') return false;
    return (
      (this.activeCommandModel != null && !!this.activeCommandModel.node) ||
      command != '_linkMapPhotoData' ||
      this.linkMapPhotoActions[0].action_type != 'hardwareAngle'
    );
  }
  /**  Returns true if connections and sections should be clickable  */
  clickableConnections(command) {
    if (command == null) return true;
    if (command == '_measure_map') return false;
    var result = false;

    // Connections should be clickable if the button exists, and isn't
    // for a connection or section, and the current command isn't trying to insert a node
    if (
      this.activeCommandModel != null &&
      !(this.activeCommandModel.connection || this.activeCommandModel.section) &&
      !SquashNulls(this.activeCommandModel, 'node', 'insert')
    ) {
      result = true;
    }
    // Connections should also be true if the command is
    if (['_makeSectionPrimary', '_addMapLengthDimension', '_addMapAngleDimension', '_offsetLines'].includes(command)) {
      result = true;
    }

    return result;
  }
  showTraceHighlight(polygonMapHighlights) {
    return polygonMapHighlights != null && polygonMapHighlights.length > 0;
  }
  undoLastAction() {
    var update = {};
    var last = this.pop('undoLog');
    if (last == null) {
      this.toast('No more actions to undo');
    } else if (last.type == 'move' || last.type == 'move with anchor') {
      var connPaths = [];
      for (var connId in last.connections) {
        connPaths.push('connections/' + connId);
      }
      GetJobData(this.jobId, connPaths).then((data) => {
        var neededPaths = [];
        var conns = {};
        // Check if there are node ids we need to load from job data
        if (last.nodes) {
          for (let i = 0; i < last.nodes.length; i++) {
            neededPaths.push('nodes/' + last.nodes[i].nodeId);
          }
        } else if (last.nodeId) {
          neededPaths.push('nodes/' + last.nodeId);
        }

        var pathLookup = {};
        for (var key in data) {
          var connId = key.replace('connections/', '');
          conns[connId] = data[key];
          var nodeIdEndpoint = data[key]['node_id_' + (last.connections[connId].idNumber == 2 ? 1 : 2)];
          if (this.$.loadRenderMap.pointLocations[nodeIdEndpoint] != null) {
            last.connections[connId].otherNodeLocation = this.$.loadRenderMap.pointLocations[nodeIdEndpoint].data.l;
          } else {
            var path = 'geohash/' + nodeIdEndpoint + '/l';
            pathLookup[path] = connId;
            neededPaths.push(path);
          }
        }
        GetJobData(this.jobId, neededPaths).then((data) => {
          for (var key in data) {
            // Check to make sure the key is for a connection
            if (key.startsWith('connections/') == true) {
              last.connections[pathLookup[key]].otherNodeLocation = data[key];
            }
          }
          var update = {};
          for (var connId in last.connections) {
            var otherLocation = last.connections[connId].otherNodeLocation;
            var updateOtherEndpoint = null;
            if (last.type == 'move with anchor') {
              for (var i = 0; i < last.nodes.length; i++) {
                if (last.nodes[i].connId == connId) {
                  otherLocation = [last.nodes[i].latitude, last.nodes[i].longitude];
                  updateOtherEndpoint = true;
                  break;
                }
              }
            }
            var idNumber = last.connections[connId].idNumber;
            var length = Round(
              google.maps.geometry.spherical.computeDistanceBetween(
                new google.maps.LatLng(last.latitude, last.longitude),
                new google.maps.LatLng(otherLocation[0], otherLocation[1])
              ) / 0.3048,
              1
            );
            if (this.modelConfig) {
              for (let key in this.modelConfig.connection_length_attributes) {
                let item = this.modelConfig.connection_length_attributes[key];
                let matches = (item.min == null || item.min <= length) && (item.max == null || item.max >= length);
                for (let prop in item.attributes) {
                  if (matches) conns[connId].attributes[prop] = { map_added: item.attributes[prop] };
                  else delete conns[connId].attributes[prop];
                  update['/connections/' + connId + '/attributes/' + prop] = matches ? { map_added: item.attributes[prop] } : null;
                }
                GeofireTools.updateStyle('connections', connId, conns[connId], update, this.jobStyles);
              }
            }
            var line = this.$.loadRenderMap.lineLocations[connId];
            if (line) {
              var path = line.getPath();
              path.setAt(idNumber == line.idNumber ? 0 : 1, new google.maps.LatLng(last.latitude, last.longitude));
              if (updateOtherEndpoint) {
                path.setAt(idNumber == line.idNumber ? 1 : 0, new google.maps.LatLng(otherLocation[0], otherLocation[1]));
              }
              line.length = length;
            }
            update['/geohash/' + connId + '~' + (idNumber == 1 ? 2 : 1) + '/l2'] = [last.latitude, last.longitude];
            update['/geohash/' + connId + '~1/d'] = length;
            update['/geohash/' + connId + '~2/d'] = length;
            GeofireTools.updateLocation('geohash/' + connId + '~' + idNumber, [last.latitude, last.longitude], 10, update);
            if (updateOtherEndpoint) {
              update['/geohash/' + connId + '~' + idNumber + '/l2'] = otherLocation;
              GeofireTools.updateLocation('geohash/' + connId + '~' + (idNumber == 1 ? 2 : 1), otherLocation, 10, update);
            }
            for (var sectionId in last.connections[connId].sections) {
              var newPosition = KatapultGeometry.SnapToLine(
                last.connections[connId].sections[sectionId].newPosition.lat,
                last.connections[connId].sections[sectionId].newPosition.long,
                last.latitude,
                last.longitude,
                otherLocation[0],
                otherLocation[1]
              );
              update['/connections/' + connId + '/sections/' + sectionId + '/latitude'] = newPosition.lat;
              update['/connections/' + connId + '/sections/' + sectionId + '/longitude'] = newPosition.long;
              GeofireTools.updateLocation('geohash/' + connId + ':' + sectionId, [newPosition.lat, newPosition.long], 10, update);
            }
          }
          if (last.type == 'move') {
            // Check if the nodeId is in the data loaded from the job
            if (data.hasOwnProperty('nodes/' + last.nodeId) == true) {
              update['/nodes/' + last.nodeId + '/latitude'] = last.latitude;
              update['/nodes/' + last.nodeId + '/longitude'] = last.longitude;
              GeofireTools.updateLocation('geohash/' + last.nodeId, [last.latitude, last.longitude], 10, update);
            }
          } else if (last.type == 'move with anchor') {
            for (var i = 0; i < last.nodes.length; i++) {
              var lat = last.nodes[i].latitude;
              var lng = last.nodes[i].longitude;
              update['/nodes/' + last.nodes[i].nodeId + '/latitude'] = lat;
              update['/nodes/' + last.nodes[i].nodeId + '/longitude'] = lng;
              GeofireTools.updateLocation('geohash/' + last.nodes[i].nodeId, [lat, lng], 10, update);
            }
          }
          if (this.jobId != null && this.jobId != '') {
            FirebaseWorker.ref('photoheight/jobs/' + this.jobId).update(
              update,
              function (error) {
                if (error) {
                  this.toast(error, null, 3000);
                }
              }.bind(this)
            );
          }
        });
      });
    } else if (last.type == 'delete') {
      if (last.itemType == 'connection') {
        var locationPaths = ['/geohash/' + last.item.node_id_1 + '/l', '/geohash/' + last.item.node_id_2 + '/l'];
        GetJobData(this.jobId, locationPaths).then((data) => {
          update['/connections/' + last.id] = last.item;
          GeofireTools.setGeohash('connections', last.item, last.id, this.jobStyles, update, {
            location1: data[locationPaths[0]],
            location2: data[locationPaths[1]],
            nId1: last.item.node_id_2,
            nId2: last.item.node_id_2
          });
          if (this.jobId != null && this.jobId != '') {
            FirebaseWorker.ref('photoheight/jobs/' + this.jobId).update(
              update,
              function (error) {
                if (error) {
                  this.toast(error, null, 3000);
                }
              }.bind(this)
            );
          }
        });
      } else if (last.itemType == 'node') {
        var locationPaths = [];
        for (var connId in last.connections) {
          locationPaths.push(
            '/geohash/' + last.connections[connId].node_id_1 + '/l',
            '/geohash/' + last.connections[connId].node_id_2 + '/l'
          );
        }
        GetJobData(this.jobId, locationPaths).then((data) => {
          update['/nodes/' + last.nodeId] = last.item;
          let nodeConnections = {};
          var count = 0;
          var photoPaths = [];
          var photoPathLookup = {};
          for (let connId in last.connections) {
            var isNode1 = last.connections[connId].node_id_1 == last.nodeId;
            nodeConnections[connId] = isNode1 ? 1 : 2;
            update['/connections/' + connId] = last.connections[connId];
            var l1 = isNode1 ? [last.item.latitude, last.item.longitude] : data[locationPaths[count]];
            var l2 = !isNode1 ? [last.item.latitude, last.item.longitude] : data[locationPaths[count + 1]];
            GeofireTools.setGeohash('connections', last.connections[connId], connId, this.jobStyles, update, {
              location1: l1,
              location2: l2,
              nId1: isNode1 ? null : last.connections[connId].node_id_1,
              nId2: isNode1 ? last.connections[connId].node_id_2 : null
            });
            for (let sectionId in last.connections[connId].sections) {
              GeofireTools.setGeohash('sections', last.connections[connId].sections[sectionId], connId, this.jobStyles, update, {
                sectionId
              });
              for (let photoId in last.connections[connId].sections[sectionId].photos) {
                update['/photos/' + photoId + '/associated_locations/' + connId + ':' + sectionId] = 'section';
                update['/photo_summary/' + photoId + '/associated'] = true;
                photoPaths.push('/photos/' + photoId);
                photoPathLookup[photoPaths[photoPaths.length - 1]] = connId + ':' + sectionId;
              }
            }
            count += 2;
          }
          GeofireTools.setGeohash('nodes', last.item, last.nodeId, this.jobStyles, update, { nodeConnections });
          for (let photoId in last.item.photos) {
            update['/photos/' + photoId + '/associated_locations/' + last.nodeId] = 'node';
            update['/photo_summary/' + photoId + '/associated'] = true;
            photoPaths.push('/photos/' + photoId);
            photoPathLookup[photoPaths[photoPaths.length - 1]] = last.nodeId;
          }
          if (this.jobId != null && this.jobId != '') {
            FirebaseWorker.ref('photoheight/jobs/' + this.jobId).update(
              update,
              function (error) {
                if (error) {
                  this.toast(error, null, 3000);
                } else {
                  GetJobData(this.jobId, photoPaths).then((data) => {
                    for (var path in data) {
                      if (data[path].camera_id) {
                        var hasOtherAssociation = false;
                        for (var key in data[path].associated_locations) {
                          if (key != photoPathLookup[path]) {
                            hasOtherAssociation = true;
                            break;
                          }
                        }
                        if (!hasOtherAssociation) {
                          if (data[path].folder_id) {
                            IncrementFolderCounter(this.jobId, data[path].folder_id, data[path].camera_id, this.userGroup, 'numAssociated');
                          } else {
                            FirebaseWorker.ref('photoheight/jobs/' + this.jobId + '/photo_folders')
                              .orderByChild('tags')
                              .equalTo(data[path].tags)
                              .once(
                                'value',
                                function (cameraId, s) {
                                  var folders = s.val();
                                  for (var folderId in folders) {
                                    IncrementFolderCounter(this.jobId, folderId, cameraId, this.userGroup, 'numAssociated');
                                    break;
                                  }
                                }.bind(this, data[path].camera_id)
                              );
                          }
                        }
                      }
                    }
                  });
                }
              }.bind(this)
            );
          }
        });
      } else {
        update['/connections/' + last.connId + '/sections/' + last.sectionId] = last.item;
        GeofireTools.setGeohash('sections', last.item, last.connId, this.jobStyles, update, { sectionId: last.sectionId });
        var photoPaths = [];
        var photoPathLookup = {};
        for (let photo in last.item.photos) {
          update['/photos/' + photo + '/associated_locations/' + last.connId + ':' + last.sectionId] = 'section';
          update['/photo_summary/' + photo + '/associated'] = true;
          photoPaths.push('/photos/' + photo);
          photoPathLookup[photoPaths[photoPaths.length - 1]] = last.connId + ':' + last.sectionId;
        }
        if (this.jobId != null && this.jobId != '') {
          FirebaseWorker.ref('photoheight/jobs/' + this.jobId).update(
            update,
            function (error) {
              if (error) {
                this.toast(error, null, 3000);
              } else {
                GetJobData(this.jobId, photoPaths).then((data) => {
                  for (var path in data) {
                    if (data[path].camera_id) {
                      var hasOtherAssociation = false;
                      for (var key in data[path].associated_locations) {
                        if (key != photoPathLookup[path]) {
                          hasOtherAssociation = true;
                          break;
                        }
                      }
                      if (!hasOtherAssociation) {
                        if (data[path].folder_id) {
                          IncrementFolderCounter(this.jobId, data[path].folder_id, data[path].camera_id, this.userGroup, 'numAssociated');
                        } else {
                          FirebaseWorker.ref('photoheight/jobs/' + this.jobId + '/photo_folders')
                            .orderByChild('tags')
                            .equalTo(data[path].tags)
                            .once(
                              'value',
                              function (cameraId, s) {
                                var folders = s.val();
                                for (var folderId in folders) {
                                  IncrementFolderCounter(this.jobId, folderId, cameraId, this.userGroup, 'numAssociated');
                                  break;
                                }
                              }.bind(this, data[path].camera_id)
                            );
                        }
                      }
                    }
                  }
                });
              }
            }.bind(this)
          );
        }
      }
    }
    this.selectionLocationUpdated = !this.selectionLocationUpdated;
  }
  async deleteItem(e) {
    var itemData = SquashNulls(e, 'detail', 'itemData');
    if (this.editing == 'Connection') {
      this.confirm(
        'Delete this Connection?',
        'Once deleted, this connection will not be recoverable.  Any offline modifications will not be applied.',
        'Delete',
        'Cancel',
        'min-width: 100px; background-color: var(--paper-red-500); color:white;',
        null,
        async () => {
          if (this.activeConnection != '') {
            await this.deleteConnection(this.activeConnection, itemData);
            this.editing = null;
            this.activeConnection = null;
            this.closeActionDialog();
          }
        }
      );
    } else if (this.editing == 'Node' || e == 'delete node') {
      if (this.editingNode || this.selectedNode) {
        let id = this.editingNode || this.selectedNode;
        var path = null;
        var geoData = SquashNulls(this.$.loadRenderMap.pointLocations[id], 'data');
        if (!geoData) path = 'geohash/' + id;
        GetJobData(this.jobId, path).then((data) => {
          geoData = geoData || data[path] || {};
          var connCount = geoData.n ? Object.keys(geoData.n).length : 0;
          let body = `Once deleted, this node ${
            connCount ? `and its ${connCount > 1 ? `${connCount} connections` : 'connection'}` : ''
          } will not be recoverable.  Any offline modifications will not be applied.`;
          // var body = "It can't be returned after it is deleted.";
          // if (connCount != 0) {
          //   body = '(' + connCount + ') connections will be deleted. They can\'t be returned after they are deleted.'
          // }
          this.confirm(
            'Delete this Node?',
            body,
            'Delete',
            'Cancel',
            'min-width: 100px; background-color: var(--paper-red-500); color:white;',
            null,
            function () {
              this.selectedNode = null;
              this.editing = null;
              this.editingNode = null;
              this.closeActionDialog();
              var path = null;
              if (!itemData) {
                path = 'nodes/' + id;
              }
              GetJobData(this.jobId, path).then(async (data) => {
                var node = itemData || data[path];

                // Check if the node has reference data and if so, break the connection for both nodes
                let referenceJobId = SquashNulls(node, 'reference_data', 'job_id');
                let otherJobUpdate = null;
                if (referenceJobId) {
                  otherJobUpdate = {};
                  otherJobUpdate[`${referenceJobId}/nodes/${id}/reference_data`] = null;
                }

                var update = {};
                update['/nodes/' + id] = null;
                update['/geohash/' + id] = null;
                update['/app_geohash/' + id] = null;
                this.push('undoLog', {
                  type: 'delete',
                  itemType: 'node',
                  nodeId: id,
                  item: node,
                  connections: {}
                });
                const connIds = Object.keys(geoData.n ?? {});
                await Parallel.forEach(
                  connIds,
                  async (connId, i, arr) => {
                    const selectNode = i == arr.length - 1;
                    this.deleteConnection(connId, null, id, { selectNode });
                  },
                  20
                );
                // Unassociate the photos from the node
                await UnassociatePhotoFromItem(this.jobId, 'node', id, null, node, update, null, this.userGroup, {
                  removePhotoFromItem: false
                });
                if (this.jobId != null && this.jobId != '') {
                  await FirebaseWorker.ref('photoheight/jobs/' + this.jobId).update(
                    update,
                    function (error) {
                      if (error) {
                        this.toast(error, null, 3000);
                      }
                    }.bind(this)
                  );
                  // Check if we need to do an update for the other job
                  if (otherJobUpdate) {
                    await FirebaseWorker.ref('photoheight/jobs').update(otherJobUpdate, (error) => {
                      if (error) {
                        this.toast(error, null, 3000);
                      }
                    });
                  }
                }
              });
            }.bind(this)
          );
        });
      }
    } else if (this.editing == 'Section' || e == 'delete section') {
      this.confirm(
        'Delete this Section?',
        "It can't be returned after it is deleted.",
        'Delete',
        'Cancel',
        'min-width: 100px; background-color: var(--paper-red-500); color:white;',
        null,
        function () {
          var path = null;
          if (!itemData) {
            path = 'connections/' + this.activeConnection + '/sections/' + this.activeSection;
          }
          GetJobData(this.jobId, path).then(async (data) => {
            var section = itemData || data[path];
            var update = {};
            this.push('undoLog', {
              type: 'delete',
              itemType: 'section',
              connId: this.activeConnection,
              sectionId: this.activeSection,
              item: section
            });

            update['/connections/' + this.activeConnection + '/sections/' + this.activeSection] = null;
            update['/geohash/' + this.activeConnection + ':' + this.activeSection] = null;

            // Unassociate the photos from the node
            await UnassociatePhotoFromItem(
              this.jobId,
              'section',
              this.activeSection,
              this.activeConnection,
              section,
              update,
              null,
              this.userGroup,
              { removePhotoFromItem: false }
            );

            if (this.jobId != null && this.jobId != '') {
              await FirebaseWorker.ref('photoheight/jobs/' + this.jobId).update(
                update,
                function (error) {
                  if (error) {
                    this.toast(error, null, 3000);
                  }
                }.bind(this)
              );
            }
            this.editing = null;
            this.activeConnection = null;
            this.activeSection = null;
          });
        }.bind(this)
      );
    }
  }
  async deleteConnection(connectionId, itemData, deletedWithNodeId, { selectNode = true } = {}) {
    let path = null;
    if (!itemData) {
      path = 'connections/' + connectionId;
    }
    let data = await GetJobData(this.jobId, path);
    let conn = itemData || data[path];
    if (deletedWithNodeId) {
      this.set('undoLog.' + (this.undoLog.length - 1) + '.connections.' + connectionId, conn);
      if (selectNode)
        this.selectNode({ detail: { key: conn.node_id_1 == deletedWithNodeId ? conn.node_id_2 : conn.node_id_1, jobId: this.jobId } });
    } else {
      this.push('undoLog', {
        type: 'delete',
        itemType: 'connection',
        id: connectionId,
        item: conn
      });
    }
    let update = {};
    update['/connections/' + connectionId] = null;
    update['/geohash/' + connectionId + '~1'] = null;
    update['/geohash/' + connectionId + '~2'] = null;
    update['/geohash/' + conn.node_id_1 + '/n/' + connectionId] = null;
    update['/geohash/' + conn.node_id_2 + '/n/' + connectionId] = null;

    for (let sectionId in conn.sections) {
      update['/geohash/' + connectionId + ':' + sectionId] = null;
      await UnassociatePhotoFromItem(
        this.jobId,
        'section',
        sectionId,
        connectionId,
        conn.sections[sectionId],
        update,
        null,
        this.userGroup,
        { removePhotoFromItem: false }
      );
    }

    if (this.jobId != null && this.jobId != '') {
      await FirebaseWorker.ref('photoheight/jobs/' + this.jobId).update(update, (error) => {
        if (error) {
          this.toast(error, null, 3000);
        }
      });
    }
  }

  toast(message, innerHTML, duration) {
    this.dispatchEvent(new CustomEvent('toast', { detail: { message, innerHTML, duration } }));
  }
  zoomToLocation(options) {
    options = options || {};
    if (options.longitude != null) this.longitude = this.zoomToLocationLng = options.longitude;
    if (options.latitude != null) this.latitude = this.zoomToLocationLat = options.latitude;
    if (options.zoom != null) this.zoom = options.zoom;
    else {
      if (options.minZoom != null && this.zoom < options.minZoom) this.zoom = options.minZoom;
      if (options.maxZoom != null && this.zoom > options.maxZoom) this.zoom = options.maxZoom;
    }
    if (options.markerContent != null) this.zoomToLocationContent = options.markerContent;
    if (options.showMarker != null) this.showZoomToLocationMarker = options.showMarker;
    this.set('additionalMapOptions.styles', null);
  }
  mapDragover(e) {
    e.detail.originalEvent.stopPropagation();
    e.detail.originalEvent.preventDefault();
    this.mapPanDebouncer = Debouncer.debounce(this.mapPanDebouncer, timeOut.after(30), () => {
      var closest = this.findClosest(e.detail.latLng.lat(), e.detail.latLng.lng());
      if (closest != null) {
        this.hoverItem = closest.item;
        var effectAllowed = e.detail.originalEvent.dataTransfer.effectAllowed;
        e.detail.originalEvent.dataTransfer.dropEffect = 'move' === effectAllowed || 'linkMove' === effectAllowed ? 'move' : 'copy';
      } else {
        this.hoverItem = null;
      }
    });
  }
  mapDrop(e) {
    // Stop the original event from propagating and doing default actions
    e.detail.originalEvent.stopPropagation();
    e.detail.originalEvent.preventDefault();

    // Check if the drop is clost to an item on the map
    var closest = this.findClosest(e.detail.latLng.lat(), e.detail.latLng.lng());
    if (closest != null) {
      // Set the event type and data for the dragged photo
      e.detail.eventType = 'drop-on-item';
      e.detail.dragFileItem = closest.item;

      setTimeout(() => {
        // Delay this so any lingering debounced drag events still get wiped. Its fine for the halo to last 0.5s while the photo is being uploaded
        this.hoverItem = null;
      }, 500);
    } else {
      // Pass drop event to drop handler
      e.detail.eventType = 'drop-file';
    }

    // Fire the custom event
    this.dispatchEvent(new CustomEvent('map-drop', { detail: e.detail }));
  }
  findClosest(latitude, longitude, options) {
    options = options || {};
    let point = new google.maps.LatLng(latitude || this.latitude, longitude || this.longitude);
    point = this.findPixelCoordinate(point);
    var closestNode = this.closestNode(point);
    var closestSection = this.closestSection(point);

    var tolerance = 10;
    var closest = closestNode;
    if (closestSection.distance < closestNode.distance) {
      closest = closestSection;
    }

    if (!options.absoluteClosest && closest.distance < tolerance) {
      return closest;
    }

    if (options.includeConnections) {
      var closestConnection = this.closestConnection(point);
      if (options.absoluteClosest) {
        if (closestConnection.distance < closest.distance) {
          closest = closestConnection;
        }
        return closest;
      }
      if (closestConnection.distance < tolerance) {
        return closestConnection;
      }
    }
    return null;
  }
  findPixelCoordinate(latLng) {
    var coord;
    if (latLng.x != null) {
      coord = latLng;
    } else {
      coord = this.map.getProjection().fromLatLngToPoint(latLng);
    }
    return new google.maps.Point(Math.floor(coord.x * this.pixelScale), Math.floor(coord.y * this.pixelScale));
  }
  closestNode(point) {
    let nearest = this.getNearestItem({ point, type: 'node' });
    return { item: `n${nearest.key}`, distance: nearest.dist };
  }
  closestConnection(point) {
    var closest, distance;
    for (const [key, value] of Object.entries(this.$.loadRenderMap.lineLocations ?? {})) {
      var item1 = new google.maps.LatLng(value.data.l[0], value.data.l[1]);
      var item2 = new google.maps.LatLng(value.data.l2[0], value.data.l2[1]);
      item1 = this.findPixelCoordinate(item1);
      item2 = this.findPixelCoordinate(item2);
      var pointOnLine = KatapultGeometry.SnapToLineXY(point, item1, item2); // this is in pixels
      var connDistanceVector = KatapultGeometry.CalculateVector(point, pointOnLine);
      var connDistance = KatapultGeometry.VectorNorm(connDistanceVector);
      if (closest == null || connDistance < distance) {
        closest = key;
        distance = connDistance;
      }
    }
    return { item: 'c' + closest, distance: distance };
  }
  closestSection(point) {
    let nearest = this.getNearestItem({ point, type: 'section' });
    return { item: `s${nearest.key}`, distance: nearest.dist };
  }
  getNearestItem(options) {
    options = options || {};
    let nearest = { dist: Infinity };
    if (options.point && options.type) {
      let locations = options.type === 'connection' ? this.$.loadRenderMap.lineLocations : this.$.loadRenderMap.pointLocations;
      let geomType = (nearest.geomType = options.type === 'connection' ? 'line' : 'point');
      for (let key in locations) {
        let geohash = locations[key].data;
        let dist = Infinity;
        if (geomType === 'point') {
          // Nodes don't have colons in their keys and sections are never found without one.
          if ((options.type === 'node' && key.includes(':')) || (options.type === 'section' && !key.includes(':'))) continue;
          let latLng = new google.maps.LatLng(geohash.l[0], geohash.l[1]);
          let itemPoint = this.findPixelCoordinate(latLng);
          dist = KatapultGeometry.VectorNorm(KatapultGeometry.CalculateVector(options.point, itemPoint));
        } else if (geomType === 'line') {
        }
        if (dist < nearest.dist) {
          nearest.dist = dist;
          nearest.key = key;
        }
      }
    }
    return nearest;
  }
  zoomChanged() {
    // scalar to convert from XY to pixels
    // pixel = xy * 2^zoomLevel
    this.pixelScale = 1 << this.zoom;
  }
  computeShowThreeDViewAvatar(threeDViewData, jobId) {
    const jobIdsMatch = threeDViewData?.threeDViewJobId == jobId;
    if (threeDViewData?.showAvatar && jobIdsMatch) return true;
    return false;
  }
  async setSelectedNodeIdOn3dViewUserData(selectedNode) {
    if (!this.userGroup || !this.uid || !this.showThreeDViewAvatar) return;

    await FirebaseWorker.ref(`photoheight/company_space/${this.userGroup}/user_data/${this.uid}/3d_view_data`).update({
      selectedNodeId: selectedNode ?? null,
      setBy: 'maps'
    });
  }
  setSelectedNodeFrom3dView() {
    if (
      !this.showThreeDViewAvatar ||
      !this.threeDViewData ||
      this.threeDViewData.setBy === 'maps' ||
      !this.threeDViewData.selectedNodeId ||
      this.threeDViewData.selectedNodeId === this.selectedNode
    )
      return;

    this.selectNode({ detail: { key: this.threeDViewData.selectedNodeId, jobId: this.jobId } });
    this.dispatchEvent(new CustomEvent('zoom-to-node', { detail: { nodeId: this.threeDViewData.selectedNodeId } }));
  }
}
window.customElements.define(KatapultMap.is, KatapultMap);
