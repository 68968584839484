import { PolymerElement, html } from '@polymer/polymer';
import { Path } from '../../modules/Path.js';
import { CamelCase } from '../../modules/CamelCase.js';

class AttributeSelector extends PolymerElement {
  static get template() {
    return html`
      <style>
        /* :host {} */
        [hidden] {
          display: none;
        }
        #selector {
          width: var(--width);
          height: var(--height);
        }
        .headerRow {
          background-color: rgba(0 0 0 / 6%);
          font-weight: bold;
        }
        paper-row[fill-gutters] {
          width: auto;
          margin: 0 -16px;
        }
        .triStateCheckbox {
          cursor: pointer;
          color: var(--secondary-color);
          user-select: none;
        }
        .triStateCheckbox[icon='check_box_outline_blank'] {
          color: unset;
        }
      </style>

      <paper-table id="selector">
        <paper-row hidden$="[[!showSelectAll]]" fill-gutters$="[[dialog]]" style="background-color: rgba(0 0 0 / 12%);">
          <paper-cell>(Select All)</paper-cell>
          <paper-cell right>
            <material-icon
              class="triStateCheckbox"
              icon$="[[_getSelectionIcon(totalSelection)]]"
              on-click="_cycleSelectAll"
            ></material-icon>
          </paper-cell>
        </paper-row>
        <template is="dom-repeat" items="[[attributeGroups]]" as="group" mutable-data>
          <paper-row class="headerRow" fill-gutters$="[[dialog]]">
            <paper-cell>[[group.name]]</paper-cell>
            <paper-cell icon right>
              <material-icon
                class="triStateCheckbox"
                icon$="[[_getSelectionIcon(group.selection)]]"
                on-click="_cycleSelectGroup"
              ></material-icon>
            </paper-cell>
          </paper-row>
          <template is="dom-repeat" items="[[group.attributes]]" as="attribute" mutable-data>
            <paper-row>
              <paper-cell>
                <paper-checkbox checked="[[_isSelected(attribute.$key, group.selection)]]" on-checked-changed="_updateSelectedAttributes"
                  >[[attribute.$name]]</paper-checkbox
                >
              </paper-cell>
            </paper-row>
          </template>
        </template>
      </paper-table>
    `;
  }

  static get is() {
    return 'attribute-selector';
  }

  static get properties() {
    return {
      attributeGroups: {
        type: Array,
        value: []
      },
      allAttributes: {
        type: Object,
        value: null
      },
      dialog: {
        type: Boolean,
        value: false
      },
      groupsModel: {
        type: Object,
        value: null
      },
      showSelectAll: {
        type: Boolean,
        value: true
      },
      selectedAttributes: {
        type: Array,
        value: [],
        notify: true
      },
      totalSelection: {
        type: String,
        value: 'none'
      }
    };
  }

  static get observers() {
    return ['_setAttributeGroups(allAttributes, groupsModel)'];
  }
  static get importMeta() {
    return import.meta;
  }

  ready() {
    super.ready();
  }

  _cycleSelectAll(e) {
    // If some or no attributes are selected, select them all
    if (this.totalSelection == 'some' || this.totalSelection == 'none') {
      this.selectedAttributes = Object.keys(this.allAttributes);
      this.totalSelection = 'all';
    }
    // If they are all selected, remove them all
    else if (this.totalSelection == 'all') {
      this.selectedAttributes = [];
      this.totalSelection = 'none';
    }
    // Loop through the groups and update their selection properties
    for (const index in this.attributeGroups) this.set(`attributeGroups.${index}.selection`, this.totalSelection);
  }

  _cycleSelectGroup(e) {
    // Get a reference to this group's attributes array
    let _attributeGroup = e.model.group.attributes.map((a) => a.$key);
    // If some or no attributes are selected, select them all
    if (e.model.group.selection == 'some' || e.model.group.selection == 'none') {
      let attributesToAdd = [];
      _attributeGroup.forEach((a) => !this.selectedAttributes.includes(a) && attributesToAdd.push(a));
      this.selectedAttributes = [...this.selectedAttributes, ...attributesToAdd];
      this.set(`attributeGroups.${e.model.index}.selection`, 'all');
    }
    // If they are all selected, remove them all
    else if (e.model.group.selection == 'all') {
      let attributesToRemove = [];
      this.selectedAttributes.forEach((a) => _attributeGroup.includes(a) && attributesToRemove.push(a));
      this.selectedAttributes = this.selectedAttributes.filter((a) => !attributesToRemove.includes(a));
      this.set(`attributeGroups.${e.model.index}.selection`, 'none');
    }
    // Update total selection descriptor
    this._updateTotalSelection();
  }

  _getSelectionIcon(selection) {
    switch (selection) {
      case 'all':
        return 'check_box';
      case 'some':
        return 'indeterminate_check_box';
      case 'none':
        return 'check_box_outline_blank';
    }
  }

  _isSelected(attribute) {
    return this.selectedAttributes.includes(attribute);
  }

  _setAttributeGroups() {
    // If attributes is null, clear the groups list
    if (!this.allAttributes) {
      this.attributeGroups = [];
      return;
    }
    // Otherwise, group and sort the attributes
    let attributesArray = Object.values(this.allAttributes);
    let _attributeGroups = [];
    for (const attribute of attributesArray) {
      let groupName = attribute.grouping || 'Other';
      let groupIndex = _attributeGroups.findIndex((group) => group.name == groupName);
      if (groupIndex == -1) groupIndex = _attributeGroups.push({ name: groupName, attributes: [], selection: 'none' }) - 1;
      // If the group doesn't have an order yet, add it
      if (_attributeGroups[groupIndex].order == undefined) {
        let order = Path.get(this.groupsModel, `${groupName}.priority`);
        if (order == undefined) order = Infinity;
        _attributeGroups[groupIndex].order = order;
      }
      // Add a priority if the attribute doesn't already have one
      if (attribute.priority == undefined) attribute.priority == Infinity;
      // Add the new item to the group's list of attributes
      _attributeGroups[groupIndex].attributes.push(Object.assign(attribute, { $name: CamelCase(attribute.$key) }));
    }
    // Sort the groups and their attributes
    _attributeGroups.sort((a, b) => a.order - b.order);
    for (const group of _attributeGroups) group.attributes.sort((a, b) => parseFloat(a.priority) - parseFloat(b.priority));
    // Set attribute groups
    this.attributeGroups = _attributeGroups;
  }

  _updateSelectedAttributes(e) {
    let groupIndex = e.model.parentModel.index;
    // If we are deselecting
    if (e.detail.value === false && this.selectedAttributes.includes(e.model.attribute.$key)) {
      this.splice(
        'selectedAttributes',
        this.selectedAttributes.findIndex((key) => key == e.model.attribute.$key),
        1
      );
      this.set(
        `attributeGroups.${groupIndex}.selection`,
        this.attributeGroups[groupIndex].attributes.some((a) => this.selectedAttributes.includes(a.$key)) ? 'some' : 'none'
      );
    }
    // If we are selecting
    else if (e.detail.value === true && !this.selectedAttributes.includes(e.model.attribute.$key)) {
      this.push('selectedAttributes', e.model.attribute.$key);
      this.set(
        `attributeGroups.${groupIndex}.selection`,
        this.attributeGroups[groupIndex].attributes.every((a) => this.selectedAttributes.includes(a.$key)) ? 'all' : 'some'
      );
    }
    // Update total selection descriptor
    this._updateTotalSelection();
  }

  _updateTotalSelection() {
    this.totalSelection =
      this.selectedAttributes.length == 0
        ? 'none'
        : Object.keys(this.allAttributes).length == this.selectedAttributes.length
          ? 'all'
          : 'some';
  }
}

customElements.define('attribute-selector', AttributeSelector);
