/**
 * Converts an Esri SimpleMarkerSymbol style to a Google Maps Icon.
 * @param {string} esriSMSStyle - The Esri SimpleMarkerSymbol style.
 * @param {object} fillAndStroke - The fill and stroke properties of the symbol.
 * @returns {object} The Google Maps Icon object.
 */
export function esriStyleToIcon(esriSMSStyle, fillAndStroke) {
  // Get the style
  const { fillColor, fillOpacity, strokeColor, strokeOpacity, strokeWidth } = fillAndStroke;
  const style = `fill:${fillColor ?? '#000'};fill-opacity:${fillOpacity ?? '1'};stroke:${strokeColor ?? '#000'};stroke-opacity:${strokeOpacity ?? '1'};stroke-width:${strokeWidth ?? '0'};`;

  // Create the SVG
  const path = getPathFromSMSStyle(esriSMSStyle, style);
  const svg = `<svg width="30" height="30" viewBox="0 0 30 30.000001" version="1.1" id="svg1553" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"> <defs id="defs1550" /> <g id="layer1"> ${path} </g> </svg>`;

  // TODO (2024-11-21): Currently this does not support different sizes or rotation.
  return {
    anchor: { x: 15, y: 15 },
    size: { width: 30, height: 30 },
    url: `data:image/svg+xml,${encodeURIComponent(svg)}`
  };
}

/**
 * Gets the path for an Esri SimpleMarkerSymbol style.
 * @param {string} esriSMSStyle - The Esri SimpleMarkerSymbol style.
 * @param {string} pathStyle - The style of the path.
 * @returns {string} The SVG path for the style.
 */
function getPathFromSMSStyle(esriSMSStyle, pathStyle) {
  switch (esriSMSStyle) {
    case 'esriSMSCross':
      return `<path id="path1509" style="${pathStyle}" d="m 7.5000025,12.500002 h 4.9999995 v -5 H 17.5 v 5 h 4.999997 v 5 H 17.5 v 4.999996 H 12.500002 V 17.500002 H 7.5000025 Z" />`;
    case 'esriSMSDiamond':
      return `<path id="path1565" style="${pathStyle}" d="M 15.000001,22.5 20.5,15 15.000001,7.5 9.5,15 Z" />`;
    case 'esriSMSSquare':
      return `<path id="path1499" style="${pathStyle}" d="M 9,9 V 21 H 21 V 9 Z" />`;
    case 'esriSMSX':
      return `<path id="path1426" style="${pathStyle}" d="m 7.500001,7.5 v 3 L 12,15 7.500001,19.5 v 3 h 3 l 4.5,-4.499999 L 19.5,22.5 h 2.999999 v -3 L 18.000001,15 22.499999,10.5 v -3 H 19.5 l -4.499999,4.5 -4.5,-4.5 z" />`;
    case 'esriSMSTriangle':
      return `<path id="path1437" style="${pathStyle}" d="m 15,8.5 -6.5,13 h 13 z" />`;
    case 'esriSMSCircle':
    default:
      return `<path id="path1563" style="${pathStyle}" d="m 21,15 a 5.9999998,5.9999998 0 0 1 -6,6 5.9999998,5.9999998 0 0 1 -6,-6 5.9999998,5.9999998 0 0 1 6,-6 5.9999998,5.9999998 0 0 1 6,6 z" />`;
  }
}
