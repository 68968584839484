import { LitElement, html, css, nothing } from 'lit';
import '../katapult-elements/katapult-button.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';
import { HybridMarker } from '../katapult-maps-desktop/HybridMarker.js';

class MapItemEditorContent extends LitElement {
  static properties = {
    hideEditButtons: { type: Boolean },
    dragging: { type: Boolean }
  };

  static styles = css`
    :host {
      --background-color: rgba(255, 255, 255, 0.8);
      display: block;
      position: absolute;
      translate: -50% 10px;
      transition:
        transform 0.1s,
        opacity 0.1s;
      transition-timing-function: ease-out;
      transform-origin: top center;
      perspective-origin: top center;
    }

    #triangle {
      margin: auto;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent var(--background-color) transparent;
      z-index: 1;
    }

    #buttons {
      display: flex;
      background: var(--background-color);
      border-radius: 16px;
      box-shadow: var(--sl-shadow-small);
    }

    katapult-button {
      transition:
        opacity 0.1s,
        margin 0.1s;
      transition-timing-function: ease-out;
    }

    .tooltip {
      --show-delay: 1s;
    }
  `;

  constructor() {
    super();

    this.dragging = false;
    this.hideEditButtons = false;

    this.preventDragging = (e) => e.stopPropagation();
  }

  render() {
    const hideEditButtons = this.dragging || this.hideEditButtons;
    return hideEditButtons
      ? nothing
      : html`
          <div id="triangle"></div>
          <div id="buttons">
            <sl-tooltip content="Delete Item" class="tooltip" .disabled=${this.dragging}>
              <katapult-button
                icon="delete"
                noBorder
                iconOnly
                @pointerdown=${this.preventDragging}
                .callback=${this.deleteButtonCallback.bind(this)}
              ></katapult-button>
            </sl-tooltip>
            <sl-tooltip content="Move Item" class="tooltip" .disabled=${this.dragging}>
              <katapult-button icon="drag_pan" noBorder iconOnly></katapult-button>
            </sl-tooltip>
            <sl-tooltip content="Edit Item" class="tooltip" .disabled=${this.dragging}>
              <katapult-button
                icon="edit"
                noBorder
                iconOnly
                @pointerdown=${this.preventDragging}
                .callback=${this.editButtonCallback.bind(this)}
              ></katapult-button>
            </sl-tooltip>
          </div>
        `;
  }
  deleteButtonCallback(e) {
    this.dispatchEvent(new CustomEvent('delete'));
  }

  editButtonCallback(e) {
    this.dispatchEvent(new CustomEvent('edit'));
  }
}
window.customElements.define('map-item-editor-content', MapItemEditorContent);

export class MapItemEditor {
  #marker;
  #content;

  get content() {
    return this.#content;
  }

  get position() {
    return this.#marker.position;
  }
  set position(value) {
    this.#marker.position = value;
  }

  get hideEditButtons() {
    return this.#content.hideEditButtons;
  }
  set hideEditButtons(value) {
    this.#content.hideEditButtons = value;
  }

  constructor(options) {
    const { map, position, hideEditButtons, onEdit, onDelete, onDragstart, onDragend, onDrag } = options;

    this.#content = new MapItemEditorContent();
    this.#content.addEventListener('edit', onEdit);
    this.#content.addEventListener('delete', onDelete);

    this.hideEditButtons = hideEditButtons;

    this.#marker = new globalThis.google.maps.marker.AdvancedMarkerElement({
      map,
      position,
      content: this.#content,
      zIndex: 10000000,
      gmpDraggable: true
    });

    HybridMarker.AttachManualDraggingCallbacks(this.#marker, {
      dragstart: (e) => {
        this.#content.dragging = true;
        onDragstart?.(e);
      },
      drag: (e) => onDrag?.(e),
      dragend: (e) => {
        this.#content.dragging = false;
        onDragend?.(e);
      }
    });
  }

  remove() {
    this.#marker.gmpDraggable = false;
    this.hideEditButtons = true;
    setTimeout(() => (this.#marker.map = null), 2000);
  }
}
