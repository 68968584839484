import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';

import '../paper-table/paper-table';
import '../model-loader/model-loader';
import '../katapult-drop-down/katapult-drop-down';
import '../style-modules/paper-table-style';
import '../style-modules/flex';

import { DateTime } from 'luxon';
import { Path } from '../../modules/Path.js';
import { ToArray } from '../../modules/ToArray.js';
import { TraverseMarkers } from '../../modules/TraverseMarkers.js';
import { ToTitleCase } from '../../modules/ToTitleCase.js';
import { GetNewAttributeValue } from '../../modules/GetNewAttributeValue.js';

class KatapultQueryBuilder extends PolymerElement {
  static get template() {
    return html`
      <style>
        :host {
        }
        paper-spinner-lite {
          --paper-spinner-color: var(--primary-color);
        }
      </style>
      <model-loader company-id="[[companyId]]" items='["attributes"]' other-attributes="{{otherAttributes}}"></model-loader>
      <katapult-firebase-worker
        id="photos"
        path="photoheight/jobs/[[jobId]]/photos"
        data="{{photos}}"
        disabled="[[!loadPhotoData]]"
        loading="{{loadingPhotos}}"
        child-events
      ></katapult-firebase-worker>
      <katapult-firebase-worker
        id="applications"
        path="photoheight/jobs/[[jobId]]/applications"
        data="{{applications}}"
        disabled="[[!loadAppData]]"
        loading="{{loadingApps}}"
        child-events
      ></katapult-firebase-worker>

      <katapult-query-builder-group
        complex="[[complex]]"
        group="{{query}}"
        dashed$="[[equal(query.operator, 'or')]]"
        other-attributes="[[otherAttributes]]"
        applications="[[applications]]"
      >
        <template is="dom-if" if="[[or(loadingPhotos, loadingApps)]]">
          <paper-spinner-lite id="spinner" active slot="header" title="Loading Photo Data"></paper-spinner-lite>
        </template>
        <slot name="header" slot="header"></slot>
      </katapult-query-builder-group>
    `;
  }
  static get properties() {
    return {
      companyId: {
        type: String,
        value: 'ppl_attachments'
      },
      jobId: {
        type: String
      },
      complex: {
        type: Boolean,
        value: false
      },
      otherAttributes: {
        type: Object
      },
      query: {
        type: Object,
        value: () => ({
          operator: 'and',
          children: []
        }),
        notify: true
      }
    };
  }
  static get observers() {
    return ['queryChanged(query.*)'];
  }
  ready() {
    super.ready();
    // this.query = {
    //   operator: 'and',
    //   children: [
    //     {
    //       operator: 'or',
    //       children: [
    //         {
    //           attribute: 'node_type',
    //           operator: 'equals',
    //           value: 'Pole'
    //         }, {
    //           attribute: 'node_type',
    //           operator: 'equals',
    //           value: 'Pole'
    //         }
    //       ]
    //     },
    //     {
    //       attribute: 'done',
    //       operator: 'equals',
    //       value: true
    //     }, {
    //       attribute: 'height',
    //       operator: 'greater than',
    //       value: '30'
    //     }
    //   ]
    // }
  }

  /**
   * @callback getVal
   * @param {String} attr - The attribute to return a value for.
   * @returns {any|Array} - May return a single value or an array of values.
   */

  /**
   *
   * @param {getVal} getVal - A function to get the value for the current attribute.
   * @returns {boolean} - Result of the query evaluation.
   */

  equal(a, b) {
    return a == b;
  }

  or(a, b) {
    return a || b;
  }

  queryChanged() {
    let checkChildren = (group, keys) => {
      return group.some((item) => {
        return keys.includes(item.attribute) || (item.children && checkChildren(item.children, keys));
      });
    };
    this.loadPhotoData = checkChildren(this.query.children, [
      '$company_attached',
      '$company_has_make_ready',
      '$company_has_complex_MR',
      '$company_has_proposed'
    ]);
    this.loadAppData = checkChildren(this.query.children, ['$application']);
  }

  eval(getVal, getPhoto) {
    return this._eval(this.query, getVal, getPhoto);
  }

  _eval(query, getVal, getPhoto) {
    if (!(Array.isArray(query?.children) || ['and', 'or'].includes(query?.operator))) throw 'Query is malformed';
    // Remove any children with null operators.
    let children = query.children.filter((child) => child.operator != null);
    // If we have no children, return true.
    if (children.length == 0) return true;
    // Otherwise, evaluate the children.
    else
      return children[query.operator == 'and' ? 'every' : 'some']((child) => {
        // Get the child value.
        let childValue = this._special(child.value);

        if (child.children) return this._eval(child, getVal, getPhoto);
        else if (child.attribute == '$application') {
          if (childValue == '' || childValue == null) {
            return true;
          }
          let nodeId = getVal('$nodeId');
          let app = Object.values(this.applications ?? {}).find((app) => app.alias == childValue || app.app_name == childValue);
          return app?.selected_poles?.some((pole) => pole.nodeId == nodeId);
        } else if (
          child.attribute == '$company_attached' ||
          child.attribute == '$company_has_make_ready' ||
          child.attribute == '$company_has_complex_MR' ||
          child.attribute == '$company_has_proposed'
        ) {
          if (childValue == '' || childValue == null) {
            return true;
          } else {
            let type = child.attribute;

            let photoData = this.photos?.[getPhoto()]?.photofirst_data;
            let hasMR = (marker) =>
              (parseInt(marker.mr_move) || 0) != 0 ||
              marker.mr_remove ||
              marker.mr_note ||
              marker.proposed ||
              this.traces?.[marker._trace]?.proposed;
            let powerCompanyLookup = null;
            let isPowerCompany = (company) => {
              if (!powerCompanyLookup) {
                powerCompanyLookup = {};
                let powerCompanies =
                  this.otherAttributes?.company?.picklists?.power_companies || this.otherAttributes?.company?.picklists?.utility_companies;
                for (let i = 0; i < powerCompanies.length; i++) {
                  powerCompanyLookup[powerCompanies[i].value] = true;
                }
              }
              return powerCompanyLookup[company];
            };
            return TraverseMarkers(photoData, (child, path, childProperty, childItemKey, topParent) => {
              // If company matches
              let company = this.traces?.[child._trace]?.company;
              if (company == childValue) {
                if (type == '$company_attached') {
                  return true;
                } else if (type == '$company_has_proposed') {
                  return topParent.proposed || child.proposed || this.traces?.[child._trace]?.proposed;
                } else if (type == '$company_has_make_ready') {
                  return hasMR(child) || hasMR(topParent);
                } else if (type == '$company_has_complex_MR') {
                  return (
                    !child.one_touch_simple &&
                    (child.otmr_complex ||
                      child.one_touch_complex ||
                      getVal('proposed_pole_spec').filter((x) => x).length ||
                      ((path.includes('equipment') || isPowerCompany(company)) && (hasMR(child) || hasMR(topParent))))
                  );
                }
              }
            });
          }
        } else {
          // Try to find the model for this attribute.
          let model = this.get(`otherAttributes.${child.attribute}`);
          // Get the values from the getVal() function.
          let values = getVal(child.attribute);
          // If the operator is greather_than or less_than, try to case the child value to a number.
          if (['less_than', 'greater_than', 'less_than_or_equal', 'greater_than_or_equal'].includes(child.operator))
            childValue = this._cast(childValue, 'number');
          // Get the data type of the child value.
          let childType = typeof childValue;
          // Covert val into an array of values if it is not one.
          if (!Array.isArray(values)) values = [values];
          // If there are no values, inject an undefined value to exists evaluates properly.
          if (values.length == 0) values.push(undefined);
          // Pass if some of the values pass.
          let res = values.some((value) => {
            // Try to convert the value to the same type as the child value.
            value = this._cast(value, childType);
            // Additionally, if we have a model, infer the data type from the model.
            if (['date'].includes(model?.gui_element)) {
              let type = model.gui_element;
              value = this._cast(value, type);
              childValue = this._cast(childValue, type);
            }
            let res = false;
            if (child.operator == 'exists') res = value != null;
            else if (child.operator == 'equals') {
              if (child.attribute == 'pole_tag') {
                res =
                  (!childValue?.company || value?.company == childValue?.company) &&
                  (!childValue?.tagtext || value?.tagtext == childValue?.tagtext) &&
                  value?.owner == childValue?.owner;
              } else {
                res = value === childValue || ((value === '' || value == null) && (childValue === '' || childValue == null));
              }
            } else if (child.operator == 'less_than') res = value < childValue;
            else if (child.operator == 'greater_than') res = value > childValue;
            else if (child.operator == 'less_than_or_equal') res = value <= childValue;
            else if (child.operator == 'greater_than_or_equal') res = value >= childValue;
            else if (child.operator == 'includes') res = this._hasFunction(value, 'includes') && value.includes(childValue);
            else if (child.operator == 'starts_with') res = this._hasFunction(value, 'startsWith') && value.startsWith(childValue);
            else if (child.operator == 'ends_with') res = this._hasFunction(value, 'endsWith') && value.endsWith(childValue);
            else throw `Unrecognized operator: ${child.operator}`;
            return child.negated ? !res : res;
          });
          return res;
        }
      });
  }

  _cast(value, type) {
    if (type == 'number') {
      let temp = parseFloat(value);
      if (!isNaN(temp)) return temp;
    } else if (type == 'string') {
      if (typeof value?.toString == 'function') return value.toString();
    }
    if (type == 'date') {
      if (value != null) return DateTime.fromMillis(value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toMillis();
    }
    return value;
  }

  _hasFunction(x, func) {
    return typeof x?.[func] === 'function';
  }

  _special(value) {
    if (value == '$true') return true;
    if (value == '$false') return false;
    if (value == '$null') return null;
    return value;
  }
}
window.customElements.define('katapult-query-builder', KatapultQueryBuilder);

class KatapultQueryBuilderGroup extends PolymerElement {
  static get template() {
    return html`
      <style include="paper-table-style flex">
        :host {
          --border-color: rgba(0, 0, 0, 0.1);
          --group-border-color: var(--secondary-color);
        }
        :host > paper-table {
          border: 1px solid var(--border-color);
        }

        :host([depth='0']) > paper-table {
          @apply --katapult-query-builder-paper-table;
        }
        .lineContainer:hover {
          cursor: move;
        }
        .lineContainer {
          position: relative;
          align-self: stretch;
          opacity: 0.4;
          --group-border-style: solid;
          transition: border 0.1s;
        }
        :host([dashed]) .lineContainer {
          --group-border-style: dotted;
        }
        .lineContainer .horiz {
          position: absolute;
          width: 50%;
          top: 50%;
          left: 50%;
          border-bottom: 2px var(--group-border-style) var(--group-border-color);
          padding-left: 1px;
        }
        .lineContainer .vert {
          margin-left: 12px;
          width: 12px;
          border-left: 2px var(--group-border-style) var(--group-border-color);
          height: 100%;
        }
        .lineContainer[first] .vert {
          margin-top: calc(50% + 1px);
          height: 50%;
          border-top: 2px var(--group-border-style) var(--group-border-color);
          border-top-left-radius: 6px;
        }
        .lineContainer[last] .horiz {
          display: none;
        }
        .lineContainer[last] .vert {
          height: 50%;
          border-bottom: 2px var(--group-border-style) var(--group-border-color);
          border-bottom-left-radius: 6px;
        }
        :host(:hover) .lineContainer {
          border-color: red;
        }
        material-icon {
          font-size: 16pt;
        }
        .deleteButton {
          transition: all 0.1s;
          color: var(--primary-text-color-faded);
        }
        paper-table:not(:hover) .deleteButton {
          opacity: 0.5;
        }
        #operatorChooser {
          font-size: 9pt;
        }
        #operatorChooser paper-cell {
          transition:
            background 0.1s,
            color 0.1s;
        }
        #operatorChooser paper-cell[active] {
          background: var(--secondary-color);
          color: var(--secondary-color-text-color);
        }
        #addRow {
          transition: height 0.3s;
          font-size: 10pt;
          text-transform: uppercase;
          white-space: nowrap;
          color: var(--primary-text-color-faded);
          opacity: 0.4;
          transition: opacity 0.1s;
        }
        #addRow paper-cell {
          transition: background 0.1s;
        }
        #addRow paper-cell:hover {
          background: rgba(0, 0, 0, 0.06);
        }
        :host(:hover) #addRow {
          opacity: 1;
        }
        :host(:not(:hover)) #addRow {
          /* height: 0; */
        }
        paper-table {
          margin: 3px;
          margin-left: 0;
          flex-grow: 1;
        }
        :host > paper-table > paper-row:first-child {
          padding: 3px;
          padding-bottom: 0;
        }
        paper-table-scroll {
          padding: 3px;
          padding-top: 0;
        }
        katapult-drop-down,
        input-element,
        paper-input {
          margin-bottom: -2px;
          --katapult-drop-down-font-size: 10pt;
          --input-element-font-size: 10pt;
          --paper-input-container-underline: {
            display: none;
          };
          --paper-input-container-underline-focus: {
            background: var(--secondary-color);
            border-color: var(--secondary-color);
          };
          --input-element-pole-tag-text: {
            min-width: 50px;
            margin: 0 5px;
          };
        }
        paper-input {
          --paper-input-container-input: {
            font-size: 10pt;
          };
          --paper-input-container-label: {
            font-size: 10pt;
          };
        }
        .negateToggle {
          text-transform: uppercase;
          --primary-color: var(--paper-red-500);
        }
      </style>
      <paper-table rounded outline overflow-hidden style$="[[getDepthBackground(depth)]];">
        <paper-row header short>
          <div class="lineContainer" first>
            <div class="vert"></div>
          </div>
          <paper-cell style="padding-left: 0;">
            <paper-row white id="operatorChooser" overflow-hidden shrink rounded outline micro pointer>
              <paper-cell active$="[[equal(group.operator, 'and')]]" on-click="setOperator" name="and">AND</paper-cell>
              <paper-cell active$="[[equal(group.operator, 'or')]]" on-click="setOperator" name="or">OR</paper-cell>
            </paper-row>
          </paper-cell>
          <paper-cell></paper-cell>
          <slot name="header"></slot>
          <!-- <template is="dom-if" if="[[equal(depth, 0)]]">
            <paper-cell icon pointer active$="[[complex]]">
              <material-icon icon="calculate"></material-icon>
            </paper-cell>
          </template> -->
          <template is="dom-if" if="[[depth]]">
            <paper-cell class="deleteButton" icon pointer ripple on-click="deleteGroup">
              <material-icon style="font-size: 18pt;" icon="delete_sweep"></material-icon>
            </paper-cell>
          </template>
        </paper-row>
        <paper-table-scroll on-sort-changed="sortChanged">
          <template is="dom-repeat" items="{{group.children}}" as="child">
            <paper-row style$="[[getDepthBackground(depth)]];" class="childRow" slot="sortable" expand>
              <div class="lineContainer" last$="[[lastItem(index, group.children.length)]]">
                <div class="vert"></div>
                <div class="horiz"></div>
              </div>
              <!-- <paper-cell drag-handle icon>
                <material-icon icon="drag_indicator"></material-icon>
              </paper-cell> -->
              <!-- Child is a logical group -->
              <template is="dom-if" if="[[child.children]]">
                <katapult-query-builder-group
                  complex="[[complex]]"
                  depth="[[sum(depth, 1)]]"
                  group="{{child}}"
                  dashed$="[[equal(child.operator, 'or')]]"
                  on-delete="deleteChild"
                  other-attributes="[[otherAttributes]]"
                >
                </katapult-query-builder-group>
              </template>
              <!-- Child is a logical row -->
              <template is="dom-if" if="[[!child.children]]">
                <paper-table style$="[[getDepthBackground(depth, 1)]];" outline rounded overflow-hidden>
                  <paper-row short>
                    <!-- <paper-cell class="deleteButton" icon pointer ripple>
                      <material-icon icon="not_interested"></material-icon>
                    </paper-cell> -->
                    <paper-cell style="min-width: 150px;">
                      <katapult-drop-down
                        label="Attribute"
                        items="[[getAttributeList(otherAttributes)]]"
                        value="{{child.attribute}}"
                        label-function="[[titleCase]]"
                        no-label-float
                        no-clear
                      ></katapult-drop-down>
                      <!-- <input-element model="[[getModel(child.attribute, otherAttributes.*)]]"></input-element> -->
                    </paper-cell>
                    <paper-cell style="min-width: 125px;">
                      <katapult-drop-down
                        label="Operator"
                        items="[[getOperatorsList(child.negated)]]"
                        value="{{child.operator}}"
                        label-path="$label"
                        value-path="key"
                        no-label-float
                        no-clear
                        show-all-items
                        update-value-on-list-change
                      >
                        <paper-row checkbox pointer ripple>
                          <paper-cell center>
                            <paper-toggle-button class="negateToggle" checked="{{child.negated}}">Negate</paper-toggle-button>
                          </paper-cell>
                        </paper-row>
                      </katapult-drop-down>
                    </paper-cell>
                    <paper-cell style="min-width: 150px;">
                      <template is="dom-if" if="[[showInputElement(child.operator)]]">
                        <input-element
                          grow
                          model="[[getModel(child.attribute, otherAttributes.*, applications.*)]]"
                          value="{{child.value}}"
                          other-attributes="[[otherAttributes]]"
                          no-label-float
                          hide-attribute-name
                          ignore-read-only
                          job-creator="[[companyId]]"
                        ></input-element>
                      </template>
                      <template is="dom-if" if="[[showPaperInput(child.operator)]]">
                        <paper-input label="Value" value="{{child.value}}" no-label-float></paper-input>
                      </template>
                    </paper-cell>
                    <paper-cell class="deleteButton" icon pointer ripple on-click="deleteChild">
                      <material-icon icon="delete"></material-icon>
                    </paper-cell>
                  </paper-row>
                </paper-table>
              </template>
            </paper-row>
          </template>
        </paper-table-scroll>
        <paper-row id="addRow" overflow-hidden short pointer>
          <paper-cell style="padding-left: 30px;" center ripple on-click="addAttribute">
            <material-icon icon="add_circle_outline"></material-icon>
            <span>&nbsp;Add Attribute</span>
          </paper-cell>
          <paper-cell center ripple on-click="addGroup">
            <material-icon icon="playlist_add"></material-icon>
            <span>&nbsp;Add Group</span>
          </paper-cell>
        </paper-row>
      </paper-table>
    `;
  }
  static get properties() {
    return {
      complex: {
        type: Boolean,
        value: false
      },
      depth: {
        type: Number,
        value: 0,
        reflectToAttribute: true
      },
      group: {
        type: Object
      },
      operators: {
        type: Array,
        value: () => [
          {
            key: 'exists',
            label: 'Exists',
            negated_label: 'Does not Exist'
          },
          {
            key: 'equals',
            label: 'Equals',
            negated_label: 'Does not Equal'
          },
          {
            key: 'greater_than',
            label: 'Greater Than',
            negated_label: 'Not Greater Than'
          },
          {
            key: 'greater_than_or_equal',
            label: 'Greater Than Or Equal',
            negated_label: 'Not Greater Than Or Equal'
          },
          {
            key: 'less_than',
            label: 'Less Than',
            negated_label: 'Not Less Than'
          },
          {
            key: 'less_than_or_equal',
            label: 'Less Than Or Equal',
            negated_label: 'Not Less Than Or Equal'
          },
          {
            key: 'includes',
            label: 'Includes',
            negated_label: 'Does not Include'
          },
          {
            key: 'starts_with',
            label: 'Starts With',
            negated_label: 'Does not Start With'
          },
          {
            key: 'ends_with',
            label: 'Ends With',
            negated_label: 'Does Not End With'
          }
        ]
      },
      otherAttributes: {
        type: Object
      }
    };
  }
  static get observers() {
    return ['childrenChanged(group.children.*)'];
  }
  ready() {
    super.ready();
  }
  getAttributeList(otherAttributes) {
    const attributeEntries = Object.entries(otherAttributes || {});
    // Filter out attribute groups; match types if desired
    const filteredAttributeEntries = attributeEntries.filter(([key, model]) => {
      const isGroup = model.gui_element == 'group';
      const isNodeType = model.attribute_types?.includes?.('node');
      return !isGroup && isNodeType;
    });
    const attributeList = filteredAttributeEntries.map(([key, model]) => key);
    // There are some custom filters / attributes we want to provide access to
    const customAttributes = [
      '$company_attached',
      '$company_has_proposed',
      '$company_has_make_ready',
      '$company_has_complex_MR',
      '$application'
    ];
    return [...customAttributes, ...attributeList];
  }
  titleCase(x) {
    return ToTitleCase(x?.replace(/^\$+/, '') ?? x);
  }
  addAttribute() {
    this.push('group.children', { attribute: null, operator: 'equals', value: null });
  }
  addGroup() {
    this.push('group.children', { operator: 'and', children: [{ attribute: null, operator: 'equals', value: null }] });
  }
  childrenChanged(changeRecord) {
    // This will fire for all descendants, so limit to only reacting to our children.
    if (new RegExp('^group.children.[0-9]+.(attribute|operator|value)$').test(changeRecord.path)) {
      let [index, key] = Path.getWildcardValues(changeRecord.path, 'group.children.*.*');
      if (key == 'attribute') {
        this.set(
          `group.children.${index}.value`,
          changeRecord.value == 'pole_tag' ? GetNewAttributeValue(changeRecord.value, this.otherAttributes) : null
        );
        if (changeRecord.value == '') {
          this.loadPhotoData = true;
        }
      }
    }
  }
  deleteChild(e) {
    this.splice('group.children', e.model.index, 1);
  }
  deleteGroup() {
    this.dispatchEvent(new CustomEvent('delete'));
  }
  equal(a, b) {
    return a == b;
  }
  getDepthBackground(depth, additional = 0) {
    let temp = depth + additional;
    return temp % 2 ? 'background: var(--paper-grey-50);' : 'background: white;';
  }
  getModel(attr) {
    if (attr == '$application')
      return {
        gui_element: 'dropdown',
        picklists: { default: Object.values(this.applications ?? {}).map((app) => ({ value: app.alias || app.app_name })) }
      };
    if (
      attr == '$company_attached' ||
      attr == '$company_has_proposed' ||
      attr == '$company_has_make_ready' ||
      attr == '$company_has_complex_MR'
    )
      return this.otherAttributes?.company;
    return this.otherAttributes?.[attr];
  }
  getOperatorsList(negated = false) {
    return this.operators.map((x) => ({ key: x.key, $label: negated ? x.negated_label : x.label }));
  }
  lastItem(i, length) {
    return i == length - 1;
  }
  setOperator(e) {
    let operator = e.currentTarget.getAttribute('name');
    if (operator) this.set('group.operator', operator);
  }
  showInputElement(operator) {
    return !['exists', 'includes', 'starts_with', 'ends_with'].includes(operator);
  }
  showPaperInput(operator) {
    return ['includes', 'starts_with', 'ends_with'].includes(operator);
  }
  sortChanged(e) {
    let removed = this.splice('group.children', e.detail.prevIndex, 1);
    this.splice('group.children', e.detail.index, 0, removed[0]);
  }
  sum(x, y) {
    return x + y;
  }
}
window.customElements.define('katapult-query-builder-group', KatapultQueryBuilderGroup);
