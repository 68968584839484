import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { gvector } from '../../js/open-source/gvector.js';
import { esriStyleToIcon } from '../../modules/EsriStyleToIcon.js';

class APIMapLayer extends PolymerElement {
  static get template() {
    return html``;
  }

  static get is() {
    return 'api-map-layer';
  }

  static get properties() {
    return {
      apiLayers: {
        type: Object,
        value: () => {
          return {};
        }
      },
      mapIsReady: {
        type: Boolean,
        value: false
      }
    };
  }

  static get observers() {
    return ['layersChanged(layers.*)', 'mapIsReadyChanged(mapIsReady)'];
  }

  ready() {
    super.ready();
  }

  constructor() {
    super();

    this.skipSettingLayerOnNextModelUpdate = {};
    this.apiLayerModelDataListeners = {};
    this.apiLayerModelData = {};
    this.apiLayers = {};
  }

  mapIsReadyChanged(mapIsReady) {
    if (mapIsReady) {
      // Add any layers to the map if they aren't already
      for (let layerId in this.apiLayers) {
        if (!this.apiLayers[layerId]?.apiLayer?.map) {
          this.apiLayers[layerId].apiLayer.setMap(this.map);
        }
      }
    }
  }

  removeAPILayer(layerId) {
    this.turnOffAPILayerModelDataListener(layerId);
    this.apiLayers[layerId].apiLayer.setMap(null);
    delete this.apiLayers[layerId];
  }

  getLayerGeometries(layerId) {
    return this.apiLayers[layerId].apiLayer.getGeometries();
  }

  getPropertiesForLayerGeometry(layerId, geometry) {
    return this.apiLayers[layerId].apiLayer.getPropertiesForGeometry(geometry);
  }

  setLayerSelectable(layerId, selectable) {
    this.apiLayers[layerId].selectable = selectable;
    this.apiLayers[layerId].apiLayer.setClickable(selectable);
  }

  layerIsSelectable(layerId) {
    return !!this.apiLayers[layerId].selectable;
  }

  /**
   * Returns an object of layer data from two sources. The data from the layer
   * stored on the job is merged with data for the layer from the model. The
   * data from the model takes precedence. This also sets up a listener to
   * the model data and will update the layer when the model data changes.
   * @param {string} layerId The ID of the layer to get data for
   * @returns {Promise<object>} The layer data
   */
  async getLayerData(layerId) {
    const layer = structuredClone(this.layers[layerId]);

    // Check if the layer has model and key data
    const modelKey = layer?.model_key;
    const modelLayerKey = layer?.model_layer_key;
    const hasModelAndLayerKeys = modelKey != null && modelLayerKey != null;

    // If there are no model and layer keys, return the local data
    if (!hasModelAndLayerKeys) {
      return layer;
    }

    // Get a path to the layer's data in the model
    const layerModelDataPath = `${modelKey}/map_api_layers/${modelLayerKey}`;

    // If a listener does not exist, set one up to watch the layer data from the model
    if (!this.apiLayerModelDataListeners[layerModelDataPath]) {
      await new Promise(async (resolve) => {
        // Create the new listener
        const layerModelDataRef = FirebaseWorker.ref(`photoheight/company_space/${layerModelDataPath}`);
        // Check if the user can read the layer data. If not, resolve so we use local data
        const canRead = await layerModelDataRef
          .once('value')
          .then(() => true)
          .catch(() => false);
        if (!canRead) {
          resolve();
          return;
        }
        // Set up a listener for the layer data
        const layerModelDataListener = layerModelDataRef.on('value', async (s) => {
          if (!s.val()) {
            resolve();
            return;
          }
          // Save the data to the cache
          this.apiLayerModelData[layerId] = s.val();
          // If we should update the layer, then call setAPILayer
          if (!this.skipSettingLayerOnNextModelUpdate[layerId]) {
            // Set the layer with the new data
            await this.setAPILayer(layerId);
          }
          this.skipSettingLayerOnNextModelUpdate[layerId] = false;
          resolve();
        });
        // Add the listener to the list
        this.apiLayerModelDataListeners[layerModelDataPath] = {
          ref: layerModelDataRef,
          callback: layerModelDataListener
        };
      });
    }

    // Finally return the local layer data merged with the fetched model data
    return { ...layer, ...this.apiLayerModelData[layerId] };
  }

  /**
   * Turns off a listener for a layer and removes its data from the cache
   * @param {string} layerId The layer to remove the listener for
   */
  turnOffAPILayerModelDataListener(layerId) {
    const layer = this.apiLayers[layerId];
    const modelKey = layer?.model_key;
    const modelLayerKey = layer?.model_layer_key;
    const layerModelDataPath = `${modelKey}/map_api_layers/${modelLayerKey}`;
    const listener = this.apiLayerModelDataListeners[layerModelDataPath];
    if (listener) {
      listener.ref.off('value', listener.callback);
      delete this.apiLayerModelData[layerId];
      delete this.apiLayerModelDataListeners[layerModelDataPath];
    }
  }

  /**
   * Fetches the layer data from the job and model and then creates a layer with gvector
   * @param {string} layerId The ID of the layer to set
   */
  async setAPILayer(layerId) {
    // Get the layer data and merge it with the existing data
    const layerData = await this.getLayerData(layerId);
    this.apiLayers[layerId] = { ...this.apiLayers[layerId], ...layerData };

    let layerOptions = this.apiLayers[layerId]?.options || {};

    // Add defaults for missing options
    // Reference this documentation for options:
    // http://jasonsanford.github.io/google-vector-layers/documentation/#docs-symbology
    layerOptions.fields = layerOptions.fields || '*';
    layerOptions.uniqueField = layerOptions.uniqueField || 'OBJECTID';
    layerOptions.scaleRange = layerOptions.scaleRange || [13, 20];
    layerOptions.symbology = layerOptions.symbology || {
      type: 'single',
      vectorOptions: {
        strokeColor: '#000000',
        strokeOpacity: 0.8,
        strokeWeight: 4,
        fillColor: '#000000',
        fillOpacity: 0.3
      }
    };

    // TODO (2024-11-22): This is unnecessary for polygon layers, but there is no way to determine that here
    // If no style (icon) is set, default to a circle
    if (!layerOptions.symbology.style) layerOptions.symbology.style = 'esriSMSCircle';
    // If we have vector options, add the icon
    if (layerOptions.symbology.vectorOptions) {
      const { strokeWeight, ...vectorOptions } = layerOptions.symbology.vectorOptions;
      layerOptions.symbology.vectorOptions.icon = esriStyleToIcon(layerOptions.symbology.style, {
        ...vectorOptions,
        strokeWidth: strokeWeight
      });
    }

    layerOptions.esriOptions = Boolean(layerOptions.use_source_styles);
    layerOptions.map = this.map;
    layerOptions._loadingCallback = this.loadingChanged.bind(this);

    // Set the option to only have one info window
    layerOptions.singleInfoWindow = true;

    // Add custom option for clickable (default to true)
    layerOptions.clickable = this.apiLayers[layerId].selectable == null ? true : !!this.apiLayers[layerId].selectable;

    // If there already was a layer, remove it before adding a new one
    if (this.apiLayers[layerId].apiLayer) {
      this.apiLayers[layerId].apiLayer.setMap(null);
    }

    // Add an instance of the layer to the list
    this.apiLayers[layerId].apiLayer = new gvector.AGS({
      url: this.apiLayers[layerId].url,
      ...layerOptions,
      infoWindowTemplate: this.infoWindowTemplateFunction
    });
  }

  infoWindowTemplateFunction(properties) {
    let output = '<h3>Feature Properties</h3>';
    for (let prop in properties) {
      output += `<b>${prop}:</b> ${properties[prop]}<br />`;
    }
    return output;
  }

  loadingChanged(loading, layer) {
    for (let layerId in this.apiLayers) {
      if (this.apiLayers[layerId].apiLayer == layer) {
        this.dispatchEvent(
          new CustomEvent('loading-changed', { detail: { loading, loadingName: layerId }, bubbles: true, composed: true })
        );
        break;
      }
    }
  }

  async layersChanged() {
    if (!this.layers) return;

    // Loop through the apiLayers and add to the map any new layers from the layers object
    for (let layerId in this.apiLayers) {
      // If the global layer object does not have the layer any
      // more, remove it from the map and the local apiLayers object
      if (this.layers[layerId] == null) {
        this.removeAPILayer(layerId);
      }
    }

    if (!this.mapIsReady) return;

    // Loop through the layers object and add any new layers to the map
    for (let layerId in this.layers) {
      // Check that the layer is an API layer and is not already in the local list
      if (this.layers[layerId]?.type == 'API Layer') {
        if (this.apiLayers[layerId] == null) {
          // When we add the layer manually, we don't want it to update again
          // when the fetch data call sets up the first model data listener
          this.skipSettingLayerOnNextModelUpdate[layerId] = true;
          await this.setAPILayer(layerId);
        } else {
          // If the layer already exists, update the selectable state if needed
          if (this.layers[layerId].selectable != this.apiLayers[layerId].selectable) {
            this.setLayerSelectable(layerId, this.layers[layerId].selectable);
          }
        }
      }
    }
  }
}
window.customElements.define(APIMapLayer.is, APIMapLayer);
