export class GeoJSONQuery {
  constructor(map, loadingCallback, google, config) {
    this.map = map;
    this.loadingCallback = loadingCallback || function () {};
    this.google = google;
    this.layers = {};
    this.features = {};
    this.loadingItems = {};
    // Set up the web worker for the element
    this.webWorker = new Worker('/source/_resources/workers/GeoJsonQueryWorker.js', { type: 'module' });
    this.webWorker.addEventListener(
      'message',
      (e) => {
        // Check if the event data has a call attribute
        if (e.data.call) {
          // Check if the function to call is available
          if (this[e.data.call]) {
            // Call the function and apply the arguments passed in
            this[e.data.call].apply(this, e.data.args);
          }
        }
      },
      false
    );
    this.webWorker.postMessage({
      call: 'init',
      args: [config.firebaseConfigs[config.appName]]
    });
    this.google.maps.event.addListener(this.map, 'bounds_changed', this.updateGeoQuery.bind(this));
    this.updateGeoQuery();
  }
  addLayer(layerData) {
    this.layers[this.getLayerKey(layerData)] = { features: {} };
    this.webWorker.postMessage({ call: 'addLayer', args: [layerData] });
    if (this.boundsLoaded) this.updateGeoQuery();
  }
  removeLayer(layerData) {
    this.webWorker.postMessage({ call: 'removeLayer', args: [layerData] });
    var key = this.getLayerKey(layerData);
    if (this.layers[key]) {
      for (var featureId in this.layers[key].features) {
        this.removeItem(featureId);
        this.setLoading(featureId, false);
      }
      delete this.layers[key];
    }
  }
  getLayerKey(layerData) {
    return layerData.pointPath.replace(/\//g, '--');
  }
  updateGeoQuery() {
    // Check that we have a map object and bounds are accessible
    if (this.map && this.map.getBounds()) {
      this.boundsLoaded = true;
      // if (!this.projection) {
      //     this.projection = this.map.getProjection();
      // }
      let radius = this.getRadiusFromBounds();
      let zoom = this.map.getZoom();
      this.webWorker.postMessage({
        call: 'updateGeoQuery',
        dataUpdate: {
          radius,
          zoom
        }
      });
    }
  }
  getRadiusFromBounds() {
    var center = this.map.getCenter();
    return {
      center: [center.lat(), center.lng()],
      radius: this.google.maps.geometry.spherical.computeDistanceBetween(center, this.map.getBounds().getNorthEast()) / 1000
    };
  }
  setLoading(featureId, loading) {
    if (loading) {
      this.loadingItems[featureId] = true;
      this.loadingCallback(true);
    } else {
      delete this.loadingItems[featureId];
      if (Object.keys(this.loadingItems).length == 0) {
        this.loadingCallback(false);
      }
    }
  }
  renderItem(layerId, featureId, geojson) {
    if (!this.features[featureId]) {
      this.layers[layerId].features[featureId] = true;
      this.features[featureId] = this.map.data.addGeoJson(geojson);
      this.setLoading(featureId, false);
    }
  }
  removeItem(featureId) {
    if (this.features[featureId]) {
      this.features[featureId].forEach((feature) => {
        this.map.data.remove(feature);
      });
      delete this.features[featureId];
    }
  }
}
