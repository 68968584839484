import './canvas-clock-script.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';

class CanvasClock extends PolymerElement {
  static get template() {
    return html`
      <style></style>
      <canvas id="canvas" style="height: 80px; width: 260px; margin-bottom: 24px;" height="160" width="520"></canvas>
    `;
  }

  static get is() {
    return 'canvas-clock';
  }
  static get properties() {
    return {
      active: {
        type: Boolean,
        value: false,
        observer: 'activeChanged'
      },
      offscreen: {
        type: Boolean,
        value: window.OffscreenCanvas != null
      }
    };
  }
  static get observers() {
    return [];
  }
  static get importMeta() {
    return import.meta;
  }
  ready() {
    super.ready();
    if (this.offscreen) {
      this.offscreenCanvas = this.$.canvas.transferControlToOffscreen();
      // Create a worker from the clock script
      this.worker = new Worker(`/source/_resources/elements/canvas-clock/canvas-clock-script.js`);
      // Post a message to the worker to initialize it
      this.worker.postMessage({ msg: 'init', canvas: this.offscreenCanvas }, [this.offscreenCanvas]);
    } else {
      // Create an instance of the local script
      this.canvasClockScript = new CanvasClockScript();
      // Initialize the local script with the canvas
      this.canvasClockScript.init(this.$.canvas);
    }
    this.activeChanged();
  }
  activeChanged() {
    // Either post a message or call the correct function based on what type of implementation we are using
    if (this.worker) this.worker.postMessage({ msg: this.active ? 'start' : 'stop' });
    else if (this.canvasClockScript) this.canvasClockScript[this.active ? 'start' : 'stop']();
  }
}
window.customElements.define(CanvasClock.is, CanvasClock);
