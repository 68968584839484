import { GetNewAttributeValue } from './GetNewAttributeValue.js';
import { AddNodeCalls } from './AddNodeCalls.js';
import { ApplyGroupAttribute } from './ApplyGroupAttribute.js';
import { Path } from './Path.js';
import { GeneratePushID } from './GeneratePushID.js';

export function AddAttribute(property, itemAttributes, otherAttributes, options) {
  // Get the value for the attributes from the options if there is one, or get a new attribute
  let value = options.attributeValue != null ? options.attributeValue : GetNewAttributeValue(property, otherAttributes);
  // Get the key for the attribute from the options, or use a generated key
  let key = options.attributeKey || GeneratePushID();
  // Set the data in the itemAttributes object
  Path.set(itemAttributes, `${property}.${key}`, value);
  // Set any node calls
  AddNodeCalls(property, itemAttributes, otherAttributes, { newValue: value });
  // Add any extra attributes if this is a group attribute
  ApplyGroupAttribute(property, itemAttributes, otherAttributes);
  // Remove any left over call_added data
  if (itemAttributes[property]) {
    delete itemAttributes[property].call_added;
  }
}
