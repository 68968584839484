import '@polymer/polymer/polymer-legacy.js';
import './google-map-marker.js';
import { Polymer } from '@polymer/polymer/lib/legacy/polymer-fn.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
Polymer({
  _template: html`
    <style>
      :host {
      }
      span {
        visibility: hidden;
        white-space: pre;
      }
    </style>
    <span id="ruler"></span>
    <google-map-marker id="marker" slot="markers" icon="{{_icon}}" latitude="{{latitude}}" longitude="{{longitude}}" hide="{{hide}}">
    </google-map-marker>
  `,

  is: 'google-map-svg-text-marker',

  properties: {
    backgroundColor: {
      type: String,
      value: 'white'
    },
    borderRadius: {
      type: String,
      value: '0'
    },
    color: {
      type: String,
      value: 'black'
    },
    fontFamily: {
      type: String,
      value: 'Helvetica'
    },
    fontSize: {
      type: String,
      value: '12pt'
    },
    fontWeight: {
      type: String,
      value: 'normal'
    },
    latitude: {
      type: String
    },
    longitude: {
      type: String
    },
    map: {
      type: Object
    },
    spaceBetween: {
      type: String,
      value: '0'
    },
    textAlign: {
      type: String,
      value: 'left'
    },
    padding: {
      type: String,
      value: '8'
    },
    rotation: {
      type: Number,
      value: 0
    },
    text: {
      type: String
    },
    xAnchor: {
      type: String,
      value: 'center'
    },
    yAnchor: {
      type: String,
      value: 'center'
    },
    xOffset: {
      type: String,
      value: '0'
    },
    yOffset: {
      type: String,
      value: '0'
    },
    _icon: {
      type: Object,
      observer: '_iconChanged'
    },
    _strings: {
      type: Array
    },
    hide: {
      type: Boolean,
      value: false
    }
  },

  observers: [
    '_updateIcon(text, fontSize, fontFamily, fontWeight, rotation, padding, spaceBetween, xOffset, yOffset, textAlign, xAnchor, yAnchor)'
  ],

  ready: function () {},

  _updateIcon: function () {
    if (this.text && this.text != '') {
      let width = 0,
        height = 0,
        spaceBetween = parseInt(this.spaceBetween) || 0,
        padding = parseInt(this.padding) || 0,
        xOffset = parseInt(this.xOffset) || 0,
        yOffset = parseInt(this.yOffset) || 0,
        tspans = '';
      let lines = (this.text || '').split('\n');
      // Calculate final height and width of the marker.
      lines.forEach((line) => {
        let lineDimensions = this._getTextDimensions(line);
        if (lineDimensions.width > width) width = lineDimensions.width;
        height += lineDimensions.height + spaceBetween;
      });
      // May get 0 height and width if not fully loaded yet.  In this case, rerun the function.
      if (this.text && this.text.length && (!height || !width)) this.async(this._updateIcon);
      // Add double padding for top and bottom, and take one spaceBetween off of height to account for the last line adding space between height when it shouldn't have.
      height += padding * 2 - spaceBetween;
      width += padding * 2;
      // Get bounding box side lengths.  This is twice the distance between the origin and the furthest corner of the label.
      let boundingBox =
        2 *
        Math.sqrt(
          Math.pow((this.xAnchor == 'center' ? width / 2 : width) + Math.abs(xOffset) * 2, 2) +
            Math.pow((this.yAnchor == 'center' ? height / 2 : height) + Math.abs(yOffset) * 2, 2)
        );
      // Get X and Y offsets of the label relative to the bounding box.
      let xLabelOffset = 0;
      let yLabelOffset = 0;
      if (this.xAnchor == 'left') {
        xLabelOffset = boundingBox / 2 + xOffset;
      } else if (this.xAnchor == 'center') {
        xLabelOffset = (boundingBox - width) / 2 + xOffset;
      } else if (this.xAnchor == 'right') {
        xLabelOffset = boundingBox / 2 - width + xOffset;
      }
      if (this.yAnchor == 'top') {
        yLabelOffset = boundingBox / 2 - yOffset;
      } else if (this.yAnchor == 'center') {
        yLabelOffset = (boundingBox - height) / 2 - yOffset;
      } else if (this.yAnchor == 'bottom') {
        yLabelOffset = boundingBox / 2 - height - yOffset;
      }
      // Get text align and X and Y offsets relative to bounding box.
      let textAnchor = '';
      let xTextOffset = 0;
      let yTextOffset = yLabelOffset + padding;
      if (this.textAlign == 'left') {
        textAnchor = 'start';
        xTextOffset = xLabelOffset + padding;
      } else if (this.textAlign == 'center') {
        textAnchor = 'middle';
        xTextOffset = xLabelOffset + width / 2;
      } else if (this.textAlign == 'right') {
        textAnchor = 'end';
        xTextOffset = xLabelOffset + width - padding;
      }
      // Build up a string of svg <tspan> elements for each line.
      lines.forEach((line, i) => {
        let lineDimensions = this._getTextDimensions(line);
        tspans += `<tspan x="${xTextOffset}" dy="${
          i ? lineDimensions.height + spaceBetween : 0
        }" text-anchor="${textAnchor}" alignment-baseline="hanging" fill="${this.color}" font-weight="${this.fontWeight}">${line}</tspan>`;
      });
      let url = `data:image/svg+xml,
                  <svg xmlns="http://www.w3.org/2000/svg" width="${boundingBox}" height="${boundingBox}">
                    <defs>
                      <style>
                        text {
                          font: ${this.fontSize} ${this.fontFamily};
                        }
                      </style>
                    </defs>
                    <g transform="rotate(${this.rotation} ${boundingBox / 2} ${boundingBox / 2})">
                      <rect x="${xLabelOffset}" y="${yLabelOffset}" width="${width}" height="${height}" style="fill:${
                        this.backgroundColor
                      };" rx="${this.borderRadius}" ry="${this.borderRadius}"></rect>
                      <text x="0" y="${yTextOffset}">${tspans}</text>
                    </g>
                  </svg>`;
      let icon = {
        size: { width: boundingBox, height: boundingBox },
        anchor: { x: boundingBox / 2, y: boundingBox / 2 },
        url
      };
      this._icon = icon;
    } else {
      this._icon = null;
    }
  },

  _getTextDimensions: function (text, fontSize, fontFamily) {
    this.$.ruler.style.fontSize = fontSize || this.fontSize;
    this.$.ruler.style.fontFamily = fontFamily || this.fontFamily;
    this.$.ruler.textContent = text;
    let temp = { width: Math.ceil(this.$.ruler.offsetWidth), height: Math.ceil(this.$.ruler.offsetHeight) };
    return temp;
  },

  _iconChanged: function () {
    if (this.$.marker) {
      if (this._icon && !this.$.marker.map) this.$.marker.map = this.map;
      else if (!this._icon && this.$.marker.map) this.$.marker.map = null;
    }
  }
});
